import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { getCookie } from "shared/methods/utilityFunctions";
import { getAuth } from "state/features/authentication/authentication.slice";

export const UnprotectedRoute = ({ children }: { children: any }) => {
  const authState = useSelector(getAuth);

  if (!authState.auth.isAuthenticated) {
    const tokensFromCookie = getCookie("authTokens");
    if (!tokensFromCookie || !JSON.parse(tokensFromCookie)) {
      return children;
    }
  }

  return <Redirect to={{ pathname: "/dashboard" }} />;
};
