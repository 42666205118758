import React, { useEffect, useState } from "react";
import Button from "components/Button";
import GenericTabs from "components/generic-tabs/generic-tab.component";
import Header from "components/Header";
import SearchBox from "components/search-box/search-box.component";
import { APPROVED, PENDING } from "shared/assets/constants/commonConstants";
import { TOCRequest, TOCList, TOC } from "./types";
import { useSelector } from "react-redux";
import { resetTocs, getTocs } from "state/features/toc/toc.slice";
import { DischargeDateProps } from "components/Modal/TOCFilterModal/toc-filter-modal.types";
import Pill from "components/Pill";
import TOCFilterModal from "components/Modal/TOCFilterModal/toc-filter-modal.component";
import { getCommon } from "state/features/common/common.slice";
import { HeaderColumnInfo } from "./toc.container";
import Row from "components/Table/Presentation/toc-list-presentation/toc-list-row";
import { useHistory } from "react-router";
import CommonTable from "components/Table/CommonTable/common-table.component";
import { EmptyStateType } from "components/empty-state/empty-state.enum";

type TOCPresentationProps = {
  onClearSearchBox: () => void;
  onClickSearchBox: () => void;
  tocPayload: TOCRequest;
  handleSearchInput: (value: string) => void;
  searchText: string;
  onClickSearchButton: () => void;
  onClickFilterButton: () => void;
  onClickTabName: (value: string) => void;
  onNextData: () => void;
  onRemoveFilter: () => void;
  onApplyFilter: (date: DischargeDateProps) => void;
  onClearFilter: () => void;
  pendingTocColumns: any[];
  approvedTocColumns: any[];
  getDateText: () => string;
  handlePendingListSort: (column: HeaderColumnInfo) => void;
  handleApprovedListSort: (column: HeaderColumnInfo) => void;
};

const TOCPresentation = (props: TOCPresentationProps) => {
  const {
    onClearSearchBox,
    onClickSearchBox,
    tocPayload,
    handleSearchInput,
    searchText,
    onClickSearchButton,
    onClickFilterButton,
    onClickTabName,
    onNextData,
    onRemoveFilter,
    onApplyFilter,
    onClearFilter,
    pendingTocColumns,
    approvedTocColumns,
    getDateText,
    handlePendingListSort,
    handleApprovedListSort,
  } = props;
  const tocState = useSelector(getTocs);
  const commonState = useSelector(getCommon);

  const canFetchMoreApprovedTocs = () => {
    return tocState.tocs.approved.length < tocState.counts.approved;
  };

  const history = useHistory();
  return (
    <div id="toc">
      <Header className="toc">
        <div className="heading">{`TOCs (${
          tocState.counts.pending + tocState.counts.approved
        })`}</div>
      </Header>

      <div className="filter-section">
        <GenericTabs
          tabsList={[
            {
              name: PENDING,
              count: tocState.counts.pending,
            },
            {
              name: APPROVED,
              count: tocState.counts.approved,
            },
          ]}
          activeTabName={tocPayload.status}
          onClickTabName={onClickTabName}
        />
        <div className="right-section">
          <div className="search-box-container">
            <SearchBox
              placeholder="Search by Patient name"
              icon="cross"
              text={searchText}
              className={`search-box ${
                tocState.isSearching ? "opacity1" : "opacity0"
              }`}
              iconClassName="search-icon"
              SearchBoxSize={22}
              onClear={onClearSearchBox}
              onClick={onClickSearchBox}
              suggestionVisible
              onTextChange={(value: string) => handleSearchInput(value)}
            />

            <Button
              icon="search"
              text="Search"
              showToolTip
              className="search-icon-container"
              iconClassName="search-icon"
              buttonSize={14}
              onClick={onClickSearchButton}
            />
          </div>
          <Button
            icon="filter"
            text="Filter"
            showToolTip
            className="filter-icon-container"
            iconClassName="filter-icon"
            buttonSize={14}
            onClick={onClickFilterButton}
          />
        </div>
      </div>
      <div className="filters-applied">
        {(tocPayload.dischargeStartDate || tocPayload.dischargeEndDate) && (
          <Pill text={getDateText()} onClear={onRemoveFilter} />
        )}
      </div>
      <div
        className={`toc-presentation-table ${
          tocPayload.dischargeStartDate || tocPayload.dischargeEndDate
            ? "toc-presentation-table-filter-appied"
            : ""
        } ${
          tocPayload.status === APPROVED
            ? "toc-table-approved"
            : "toc-table-pending"
        }`}
      >
        {tocPayload.status === APPROVED ? (
          <CommonTable
            columns={approvedTocColumns}
            currentTableData={tocState.tocs.approved}
            handleSort={handleApprovedListSort}
            canFetchMoreEpisodes={canFetchMoreApprovedTocs}
            changePageOffset={onNextData}
            isInifiniteLoading={tocState.isSilentLoading}
            isLoading={tocState.isLoading}
            messageForNoData={
              tocState.counts.approved === 0 && tocState.counts.pending === 0
                ? EmptyStateType.NO_TOCS
                : EmptyStateType.NO_APPROVED_TOCS
            }
            data={() =>
              tocState.tocs.approved.map((item: TOC, index: number) => {
                return (
                  <Row
                    key={item.IntakeID}
                    {...item}
                    index={index}
                    listType={"approved"}
                    onClick={() => history.push(`/toc/${item.IntakeID}`)}
                  />
                );
              })
            }
          />
        ) : (
          <CommonTable
            columns={pendingTocColumns}
            currentTableData={tocState.tocs.pending}
            handleSort={handlePendingListSort}
            canFetchMoreEpisodes={() =>
              tocState.tocs.pending.length < tocState.counts.pending
            }
            changePageOffset={onNextData}
            isInifiniteLoading={tocState.isSilentLoading}
            isLoading={tocState.isLoading}
            messageForNoData={
              tocState.counts.approved === 0 && tocState.counts.pending === 0
                ? EmptyStateType.NO_TOCS
                : EmptyStateType.NO_PENDING_TOCS
            }
            data={() =>
              tocState.tocs.pending.map((item: TOC, index: number) => {
                return (
                  <Row
                    key={item.IntakeID}
                    {...item}
                    index={index}
                    listType={"pending"}
                    onClick={() => history.push(`/toc/${item.IntakeID}`)}
                  />
                );
              })
            }
          />
        )}
      </div>
      {commonState.isFilterModalVisible && (
        <TOCFilterModal
          handleFilterApplyClick={(date: DischargeDateProps) =>
            onApplyFilter(date)
          }
          handleFilterClearClick={onClearFilter}
          dates={{
            dateTo: tocPayload.dischargeEndDate,
            dateFrom: tocPayload.dischargeStartDate,
          }}
        />
      )}
    </div>
  );
};
export default TOCPresentation;
