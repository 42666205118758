import axios from "axios";
import { getConfig } from "../../config/config";

const BASE_URL = getConfig().authenticationBase;

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": getConfig().subscriptionKey!,
  },
});
