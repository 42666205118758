export const LOGIN_FORM = {
  loginButtonTitle: "Login",
  emailPlaceholder: "Enter Your Email",
  label: "EMAIL",
  buttonLabel: "Login",
  passwordPlaceholder: "Enter Your Password",
  passwordLabel: "PASSWORD",
};

export const HOME_CONSTANTS = {
  remainingTimeSeconds: 0,
  remainingTimeMinutes: 10,
};

export const OTP_CONSTANTS = {
  OTP_Length: 4,
  OTP_Placeholder: "....",
  OTP_TimeRemaining: "Time remaining ",
  OTP_Button: "Verify OTP",
  OTP_Resend: "Resend OTP",
  OTP_Seconds_Check: 10,
};

export const TOAST_MESSAGES = {
  WRONG_OTP: "Wrong OTP. Please verify and enter again",
  EXPIRE_OTP: "OTP has expired. Please click on Resend OTP to get a new one.",
  ERROR: "Something went wrong. Please try again.",
  USER_NOT_EXIST: "User Not Found",
  USER_ERROR_MESSAGE:
    "Login failed. Username and Password combination is incorrect.",
  SESSION_EXPIRED: "Session expired. Please login again.",
  USER_LOCKED_OUT:
    "Too many attempts. The user has been locked out. Please try again after sometime.",
  TOC_ERROR_FACILITY_EMPTY:
    "Please make sure Facility is selected for desired TOC items",
  TOC_ERROR_LOS_EMPTY:
    "Please make sure LOS/Visits are filled for selected Facilities",
  TOC_ERROR_EMPTY: "Please select atleast one Facility or Home w/No services",
  TOC_ERROR_INVALID_LOS: "Please enter a valid value for Acute LOS",
  TOC_ERROR_INVALID_LOS_RANGE: "Acute LOS should be within the range 1-50 ",
  TOC_ERROR_VALID_LOS:
    "Please make sure LOS/Visits are valid for selected Facilities",
};

export const ADMIN_EMAIL = "admin@es.co";

export const CUSTOM_EVENT_NAMES = {
  CLEAR_REDUX_DATA: "CLEAR_REDUX_DATA",
};

export const MESSAGE_LOGS_COLUMNS = [
  {
    name: "Participants",
    sorting: true,
    key: "participants",
  },
  {
    name: "Type",
    sorting: true,
    key: "type",
  },
  {
    name: "Date/Time",
    sorting: true,
    key: "datetime",
  },
  {
    name: "Message",
    sorting: false,
    key: "message",
  },
  {
    name: "",
    key: "actions",
  },
];

export enum SortingTypes {
  original = 0,
  asc = 1,
  desc = 2,
}

export const NAVIGATOR_MAXIMUM_LIMIT = 3;

export const GENERAL = "General";

export enum AccountType {
  PATIENT = "Patient",
  PHYSICIAN = "Physician",
  NAVIGATOR = "Navigator",
}
