import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ClickAwayListener from "react-click-away-listener";
import TickmarkIcon from "shared/assets/images/tickmark.png";
import "./styles.scss";
import { getTocs } from "state/features/toc/toc.slice";
import Icon from "components/Icon";
import getHighlightedText from "components/highlighted-text/highlighted-text.component";
import Preferred from "components/Preferred/preferred.component";

type FacilityDropdownProps = {
  onChange: (id: number, name: string) => void;
  isError: boolean;
  isDisabled?: boolean;
  selectedOptionId: number;
  selectedOptionName: string;
};
const FacilityDropdown = (props: FacilityDropdownProps) => {
  const { facilities } = useSelector(getTocs);
  const [isOptionsVisible, setIsOptionsVisible] = useState<boolean>(false);
  const {
    selectedOptionId,
    onChange,
    isDisabled,
    isError,
    selectedOptionName,
  } = props;
  const [selectedOption, setSelectedOption] = useState<{
    id: number;
    name: string;
  }>();
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    setSelectedOption({
      id: selectedOptionId,
      name: selectedOptionName,
    });
  }, [selectedOptionId, selectedOptionName]);

  const onOptionClick = (id: number) => {
    if (facilities && facilities.length) {
      const facility = facilities.find((el) => el.id === id)!;
      setSelectedOption({ id: facility.id, name: facility.providerName });
      setIsOptionsVisible(false);
      onChange(facility.id, facility.providerName);
      setSearch("");
    }
  };

  const clearSelection = () => {
    setSelectedOption({ id: -1, name: "" });
    onChange(-1, "");
  };

  const onSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsOptionsVisible(false);
      }}
    >
      <div className="facility-dropdown-container">
        <div
          className={`current-selction-container ${
            isDisabled ? "disabled" : null
          } ${!isError ? null : "input-error"}`}
        >
          <div className="current-selection">{selectedOption?.name}</div>
          {selectedOption && selectedOption?.id !== -1 ? (
            <div className="cancel-selection-container">
              <div
                className="cancel-icon-container"
                onClick={() => {
                  if (isDisabled) {
                    return;
                  }
                  clearSelection();
                }}
              >
                <Icon
                  icon="cross-in-circle"
                  size={18}
                  className={`search-cross-icon ${
                    isDisabled ? "search-cross-icon-disabled" : ""
                  }`}
                />
              </div>
            </div>
          ) : null}
          <div
            className={`dropdown-icon-container ${
              isDisabled ? "dropdown-icon-container-disabled" : ""
            }`}
            onClick={() => {
              if (isDisabled) {
                return;
              }
              setIsOptionsVisible(!isOptionsVisible);
            }}
          >
            {isOptionsVisible ? (
              <Icon icon="up-arrow" className="select-box-up-arrow" size={11} />
            ) : (
              <Icon
                icon="down-arrow"
                className={`select-box-down-arrow `}
                size={11}
              />
            )}
          </div>
        </div>
        {isOptionsVisible ? (
          <div className="options-container">
            <div className="search-container">
              <div className="search-input-container">
                <input
                  type="text"
                  value={search}
                  onChange={onSearchChange}
                  name="search"
                  id="search"
                />
              </div>
              <div className="cancel-icon-container">
                <span onClick={() => setSearch("")}>
                  <Icon
                    icon="search-cross"
                    size={16}
                    className="search-cross-icon"
                  />
                </span>
              </div>
            </div>
            <div className="dropdown-options-container">
              {facilities
                .filter((el) =>
                  el.providerName.toLowerCase().includes(search.toLowerCase())
                )
                .map((el) => (
                  <div
                    className={`dropdown-options-row`}
                    key={el.id}
                    onClick={() => {
                      onOptionClick(el.id);
                    }}
                  >
                    <div className={`name ${el.id === selectedOption?.id ? "selected" : ""}`}>
                      {getHighlightedText(`${el.providerName}`, search)}
                    </div>
                    <div className="right-section">
                      <div>{el.preferredProvider ? <Preferred /> : null}</div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default FacilityDropdown;
