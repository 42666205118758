import React from "react";
import { GenericTabsProps } from "./types";
import "./generic-tabs.styles.scss";

const GenericTabs = ({
  tabsList,
  activeTabName,
  onClickTabName,
}: GenericTabsProps) => {
  return (
    <div className="tab-container">
      <div className="tabs">
        {tabsList?.map((singleTab) => (
          <div
            key={singleTab.name}
            className={`tab ${
              activeTabName === singleTab.name ? "active" : ""
            }`}
            onClick={()=>onClickTabName(singleTab.name)}
          >
            {singleTab?.name}{" "}
            {singleTab?.count ? `(${singleTab?.count})` : "(0)"}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GenericTabs;
