import SurgeonAndEpisodeDetails from "./tabs/SurgeonAndEpisodeDetails";

type AddEditEpisodeProps = {
  form: any;
  setForm: (val: any) => void;
};

const AddEditEpisode = ({ form, setForm }: AddEditEpisodeProps) => {
  return <SurgeonAndEpisodeDetails form={form} setForm={setForm} />;
};

export default AddEditEpisode;
