import { createSlice } from "@reduxjs/toolkit";
import { IPatientEpisode } from "shared/types/patient-episodes.types";
import { IPatientEpisodesState } from "state/types/patient-episodes-slice.type";
import { getPatientEpisodesByPractice } from "./patient-episodes.action";

const initialState: IPatientEpisodesState = {
  isLoading: false,
  isSilentLoading: false,
  isSearching: false,
  isError: false,
  suggestions: "",
  episodes: {
    totalCount: 0,
    records: [],
  },
};

const patientEpisodesSlice = createSlice({
  name: "patient-episodes",
  initialState,
  reducers: {
    setIsLoading: (state: any, action) => {
      state.isLoading = action.payload;
    },
    setIsSilentLoading: (state: any, action) => {
      state.isSilentLoading = action.payload;
    },
    setIsSearching: (state: any, action) => {
      state.isSearching = action.payload;
    },
    setError: (state: any, action) => {
      state.isError = action.payload;
    },
    setSuggestions: (state: any, action) => {
      state.suggestions = action.payload;
    },
    setEpisodes: (state: any, action) => {
      state.episodes = {
        totalCount: action.payload.TotalRecords,
        records: action.payload.episodes,
      };
    },
    resetPatientEpisodes: () => initialState,
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(
        getPatientEpisodesByPractice.pending,
        (state: any, action) => {
          if (state.episodes.totalCount > 0) {
            state.isLoading = false;
            state.isSilentLoading = true;
          } else {
            state.isLoading = true;
            state.isSilentLoading = false;
          }
          state.isError = false;
        }
      ),
      builder.addCase(
        getPatientEpisodesByPractice.fulfilled,
        (state: any, action: any) => {
          const episodes: IPatientEpisode[] = action.payload.Patients.map(
            (episode: any) => {
              const {
                PhysicianFirstName,
                PhysicianMiddleName,
                PhysicianLastName,
              } = episode;
              let physicianFullName = "";
              if (PhysicianFirstName) {
                physicianFullName += PhysicianFirstName;
              }
              if (PhysicianMiddleName) {
                physicianFullName += ` ${PhysicianMiddleName}`;
              }
              if (PhysicianLastName) {
                physicianFullName += ` ${PhysicianLastName}`;
              }

              return {
                id: episode.ID,
                firstName: episode.FirstName,
                lastName: episode.LastName,
                gender: episode.Gender,
                dob: episode.DOB,
                age: episode.Age,
                navigatorName: episode.NavigatorName,
                navigatorUserId: episode.NavigatorUserId,
                episode: episode.Procedure,
                surgeryDate: episode.SurgeryDate,
                patientUserId: episode.PatientUserId,
                physicianUserId: episode.PhysicianUserID,
                physicianFullName,
                intakeRecordCreatedDate: episode.IntakeRecordCreatedDate,
                intakeId: episode.IntakeID.toString(),
              } as IPatientEpisode;
            }
          );
          if (state.isSilentLoading) {
            state.episodes = {
              totalCount: action.payload.TotalRecords,
              records: [...state.episodes?.records, ...episodes],
            };
          } else {
            state.episodes.totalCount = action.payload.TotalRecords;
            state.episodes.records = episodes;
          }
          state.isLoading = false;
          state.isSilentLoading = false;
          state.isError = false;
        }
      ),
      builder.addCase(
        getPatientEpisodesByPractice.rejected,
        (state: any, action) => {
          if (!action.meta.aborted) {
            state.isLoading = false;
            state.isError = true;
          }
        }
      )
    );
  },
});

export default patientEpisodesSlice.reducer;
export const {
  setIsLoading,
  setIsSilentLoading,
  setIsSearching,
  setError,
  setSuggestions,
  setEpisodes,
  resetPatientEpisodes,
} = patientEpisodesSlice.actions;
export const getPatientEpisodes = (state: any): IPatientEpisodesState =>
  state.patientEpisodes;
