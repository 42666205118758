import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ProgressBar = ({ percentage }: { percentage: number }) => {
  return (
    <CircularProgressbar
      value={percentage}
      maxValue={100}
      minValue={0}
      styles={buildStyles({
        backgroundColor: "#333333",
        pathColor: "#333333",
      })}
    />
  );
};

export default ProgressBar;
