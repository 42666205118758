import React from "react";
import { RadioInputProps } from "./props";
import "./styles.scss";

const RadioInputGroup = ({
  labelText,
  radioList,
  className,
  handleInputChange,
  required,
  disabled,
  showError,
  errorMessage,
  shouldDisplayErroronDisabled,
}: RadioInputProps) => {
  const handleChange = (event: any) => {
    let text = "";
    radioList.forEach((singleItem) => {
      if (singleItem.id == event.target.value) {
        text = singleItem.text;
      }
    });
    handleInputChange(event.target.value, text);
  };

  const isdisplayErrorMsg =
    (shouldDisplayErroronDisabled && showError) || (!disabled && showError);
  return (
    <div className="radio-input-group-section">
      <div className="radio-group-label">
        {labelText}
        {required && <span className="red-color-asterisk">*</span>}
      </div>
      <div className="input-section" onChange={(event) => handleChange(event)}>
        {radioList.map((item: any) => (
          <div className="single-radio" key={item.id}>
            <label
              className={`container ${disabled ? "disable-radio-button" : ""}`}
            >
              <input
                type="radio"
                checked={item.checked}
                name={labelText}
                value={item.id}
                onChange={() => {}}
                className={`${className ? className : ""} ${
                  disabled ? "disable-radio-button" : ""
                }`}
                disabled={disabled}
              />
              {item.text}
            </label>
          </div>
        ))}
      </div>
      {isdisplayErrorMsg && <div className="error-wrap">{errorMessage}</div>}
    </div>
  );
};

export default RadioInputGroup;
