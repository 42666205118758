import React from "react";
import { HeaderProps } from "./props";
import "./styles.scss";

const Header = ({ className, children }: HeaderProps) => {
  return (
    <div id="header" className={`${className}-header`}>
      {children}
    </div>
  );
};

export default Header;
