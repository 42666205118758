import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { getCookie } from "shared/methods/utilityFunctions";
import { getAuth } from "state/features/authentication/authentication.slice";
import { setTwilioClientRef } from "state/features/common/common.slice";
import { useAppDispatch } from "state/store";
import { TwilioClient } from "shared/services/twilio/twilioClient";
import { useTwilioClientService } from "shared/services/twilio/twilio-client.service";
import { requestGenerateTwilioTokenAsync } from "state/features/authentication/authentication.action";

export const ProtectedRoute = ({ children }: { children: any }) => {
  const authState = useSelector(getAuth);
  const appDispatch = useAppDispatch();
  const twilioClient = useTwilioClientService();

  const initializeTwilio = useCallback(async () => {
    if (document.visibilityState === "visible") {
      await appDispatch(
        requestGenerateTwilioTokenAsync(authState.user.email)
      ).unwrap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authState.auth.isAuthenticated && authState.twilioToken) {
      document.addEventListener("visibilitychange", initializeTwilio);
    }
    return () => {
      document.removeEventListener("visibilitychange", initializeTwilio);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.twilioToken]);

  useEffect(() => {
    if (authState.auth.isAuthenticated) {
      if (authState.twilioToken) {
        appDispatch(
          setTwilioClientRef(TwilioClient.getInstance(authState.twilioToken))
        );
      } else {
        appDispatch(
          requestGenerateTwilioTokenAsync(authState.user.email)
        ).unwrap();
      }
    }
  }, [
    twilioClient,
    authState.twilioToken,
    authState.auth.isAuthenticated,
    authState.user.email,
    appDispatch,
  ]);

  if (!authState.auth.isAuthenticated) {
    const tokensFromCookie = getCookie("authTokens");
    if (!tokensFromCookie || !JSON.parse(tokensFromCookie)) {
      return <Redirect to={{ pathname: "/" }} />;
    }
  }

  return children;
};
