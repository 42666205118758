import React, { useEffect, useRef, useState } from "react";
import { SearchBoxProps } from "./search-box.props";
import Icon from "components/Icon";
import "./search-box.component.scss";
import { useSelector } from "react-redux";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { getPatientEpisodes } from "state/features/patient-episodes/patient-episodes.slice";
import { getTocs } from "state/features/toc/toc.slice";
import { getMessages } from "state/features/messages/messages.slice";

const SearchBox = ({
  text,
  className,
  onClick,
  onClear,
  icon,
  iconClassName,
  SearchBoxSize,
  onTextChange,
  suggestionVisible,
  inputClassName,
  placeholder = "",
  autoFocus = true,
  showCrossIcon = true,
  showSearchIcon = false,
}: SearchBoxProps) => {
  const patientsState = useSelector(getPatientEpisodes);
  const tocState = useSelector(getTocs);
  const messagingState = useSelector(getMessages);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isTabPressed, setIsTabPressed] = useState(false);

  useEffect(() => {
    if (suggestionVisible) {
      document.addEventListener("keydown", function (event) {
        const code = event.key === "Tab";
        setIsTabPressed(code);
      });
    }
  }, []);

  useEffect(() => {
    if (
      isTabPressed &&
      (!isEmptyString(patientsState.suggestions) ||
        !isEmptyString(tocState.suggestions))
    ) {
      onTextChange(patientsState.suggestions || tocState.suggestions);
      inputRef.current?.focus();
      setIsTabPressed(false);
    }
  }, [isTabPressed]);

  useEffect(() => {
    if (
      patientsState.isSearching ||
      tocState.isSearching ||
      messagingState.isSearching
    ) {
      inputRef.current?.focus();
    }
  }, [
    patientsState.isSearching,
    tocState.isSearching,
    messagingState.isSearching,
  ]);

  if (icon) {
    return (
      <div className={`${className ? className : ""} icon-button`}>
        <div className="input-plus-lens">
          {showSearchIcon && (
            <Icon icon="search-lens" size={16} className="search-lens-icon" />
          )}

          <input
            ref={inputRef}
            tabIndex={-1}
            className={`input-field ${inputClassName ? inputClassName : ""}`}
            type="text"
            value={text}
            placeholder={placeholder}
            onChange={(event) => {
              onTextChange(event.target.value);
            }}
            autoComplete="off"
            autoFocus={autoFocus}
          />
          {/* {isEmptyString(text) && isEmptyString(patientsState.suggestions) && (
            <span className="placeholder">{placeholder}</span>
          )} */}
          {suggestionVisible &&
            (!isEmptyString(patientsState.suggestions) ||
              !isEmptyString(tocState.suggestions)) && (
              <span className="suggestion">
                {patientsState.suggestions || tocState.suggestions}
              </span>
            )}
        </div>
        {showCrossIcon && (
          <div className="icon-section" onClick={onClear}>
            <Icon icon="search-cross" size={16} className="search-cross-icon" />
          </div>
        )}
      </div>
    );
  }

  return (
    <input
      type="SearchBox"
      className={`${className}`}
      onClick={onClick}
      value={text}
      onChange={(event) => onTextChange(event.target.value)}
    />
  );
};

export default SearchBox;
