import { createSlice } from "@reduxjs/toolkit";
import { ICommonState } from "state/types/common-slice.type";
import { getConfigurationSettings } from "./common.action";
import { MessageOpenFrom } from "shared/types/enum";

let initialization = {
  isInitialized: false,
};

const initialState: ICommonState = {
  isLoading: false,
  isAppInitialized: initialization.isInitialized,
  isError: false,
  isFooterVisible: false,
  isSessionTimeoutModalVisible: false,
  isLogoutModalVisible: false,
  isFilterModalVisible: false,
  isChatScreenVisible: {
    isOpen: false,
    openFrom: MessageOpenFrom.COMPOSE,
  },
  globalValues: {
    twilioClientRef: null,
  },
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setIsLoading: (state: any, action) => {
      state.isLoading = action.payload;
    },
    setError: (state: any, action) => {
      state.isError = action.payload;
    },
    setIsFooterVisibile: (state: any, action) => {
      state.isFooterVisibile = action.payload;
    },
    setIsSessionTimeoutModalVisible: (state: any, action) => {
      state.isSessionTimeoutModalVisible = action.payload;
    },
    setIsLogoutModalVisible: (state: any, action) => {
      state.isLogoutModalVisible = action.payload;
    },
    setIsFilterModalVisible: (state: any, action) => {
      state.isFilterModalVisible = action.payload;
    },
    setIsChatScreenVisible: (state: any, action) => {
      state.isChatScreenVisible = action.payload;
    },
    setTwilioClientRef: (state: any, action) => {
      state.globalValues.twilioClientRef = action.payload;
    },
    resetCommon: () => {
      return {
        ...initialState,
        ...{ isAppInitialized: initialization.isInitialized },
      };
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(
        getConfigurationSettings.pending,
        (state: any, action) => {
          state.isAppInitialized = false;
          initialization.isInitialized = false;
        }
      ),
      builder.addCase(
        getConfigurationSettings.fulfilled,
        (state: any, action) => {
          state.isAppInitialized = true;
          initialization.isInitialized = true;
          state.isError = false;
        }
      ),
      builder.addCase(
        getConfigurationSettings.rejected,
        (state: any, action) => {
          state.isAppInitialized = true;
          initialization.isInitialized = true;
          state.isError = true;
        }
      )
    );
  },
});

export default commonSlice.reducer;
export const {
  setIsLoading,
  setError,
  setIsFooterVisibile,
  setIsSessionTimeoutModalVisible,
  setIsLogoutModalVisible,
  setIsFilterModalVisible,
  setIsChatScreenVisible,
  resetCommon,
  setTwilioClientRef,
} = commonSlice.actions;
export const getCommon = (state: any): ICommonState => state.common;
