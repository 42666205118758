import React, { useEffect } from "react";
import Modal from "components/Modal";
import Button from "components/Button";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import useIdleTimerContainer from "components/IdleTimeout";
import {
  getCommon,
  setIsSessionTimeoutModalVisible,
} from "state/features/common/common.slice";
import { clearUser } from "state/features/authentication/authentication.slice";

const SessionTimeOutModal = () => {
  const { isIdle } = useIdleTimerContainer();
  const commonState = useSelector(getCommon);

  const dispatch = useDispatch();
  const handleOkClick = () => {
    dispatch(clearUser());
    window.location.replace("/");
  };

  useEffect(() => {
    if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/reset-password" &&
      window.location.pathname !== "/forgot-password" &&
      isIdle
    ) {
      dispatch(setIsSessionTimeoutModalVisible(true));
    }
  }, [isIdle]);

  if (
    window.location.pathname !== "/" &&
    window.location.pathname !== "/reset-password" &&
    window.location.pathname !== "/forgot-password" &&
    commonState.isSessionTimeoutModalVisible
  ) {
    return (
      <Modal
        isOpen={commonState.isSessionTimeoutModalVisible}
        className="center"
      >
        <div className="session-timedout-container">
          <div className="header">Session Timed Out</div>
          <div className="content">Please login again</div>
          <div className="button-section">
            <Button
              text="Ok"
              className="green-button reload-button"
              onClick={handleOkClick}
            />
          </div>
        </div>
      </Modal>
    );
  } else {
    return null;
  }
};

export default SessionTimeOutModal;
