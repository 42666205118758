import { useDispatch, useSelector } from "react-redux";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import ComposeContentPresentation from "./compose-content.component";
import { getMessages } from "state/features/messages/messages.slice";
import "./compose-content.styles.scss";
import { getAuth } from "state/features/authentication/authentication.slice";
import { requestFetchAllContactsAsync } from "state/features/messages/messages.action";
import { ISelectedDropdownValue } from "./types";
import { getCommon } from "state/features/common/common.slice";
import { MessageOpenFrom } from "shared/types/enum";
export const defaultPatientValue = {
  key: "general",
  name: "General",
  label: "General",
  value: "General",
};

const ComposeContent = forwardRef((props, ref) => {
  useAxiosAuthenticated();
  const dispatch = useDispatch();
  const { allContacts } = useSelector(getMessages);
  const authState = useSelector(getAuth);
  const { isChatScreenVisible } = useSelector(getCommon);
  const [selectedPatient, setSelectedPatient] =
    useState<ISelectedDropdownValue>(defaultPatientValue);
  const [patientCheckbox, setPatientCheckbox] = useState(false);
  const [selectedNavigators, setSelectedNavigators] = useState<
    ISelectedDropdownValue[]
  >([]);
  const [isSearchable, setIsSearchable] = useState(false);
  const [navigatorSearchText, setNavigatorSearchText] = useState("");

  const onChangePatient = (value: any) => {
    setSelectedPatient(value);
    setSelectedNavigators([]);
    if (value === null || value.key !== "general") {
      setIsSearchable(true);
      setPatientCheckbox(false);
    } else {
      setIsSearchable(false);
      setPatientCheckbox(false);
    }
    if (value !== null) {
      dispatch(
        requestFetchAllContactsAsync({
          userId: authState.user.id,
          patientId: value.patientId,
        })
      );
    }
  };

  useEffect(() => {
    if (isChatScreenVisible.openFrom === MessageOpenFrom.COMPOSE) {
      dispatch(
        requestFetchAllContactsAsync({
          userId: authState.user.id,
          patientId: null,
        })
      );
    }
  }, []);

  const onChangePatientSearch = (value: string) => {
    return new Promise((resolve, reject) => {
      const patients = allContacts.filter(
        (patient: any) =>
          patient.contactType?.toLowerCase() === "patient" &&
          `${patient.firstName} ${patient.lastName}`
            .toLowerCase()
            .includes(value.toLowerCase())
      );
      setTimeout(() => {
        resolve(patients);
      }, 500);
    });
  };

  const onChangePatientCheckox = (value: boolean) => {
    setPatientCheckbox(value);
  };

  const onChangeSelectedNavigators = (val: any) => {
    setSelectedNavigators(val);
  };

  const onChangeNavigatorSearchText = (value: string) => {
    setNavigatorSearchText(value);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        getSelectedNavigators: () => selectedNavigators,
        getSelectedPatient: () => selectedPatient,
        includePatientInParticipationList: () => patientCheckbox,
      };
    },
    [selectedNavigators, selectedPatient, patientCheckbox]
  );

  return (
    <ComposeContentPresentation
      selectedPatient={selectedPatient}
      onChangePatient={onChangePatient}
      isSearchable={isSearchable}
      onChangePatientSearch={onChangePatientSearch}
      patientCheckbox={patientCheckbox}
      onChangePatientCheckox={onChangePatientCheckox}
      selectedNavigators={selectedNavigators}
      onChangeSelectedNavigators={onChangeSelectedNavigators}
      navigatorSearchText={navigatorSearchText}
      onChangeNavigatorSearchText={onChangeNavigatorSearchText}
    />
  );
});

export default ComposeContent;
