import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosPrivate } from "shared/api/axios-wrapper.service";
import axios from "axios";
import { getConfig } from "../../../config/config";

export const loginUserAsync = createAsyncThunk(
  "authentication/loginUserAsync",
  async (requestPayload: { username: string; password: string }) => {
    const response = await axiosPrivate.post(
      `${getConfig().authenticationBase}/api/v1/Account/login`,
      {
        username: requestPayload.username,
        password: requestPayload.password,
      }
    );
    return response.data;
  }
);

export const refreshTokenAsync = createAsyncThunk(
  "authentication/refreshTokenAsync",
  async (token: string) => {
    const response = await axios.post(
      `${getConfig().authenticationBase}/api/v1/Account/refresh-token`,
      {
        token: token,
      }
    );
    return response.data;
  }
);

export const requestSendOtpAsync = createAsyncThunk(
  "authentication/requestSendOtpAsync",
  async (username: string, { rejectWithValue }) => {
    const response = await axiosPrivate.post(
      `${
        getConfig().authenticationBase
      }/api/v1/Account/request-reset-with-verification-code`,
      {
        username: username,
      }
    );
    if (response.data.code) {
      return response.data;
    } else {
      return rejectWithValue(response.data.message);
    }
  }
);

export const requestVerifyOtpAsync = createAsyncThunk(
  "authentication/requestVerifyOtpAsync",
  async (
    requestPayload: { verificationCode: string; phoneNumber: string },
    { rejectWithValue }
  ) => {
    const response = await axiosPrivate.post(
      `${getConfig().messagingBase}/api/v1/Messaging/verify-otp`,
      {
        verificationCode: requestPayload.verificationCode,
        phoneNumber: requestPayload.phoneNumber,
      }
    );
    if (!response.data.result) {
      return rejectWithValue(response.data.resultMessage);
    } else {
      return response.data;
    }
  }
);

export const requestUpdatePasswordAsync = createAsyncThunk(
  "authentication/requestUpdatePasswordAsync",
  async (requestPayload: {
    username: string;
    password: string;
    confirmPassword: string;
    code: string;
  }) => {
    const response = await axiosPrivate.post(
      `${getConfig().authenticationBase}/api/v1/Account/password`,
      {
        userName: requestPayload.username,
        password: requestPayload.password,
        confirmPassword: requestPayload.confirmPassword,
        code: requestPayload.code,
      }
    );
    return response.data;
  }
);

export const requestGenerateTwilioTokenAsync = createAsyncThunk(
  "messages/requestGenerateTwilioTokenAsync",
  async (userEmail: string) => {
    const response = await axiosPrivate.get(
      `${getConfig().messagingBase}/api/v1/Messaging/GetAccessToken`,
      {
        params: {
          emailAddress: userEmail,
        },
      }
    );
    return response.data;
  }
);

export const getAccessTokenAsync = createAsyncThunk(
  "authentication/getAccessTokenAsync",
  async (requestPayload: { refreshToken: string }) => {
    try {
      const response = await fetch(
        `${getConfig().authenticationBase}/api/v1/Account/access-token`,
        {
          method: "POST",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({ token: requestPayload.refreshToken }),
        }
      );
      const result = await response.json();
      return result;
    } catch (error: any) {
      throw error;
    }
  }
);
