import React, { useCallback, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { HOME_CONSTANTS, OTP_CONSTANTS } from "../../shared/constant/constants";
import LoginScreenImage from "../../shared/assets/images/login-screen-image.png";
import Icon from "components/Icon";
import Button from "components/Button";
import ResendArrow from "shared/assets/images/green-resend-arrow.svg";
import "./styles.scss";
import { useHistory, useLocation } from "react-router";
import TextField from "components/TextField";
import Header from "components/Header";
import Logo from "../../shared/assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { toast } from "react-toastify";
import {
  getAuth,
  setError,
  setIsLoading,
} from "state/features/authentication/authentication.slice";
import { requestSendOtpAsync } from "state/features/authentication/authentication.action";
import { useAppDispatch } from "state/store";
import { ButtonLoader } from "components/ButtonLoader";

export const ResetPassword = () => {
  const authState = useSelector(getAuth);
  const history = useHistory();
  const [username, setUsername] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {}, []);

  const requestSendOtp = () => {
    if (username && username.trim() !== "") {
      dispatch(requestSendOtpAsync(username.trim())).then((res: any) => {
        if (res && !res.error) {
          toast.success("OTP sent successfully.", {
            toastId: "otp-send",
          });
          setTimeout(
            () => history.replace(`/forgot-password?username=${username.trim()}`),
            1000
          );
        }
      });
    }
  };

  return (
    <>
      <div className="otpScreenContainer">
        <div className="otpFormWrapper">
          <Header className="otp">
            <img src={Logo} alt="logo" className="logo" />
          </Header>
          <div className="left-content">
            <div className="heading">
              <div className="reset-password-icon-container">
                <Icon
                  icon="reset-password"
                  className="reset-password-icon"
                  size={15}
                />
              </div>
              <div className="reset-password-heading">Reset Password</div>
            </div>
            <TextField
              label="User Name"
              className="input-field"
              inputClassName="input"
              inputContainerClassName="login-input-container"
              type="text"
              value={username}
              setValue={(label, value) => {
                setUsername(() => {
                  if (!isEmptyString(value)) {
                    dispatch(setError(false));
                  }
                  return value;
                });
              }}
              icon={
                <Icon icon="username" size={16} className="username-icon" />
              }
              placeholder="Enter your username"
              isRequired
              showError={authState.isError}
            />
            <div className="button-container">
              <Button
                text="Back To Login"
                onClick={() => {
                  history.push("/");
                }}
                className="no-border-button back-to-login"
              />
              <button
                className={`green-button send-otp ${
                  isEmptyString(username) ? "disabled" : ""
                }`}
                onClick={requestSendOtp}
              >
                {authState.isLoading ? (
                  <ButtonLoader />
                ) : (
                  <span className="login-button-screen">Send OTP</span>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="rightImageBlock">
          <img
            src={LoginScreenImage}
            alt="login screen"
            className="login-screen-image"
          ></img>
          <div className="big-text">
            <div className="line1">Manage Your Patients</div>
            <div className="line2">Effortlessly</div>
          </div>
        </div>
      </div>
    </>
  );
};
