import Icon from "components/Icon";
import React from "react";
import "./styles.scss";
import { validateNumber } from "shared/methods/utilityFunctions";
type PhoneInputProps = {
  name: string;
  className?: string;
  type: string;
  value: string;
  onChange: (e: any) => void;
  showError?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  onBlur?: () => void;
  placeholder?: string;
  inputClassName?: string;
  icon?: React.ReactNode;
  isRequired?: boolean;
  inputContainerClassName?: string;
  togglePassword?: (e: React.MouseEvent) => void;
  isVisiblePassword?: boolean;
};

const PhoneInput = ({
  name,
  className,
  type,
  value,
  onChange,
  showError,
  disabled,
  errorMessage,
  onBlur,
  placeholder,
  inputClassName,
  icon,
  isRequired,
  inputContainerClassName,
  togglePassword,
  isVisiblePassword,
}: PhoneInputProps) => {
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const handleOnFocus = () => {
    setIsFocused(true);
  };
  const handleOnBlur = () => {
    setIsFocused(false);
    onBlur && onBlur();
  };
  return (
    <div
      className={`phone-input-field-container ${className ? className : ""}`}
    >
      <div
        className={`field-wrap ${
          inputContainerClassName ? inputContainerClassName : ""
        }  ${
          showError ? "red-border" : isFocused ? "focused" : "black-border"
        } `}
      >
        {icon ? icon : null}
        <input
          type={type ? type : "text"}
          className={`input-field ${inputClassName ? inputClassName : ""}`}
          value={value}
          name={name}
          onChange={(e) => {
            if (e.target?.value != "" && !validateNumber(e.target?.value)) {
              return;
            }
            onChange(e.target?.value);
          }}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          min="0"
          maxLength={10}
          disabled={disabled}
          placeholder={placeholder}
        />
        {type === "password" && !isVisiblePassword ? (
          <div className="eye" role="button" onClick={togglePassword}>
            <Icon icon="eye" size={20} />
          </div>
        ) : type === "password" && isVisiblePassword ? (
          <div className="eye" role="button" onClick={togglePassword}>
            <Icon icon="eye-slash" size={20} />
          </div>
        ) : null}
      </div>
      {showError && <div className="error-wrap">{errorMessage}</div>}
    </div>
  );
};

export default PhoneInput;
