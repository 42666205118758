import React, { useEffect, useState } from "react";
import Sort from "components/Sort";
import "./common-table.styles.scss";
import Icon from "components/Icon";
import { SortingOrderType } from "shared/types/enum";
import { HeaderColumnInfo } from "pages/patient-episodes/types";
import { EmptyStateType } from "components/empty-state/empty-state.enum";
import EmptyState from "components/empty-state/empty-state.component";
import GenericScrollComponent from "components/InfiniteScroll";

const CommonTable = ({
  columns,
  currentTableData,
  handleSort,
  isLoading,
  isInifiniteLoading,
  canFetchMoreEpisodes,
  changePageOffset,
  data,
  messageForNoData,
}: {
  currentTableData: any[];
  columns: HeaderColumnInfo[];
  handleSort: (column: HeaderColumnInfo) => void;
  isLoading: boolean;
  isInifiniteLoading: boolean;
  canFetchMoreEpisodes: () => boolean;
  changePageOffset: () => void;
  data: (data: any) => React.ReactNode;
  messageForNoData: EmptyStateType;
}) => {
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const getColumnName = (column: HeaderColumnInfo) => {
    if (!column.sortOrder) {
      return;
    }
    return column.sortOrder === SortingOrderType.DEFAULT ? (
      <Sort />
    ) : (
      <Icon
        icon={
          column.sortOrder === SortingOrderType.ASC ? "sort-top" : "sort-bottom"
        }
        size={8}
      />
    );
  };
  const updateState = () => {
    const InfiniteScrollOuterDivElement = document.querySelector(
      ".infinite-scroll-component__outerdiv"
    );
    const InfiniteScrollInnerDivElement = InfiniteScrollOuterDivElement
      ?.children[0]
      ? InfiniteScrollOuterDivElement.children[0]
      : null;

    InfiniteScrollOuterDivElement &&
      InfiniteScrollInnerDivElement &&
      setHasScrollBar(
        InfiniteScrollInnerDivElement.clientWidth <
          InfiniteScrollOuterDivElement.clientWidth
      );
  };
  useEffect(() => {
    updateState();
    window.addEventListener("resize", updateState);
    return () => window.removeEventListener("resize", updateState);
  }, [currentTableData]);

  return (
    <div className="common-table-listing-container">
      <div className="common-listing-table">
        <div
          className={`common-table-listing-header ${
            hasScrollBar ? "scrollbar-added" : ""
          }`}
        >
          {columns.map((el, index) => (
            <div key={`${index}-${el.key}`} className="column">
              <div
                className="column-content"
                onClick={() => {
                  handleSort(el);
                }}
              >
                <div className="content">{el.name}</div>
                <div>{getColumnName(el)}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="common-table-listing-content">
          {isLoading ? (
            <div className="empty-state-container loader">
              <EmptyState type={EmptyStateType.LOADING} />
            </div>
          ) : currentTableData.length === 0 ? (
            <div className=" common-table-listing-row no-message-container">
              <EmptyState type={messageForNoData} />
            </div>
          ) : (
            <GenericScrollComponent
              data={currentTableData}
              isLoading={isInifiniteLoading}
              next={changePageOffset}
              hasMore={canFetchMoreEpisodes()}
            >
              {data}
            </GenericScrollComponent>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommonTable;
