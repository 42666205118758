import Icon from "components/Icon";
import React from "react";
import "./styles.scss";
type TextFieldProps = {
  label: string;
  className?: string;
  type: string;
  value: string;
  setValue?: (label: string, value: string) => void;
  showError?: boolean;
  isDisplayInfoMessage?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  infoMessaage?: string;
  onBlur?: () => void;
  placeholder?: string;
  inputClassName?: string;
  icon?: React.ReactNode;
  isRequired?: boolean;
  inputContainerClassName?: string;
  togglePassword?: (e: React.MouseEvent) => void;
  isVisiblePassword?: boolean;
  maxLength?: number;
  id?: string;
};

const TextField = ({
  label,
  className,
  type,
  value,
  setValue,
  showError,
  isDisplayInfoMessage,
  disabled,
  errorMessage,
  infoMessaage,
  onBlur,
  placeholder,
  inputClassName,
  icon,
  isRequired,
  inputContainerClassName,
  togglePassword,
  isVisiblePassword,
  maxLength,
  id,
}: TextFieldProps) => {
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const handleOnFocus = () => {
    setIsFocused(true);
  };
  const handleOnBlur = () => {
    setIsFocused(false);
    onBlur && onBlur();
  };
  return (
    <div
      className={`text-input-field-container ${className ? className : ""}`}
      id={id}
    >
      <label className="field-title">
        {label}
        {isRequired && <span className="red-color">*</span>}
      </label>
      <div
        className={`field-wrap ${
          inputContainerClassName ? inputContainerClassName : ""
        }  ${
          showError ? "red-border" : isFocused ? "focused" : "black-border"
        } ${disabled ? "field-wrap-disabled" : ""}`}
      >
        {icon ? icon : null}
        <input
          type={type ? type : "text"}
          className={`input-field ${inputClassName ? inputClassName : ""}`}
          value={value}
          onChange={(event) => {
            if (setValue) {
              if (event.target.value.length <= 5 && label === "Zip Code") {
                setValue(label, event.target.value);
              } else if (label !== "Zip Code") {
                setValue(label, event.target.value);
              }
            }
          }}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          min="0"
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength}
        />
        {type === "password" && !isVisiblePassword ? (
          <div className="eye" role="button" onClick={togglePassword}>
            <Icon icon="eye" size={20} />
          </div>
        ) : type === "password" && isVisiblePassword ? (
          <div className="eye" role="button" onClick={togglePassword}>
            <Icon icon="eye-slash" size={20} />
          </div>
        ) : null}
      </div>

      {isDisplayInfoMessage && (
        <div className="info-wrap">
          <Icon icon="info" size={15} className="info-icon" />
          {infoMessaage}
        </div>
      )}
      {showError && <div className="error-wrap">{errorMessage}</div>}
    </div>
  );
};

export default TextField;
