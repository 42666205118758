import "./chat-loading.styles.scss";
import { EmptyStateType } from "components/empty-state/empty-state.enum";
import EmptyState from "components/empty-state/empty-state.component";

const ConversationLoading = () => {
  return (
    <div className="loading-conversation">
      <EmptyState type={EmptyStateType.LOADING} />
    </div>
  );
};

export default ConversationLoading;
