export enum SortingOrderType {
  DEFAULT = "Default",
  ASC = "Asc",
  DESC = "Desc",
}

export enum MessagesSubjectType {
  GENERAL = "General",
}
export enum MessageOpenFrom {
  COMPOSE = "compose",
  NOTIFICATION_PANEL = "notification_panel",
  EXISTING_CHAT = "existing_chat",
  EMPTY = "",
}


export enum UserTypes {
  PHYSICIAN = 0,
  PATIENT,
  NAVIGATOR,
}
