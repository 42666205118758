import InfiniteScroll from "react-infinite-scroll-component";
import TableLoader from "components/TableLoader";
import "./styles.scss";
import { GenericScrollComponentProps } from "./types";

const GenericScrollComponent = ({
  data,
  isLoading,
  children,
  next,
  hasMore,
  className,
}: GenericScrollComponentProps) => {
  return (
    <div id="scroll">
      <InfiniteScroll
        dataLength={data.length}
        next={next}
        hasMore={hasMore}
        loader={isLoading && <TableLoader />}
        scrollableTarget="scroll"
        className={className}
        height="90%"
        scrollThreshold={0.7}
      >
        {children(data)}
      </InfiniteScroll>
    </div>
  );
};

export default GenericScrollComponent;
