import React from "react";
import { useHistory, useLocation } from "react-router";
import SideBar from "components/SideBar";
import { useDispatch, useSelector } from "react-redux";
import LogOutModal from "components/Modal/LogoutModal";
import TOC from "pages/toc/toc.container";
import {
  getCommon,
  setIsLogoutModalVisible,
} from "state/features/common/common.slice";
import { Messages } from "pages/calling-messaging-logs/messages.component";
import { PatientEpisodes } from "pages/patient-episodes/patient-episodes.component";
import { TwilioClient } from "shared/services/twilio/twilioClient";
import Dashboard from "pages/Dashboard/dashboard.component";
import { dispatchCustomEvent } from "shared/methods/utilityFunctions";
import { CUSTOM_EVENT_NAMES } from "shared/constant/constants";
import { NavbarIconsType } from "./nav.type";

const Nav = ({ children }: { children?: React.ReactNode }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const commonState = useSelector(getCommon);

  let routesToBeRender: NavbarIconsType[];
  routesToBeRender = [
    {
      path: "/dashboard",
      icon: "dashboard-unselected",
      iconSelected: "dashboard-selected",
      className: "nav-item-my-patients",
      size: 24,
      selectedSize: 24,
      name: "Dashboard",
      main: () => <Dashboard />,
      exact: true,
    },
    {
      path: "/toc",
      icon: "toc-unselected",
      iconSelected: "toc-selected",
      className: "nav-item-my-patients",
      size: 24,
      selectedSize: 24,
      name: "TOC items",
      main: () => <TOC />,
      exact: true,
    },
    {
      path: "/patient-episodes",
      icon: "patients-unselected",
      iconSelected: "patients-selected",
      className: "nav-item-my-patients",
      size: 24,
      selectedSize: 24,
      name: "Patient Episodes",
      main: () => <PatientEpisodes />,
      exact: true,
    },
    {
      path: "/messages",
      icon: "messages-outlined",
      iconSelected: "messages-filled",
      className: "nav-item-message-logs",
      size: 24,
      selectedSize: 21,
      name: "Messages",
      main: () => <Messages />,
      exact: true,
    },
  ];

  const handleLogoutClick = () => {
    dispatchCustomEvent(CUSTOM_EVENT_NAMES.CLEAR_REDUX_DATA);
    TwilioClient.removeInstance();
    history.replace("/");
  };

  if (
    location.pathname !== "/" &&
    location.pathname !== "/forgot-password" &&
    location.pathname !== "/reset-password"
  ) {
    return (
      <div className="app-routes">
        <LogOutModal
          isOpen={commonState.isLogoutModalVisible}
          handleCancelClick={() => {
            dispatch(setIsLogoutModalVisible(false as boolean));
          }}
          handleReloadClick={() => handleLogoutClick()}
        />
        <SideBar routes={routesToBeRender} />
        {children}
      </div>
    );
  } else return null;
};

export default Nav;
