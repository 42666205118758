import { useState } from "react";
import moment from "moment";
import { IPatientEpisode } from "shared/types/patient-episodes.types";
import Icon from "components/Icon";
import { useHistory } from "react-router";
import { CustomToolTip } from "components/tool-tip/tool-tip.component";
import "./styles.scss";
import { getPatientFullName } from "shared/methods/utilityFunctions";
const Row = ({
  intakeId,
  firstName,
  middleName,
  lastName,
  dob,
  episode,
  surgeryDate,
  navigatorName,
  physicianFullName,
  intakeRecordCreatedDate,
}: IPatientEpisode) => {
  const history = useHistory();
  const [isRowHovered, setIsRowHovered] = useState(false);
  const redirectToEditPatient = () => {
    history.push(`/patient-episodes/edit/${intakeId}`);
  };

  const hasSurgeryDatePassed = () => {
    return surgeryDate && moment() >= moment(surgeryDate);
  };
  return (
    <div
      onMouseEnter={() => setIsRowHovered(true)}
      onMouseLeave={() => setIsRowHovered(false)}
      className={` common-table-listing-row ${isRowHovered ? "hovered" : ""}`}
    >
      <div className="column">
        <div className="content">
          {getPatientFullName({ firstName, middleName, lastName })}
        </div>
      </div>
      <div className="column">
        <div className="content">
          {dob ? moment(dob).format("MM-DD-YYYY") : "-"}
        </div>
      </div>
      <div className="column">
        <div className="content">{episode}</div>
      </div>
      <div className="column">
        <div className="content">
          {surgeryDate ? moment(surgeryDate).format("MM-DD-YYYY") : "-"}
        </div>
      </div>
      <div className="column">
        <div className="content">
          {physicianFullName ? physicianFullName : "-"}
        </div>
      </div>
      <div className="column">
        <div className="content">{navigatorName ?? "-"}</div>
      </div>
      <div className="column">
        <div className="content">
          {intakeRecordCreatedDate
            ? moment(intakeRecordCreatedDate).format("MM-DD-YYYY HH:mm A")
            : "-"}
        </div>
      </div>

      {isRowHovered ? (
        <table className="action-table">
          <tr className="action-row">
            <td className="empty-filling"></td>
            <td className="action-col">
              {hasSurgeryDatePassed() ? (
                <div
                  data-tip
                  data-for="View"
                  className="view-action"
                  onClick={redirectToEditPatient}
                >
                  <Icon icon="eye" size={20} />
                  <CustomToolTip text="View" place="bottom" />
                </div>
              ) : (
                <div
                  data-tip
                  data-for="Edit"
                  className="edit-action"
                  onClick={redirectToEditPatient}
                >
                  <Icon icon="edit" size={20} />
                  <CustomToolTip text="Edit" place="bottom" />
                </div>
              )}
            </td>
          </tr>
        </table>
      ) : null}
    </div>
  );
};

export default Row;
