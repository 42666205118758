import React, { useState } from "react";
import moment from "moment";
import { TOC } from "pages/toc/types";
import { NavLink } from "react-router-dom";
import "./toc-list.styles.scss";
export interface TOCRowProps extends TOC {
  index: number;
  listType?: string;
  onClick: () => void;
}

const Row = (props: TOCRowProps) => {
  const {
    PatientName,
    NavigatorFirstName,
    NavigatorLastName,
    ProcedureDescription,
    SurgeryDischargeDate,
    listType,
    revision,
    TocCreatedDate,
    onClick,
  } = props;
  const [isRowHovered, setIsRowHovered] = useState(false);
  const getNavigatorName = () => {
    let navigatorName = "-";
    if (NavigatorFirstName) {
      navigatorName = `${NavigatorFirstName}`;
      if (NavigatorLastName) {
        navigatorName += ` ${NavigatorLastName}`;
      }
    }
    return navigatorName;
  };
  return (
    <div
      onMouseEnter={() => setIsRowHovered(true)}
      onMouseLeave={() => setIsRowHovered(false)}
      onClick={onClick}
      className={`common-table-listing-row ${isRowHovered ? "hovered" : ""} ${
        listType ? listType : ""
      }`}
    >
      <div className="column">
        <div className="content">
          <NavLink to="#" className="patient-detail">
            {PatientName}
          </NavLink>
        </div>
      </div>
      <div className="column">
        <div className="content">{getNavigatorName()}</div>
      </div>
      <div className="column">
        <div className="content">{ProcedureDescription}</div>
      </div>
      <div className="column">
        <div className="content">
          {SurgeryDischargeDate
            ? moment(SurgeryDischargeDate).format("MM-DD-YYYY")
            : "-"}
        </div>
      </div>
      {listType !== "approved" && (
        <div className="column">
          <div className="content">
            {TocCreatedDate
              ? moment(TocCreatedDate).format("MM-DD-YYYY, hh:mm A")
              : "-"}
          </div>
        </div>
      )}
      {listType === "approved" && (
        <div className="column">
          <div className="content">
            {revision && revision.length > 0 && revision[0].CreatedDateTime
              ? moment(revision[0].CreatedDateTime).format(
                  "MM-DD-YYYY, hh:mm A"
                )
              : "-"}
          </div>
        </div>
      )}
      <div className="column">
        <div className="content">
          <div className={`icons ${isRowHovered ? "visible" : "hidden"}`}></div>
        </div>
      </div>
    </div>
  );
};

export default Row;
