import {
  genderType,
  prefferedPhoneType,
} from "pages/add-edit-episode/tabs/PatientPersonalDetails/constants";
import {
  episodeSettingType,
  episodePlanType,
  episodeCareType,
} from "pages/add-edit-episode/tabs/SurgeonAndEpisodeDetails/constants";
import {
  IPatientAddressDetailsFormType,
  IPatientPersonalDetailsFormType,
  ISurgeonEpisodeDetailsFormType,
} from "state/types/add-edit-episode-slice.type";

export const SURGEON_EPISODE_DETAILS_FORM: ISurgeonEpisodeDetailsFormType = {
  surgeonName: {
    value: "",
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "surgeon_name",
  },
  surgeonId: {
    value: "",
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "",
  },
  npi: {
    value: "",
    isValid: true,
    isRequired: false,
    isDisabled: false,
    id: "npi",
  },
  surgeonPractice: {
    value: { id: "", name: "" },
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "surgeon_practice",
  },
  program: {
    value: { id: "", name: "" },
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "program",
  },
  episode: {
    value: { id: "", name: "" },
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "episode",
  },
  cpt: {
    value: "",
    isValid: true,
    isRequired: false,
    isDisabled: false,
    id: "cpt",
  },
  procedureDescription: {
    value: "",
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "procedure_description",
  },
  episodeSettings: {
    value: { name: "", id: "" },
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "episode_setting",
  },
  episodeType: {
    value: { name: "", id: "" },
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "episode_type",
  },
  episodeCare: {
    value: { name: "", id: "" },
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "episode_care",
  },
  surgerySiteSide: {
    value: { name: "", id: "" },
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "surgery_site_side",
  },
  acuteCareFacility: {
    value: { name: "", id: "" },
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "acute_care_facility",
  },
  ccn: {
    value: "",
    isValid: true,
    isRequired: false,
    isDisabled: false,
    id: "ccn",
  },
  admitDate: {
    value: null,
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "admit_date",
  },
  surgeryDate: {
    value: null,
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "surgery_date",
  },
  dischargeDate: {
    value: null,
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "discharge_date",
  },
  patientEpisodeStatus: {
    value: "New",
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "patient_episode_status",
  },
};

export const PATIENT_PERSONAL_DETAILS_FORM: IPatientPersonalDetailsFormType = {
  firstName: {
    value: "",
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "firstName",
  },
  middleName: {
    value: "",
    isValid: true,
    isRequired: false,
    isDisabled: false,
    id: "middleName",
  },
  lastName: {
    value: "",
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "lastName",
  },
  suffix: {
    value: "",
    isValid: true,
    isRequired: false,
    isDisabled: false,
    id: "suffix",
  },
  email: {
    value: "",
    isValid: true,
    isRequired: false,
    isDisabled: false,
    id: "email",
  },
  gender: {
    value: { id: genderType.MALE.id, name: genderType.MALE.text },
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "gender",
  },
  dob: {
    value: "",
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "dob",
  },
  cell: {
    value: { code: "+1", number: "" },
    isValid: true,
    isRequired: false,
    isDisabled: false,
    id: "cell",
  },
  home: {
    value: { code: "+1", number: "" },
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "home",
  },
  emegencyContactName: {
    value: "",
    isValid: true,
    isRequired: false,
    isDisabled: false,
    id: "emegency_contact_name",
  },
  emegencyContactNumber: {
    value: { code: "+1", number: "" },
    isValid: true,
    isRequired: false,
    isDisabled: false,
    id: "emegency_contact_number",
  },
  preferredPhone: {
    value: {
      name: prefferedPhoneType.CELL.text,
      id: prefferedPhoneType.CELL.id,
    },
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "preferred_phone",
  },
  mbi: {
    value: "",
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "mbi",
  },
  mbiEffectiveDate: {
    value: "",
    isValid: true,
    isRequired: false,
    isDisabled: false,
    id: "mbi_effective_date",
  },
  mrn: {
    value: "",
    isValid: true,
    isRequired: false,
    isDisabled: false,
    id: "mrn",
  },
};

export const PATIENT_ADDRESS_DETAILS_FORM: IPatientAddressDetailsFormType = {
  address: {
    value: "",
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "address",
  },
  zipcode: {
    value: "",
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "zipcode",
  },
  city: {
    value: "",
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "city",
  },
  state: {
    value: "",
    isValid: true,
    isRequired: true,
    isDisabled: false,
    id: "state",
  },
};
