import React, { useEffect } from "react";
import Icon from "components/Icon";
import "./chat-footer.styles.scss";
import { ThumbnailProp } from "components/thumbnail/thumbnail.component";
import { CustomToolTip } from "components/tool-tip/tool-tip.component";

const MSG_LENGTH = 3200;

type ChatInputProps = {
  sendMessage: () => void;
  inputValue: string;
  onChangeInputValue: (value: string) => void;
  disabled?: boolean;
  previewThumbnail: Array<ThumbnailProp>;
  onClickFileAttachment?: (e: any) => void;
  isSendDisabled?: boolean;
  placeholder?: string;
};

function ChatFooter({
  sendMessage,
  inputValue,
  onChangeInputValue,
  disabled,
  previewThumbnail,
  onClickFileAttachment,
  isSendDisabled,
  placeholder,
}: ChatInputProps) {
  const expandTextBox = (e: any) => {
    e.target.setAttribute("style", `height:auto`);
    e.target.setAttribute("style", `height:${e.target.scrollHeight}px`);
  };

  const resetHeight = () => {
    const element = document.querySelector(".chat-text-box");
    if (element) {
      element?.setAttribute("style", "height:42px !important");
    }
  };

  return (
    <div className="chat-footer">
      <CustomToolTip text="Attachment" place="top" />
      <div className="chat-text-container">
        <textarea
          value={inputValue}
          rows={2}
          onChange={(e) => {
            expandTextBox(e);
            onChangeInputValue(e.target.value);
          }}
          className="chat-text-box"
          disabled={disabled}
          maxLength={MSG_LENGTH}
          placeholder={placeholder}
          onKeyDown={(e) => {
            if (
              e.key === "Enter" &&
              !e.shiftKey &&
              !isSendDisabled &&
              !isSendDisabled &&
              !disabled
            ) {
              sendMessage();
              resetHeight();
            }
          }}
        />
        {previewThumbnail.length === 0 && (
          <label
            className="attachment-icon-container"
            htmlFor="attachment"
            data-tip
            data-for="Attachment"
          >
            <input
              id="attachment"
              onChange={onClickFileAttachment}
              type="file"
              disabled={disabled}
            />
            <div className="chat-attachment-icon">
              <Icon
                icon="Path"
                size={20}
                className={`attachment ${disabled ? " disabled" : ""}`}
              />
            </div>
          </label>
        )}
      </div>
      <div
        className={`send ${
          disabled || isSendDisabled ? "disabled" : "enabled"
        }`}
        onClick={() => {
          if (!isSendDisabled && !disabled) {
            sendMessage();
            resetHeight();
          }
        }}
      >
        <Icon icon="Shape" size={30} />
      </div>
    </div>
  );
}

export default ChatFooter;
