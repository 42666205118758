import { Client as ConversationsClient } from "@twilio/conversations";

export class TwilioClient {
  private static instance: ConversationsClient;

  private static isRevoked: boolean;

  constructor() {
    throw new Error("Use getInstance() method");
  }

  static getInstance(token: string) {
    if (TwilioClient.isRevoked || !TwilioClient.instance) {
      TwilioClient.instance = new ConversationsClient(token);
      TwilioClient.isRevoked = false;
    }
    return TwilioClient.instance;
  }

  static removeInstance() {
    if (TwilioClient.instance) {
      TwilioClient.isRevoked = true;
    }
  }
}
