import Header from "components/Header";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./patient-episodes.styles.scss";
import { useHistory } from "react-router";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import SearchBox from "components/search-box/search-box.component";
import { PAGE_LIMIT } from "shared/assets/constants/commonConstants";
import GenericScrollComponent from "components/InfiniteScroll";
import { getUTCDate } from "shared/methods/utilityFunctions";
import moment from "moment";
import { getPatientEpisodesByPractice } from "state/features/patient-episodes/patient-episodes.action";
import {
  getPatientEpisodes,
  resetPatientEpisodes,
  setIsSearching,
  setIsSilentLoading,
  setEpisodes,
  setSuggestions,
} from "state/features/patient-episodes/patient-episodes.slice";
import {
  getCommon,
  setIsFilterModalVisible,
} from "state/features/common/common.slice";
import EmptyState from "components/empty-state/empty-state.component";
import {
  IPatientEpisode,
  IPatientEpisodePayloadProps,
} from "shared/types/patient-episodes.types";
import PatientEpisodesFilterModal from "components/Modal/PatientEpisodesFilterModal/patient-episodes-filter-modal.component";
import { FilterFormType } from "components/Modal/PatientEpisodesFilterModal/patient-episodes-filter-modal.types";
import { SortingOrderType } from "shared/types/enum";
import { debounce } from "lodash";
import { useAppDispatch } from "state/store";
import { filterLabelConstants, headerColumns } from "./constants";
import { HeaderColumnInfo } from "./types";
import Icon from "components/Icon";
import { EmptyStateType } from "components/empty-state/empty-state.enum";
import Row from "components/Table/Presentation/PatientListingPresentation/Row";
import CommonTable from "components/Table/CommonTable/common-table.component";

export function PatientEpisodes() {
  const history = useHistory();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { isFilterModalVisible } = useSelector(getCommon);
  const { episodes, isSearching, isLoading, isSilentLoading } =
    useSelector(getPatientEpisodes);
  const [offset, setOffset] = useState(0);
  const [headerColumnsInfo, setHeaderColumns] =
    useState<HeaderColumnInfo[]>(headerColumns);
  const [patientEpisodesPayload, setPatientEpisodesPayload] =
    useState<IPatientEpisodePayloadProps>({
      searchKeyword: "",
      surgeryDateFrom: null,
      surgeryDateTo: null,
      sortColumn: "createddate",
      sortOrder: "desc",
      limit: 30,
      offset: 0,
      physicianIDs: [],
    });
  const abortControllerForGetPatientEpisodesAsync = useRef<() => void>();
  const [searchText, setSearchText] = useState<string>("");
  const { SURGERY_DATE, PHYSICIAN } = filterLabelConstants;
  const [appliedFilters, setAppliedFilters] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const PHYSICIAN_NAME_LIMIT = 2;

  const resetListDataAndResultOffset = () => {
    setOffset(0);
    dispatch(
      setEpisodes({
        totalRecords: 0,
        episodes: [],
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(resetPatientEpisodes());
    };
  }, []);

  useEffect(() => {
    let controller = new AbortController();
    resetListDataAndResultOffset();
    dispatch(setIsSilentLoading(false));
    let payloadAction = appDispatch(
      getPatientEpisodesByPractice({
        searchKeyword: patientEpisodesPayload.searchKeyword,
        limit: PAGE_LIMIT,
        offset,
        surgeryDateFrom: patientEpisodesPayload.surgeryDateFrom
          ? getUTCDate(patientEpisodesPayload.surgeryDateFrom)
          : null,
        surgeryDateTo: patientEpisodesPayload.surgeryDateTo
          ? getUTCDate(patientEpisodesPayload.surgeryDateTo)
          : null,
        sortColumn: patientEpisodesPayload.sortColumn.toLowerCase(),
        sortOrder: patientEpisodesPayload.sortOrder.toLowerCase(),
        physicianIDs: patientEpisodesPayload.physicianIDs,
      })
    );
    abortControllerForGetPatientEpisodesAsync.current = payloadAction.abort;
    return () => {
      controller?.abort();
    };
  }, [patientEpisodesPayload]);

  const handleSearchInput = useCallback((value: string) => {
    dispatch(
      setEpisodes({
        totalRecords: 0,
        episodes: [],
      })
    );
    setOffset(0);
    setPatientEpisodesPayload((prev) => {
      return {
        ...prev,
        searchKeyword: value.toLowerCase(),
      };
    });
  }, []);

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearchInput, 500);
  }, [handleSearchInput]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const changePageOffset = () => {
    setOffset((prevState) => {
      return prevState + PAGE_LIMIT;
    });
    dispatch(
      getPatientEpisodesByPractice({
        searchKeyword: patientEpisodesPayload.searchKeyword,
        limit: PAGE_LIMIT,
        offset: offset + PAGE_LIMIT,
        surgeryDateFrom: patientEpisodesPayload.surgeryDateFrom
          ? getUTCDate(patientEpisodesPayload.surgeryDateFrom)
          : null,
        surgeryDateTo: patientEpisodesPayload.surgeryDateTo
          ? getUTCDate(patientEpisodesPayload.surgeryDateTo)
          : null,
        sortColumn: patientEpisodesPayload.sortColumn.toLowerCase(),
        sortOrder: patientEpisodesPayload.sortOrder.toLowerCase(),
        physicianIDs: patientEpisodesPayload.physicianIDs,
      })
    );
  };

  const canFetchMoreEpisodes = () => {
    return episodes.records.length < episodes.totalCount;
  };

  const getDateText = (date: Date) => moment(date).format("MM-DD-YYYY");

  const getPhysiciansFilterPillText = (
    physicians: Array<{ key: any; name: string; value: string }>
  ) => {
    const textArr = [];
    let i = 0;
    const limit =
      physicians.length > PHYSICIAN_NAME_LIMIT
        ? PHYSICIAN_NAME_LIMIT
        : physicians.length;
    while (i < limit) {
      textArr.push(`${physicians[i].name}`);
      i++;
    }

    if (physicians.length > PHYSICIAN_NAME_LIMIT) {
      textArr.push(`(${physicians.length - PHYSICIAN_NAME_LIMIT}+)`);
    }
    return textArr.join(" | ");
  };

  const handleAppliedFilters = (filterForm: FilterFormType) => {
    const appliedFilters: Array<{ label: string; value: string }> = [];
    const { surgeryDateFrom, surgeryDateTo, physicians } = filterForm;

    const surgeryDates = [];
    if (surgeryDateFrom) {
      surgeryDates.push(getDateText(surgeryDateFrom));
    }

    if (surgeryDateTo) {
      surgeryDates.push(getDateText(surgeryDateTo));
    }

    if (surgeryDates.length) {
      appliedFilters.push({
        label: SURGERY_DATE,
        value: surgeryDates.join(" - "),
      });
    }

    if (physicians.length) {
      appliedFilters.push({
        label: PHYSICIAN,
        value: getPhysiciansFilterPillText(physicians),
      });
    }

    setAppliedFilters(appliedFilters);
  };

  const removeAppliedFiltersPill = (label: string) => {
    const changedFilters = [...appliedFilters];
    const idx = appliedFilters?.findIndex((el) => el.label === label);
    changedFilters.splice(idx, 1);
    setAppliedFilters(changedFilters);

    setOffset(0);
    if (label === SURGERY_DATE) {
      setPatientEpisodesPayload({
        ...patientEpisodesPayload,
        surgeryDateFrom: null,
        surgeryDateTo: null,
      });
    }

    if (label === PHYSICIAN) {
      setPatientEpisodesPayload({
        ...patientEpisodesPayload,
        physicianIDs: [],
      });
    }
  };

  const handleFilterApplyClick = (
    filterForm: FilterFormType,
    closeModal: boolean
  ) => {
    const { surgeryDateFrom, surgeryDateTo, physicians } = filterForm;
    resetListDataAndResultOffset();
    handleAppliedFilters(filterForm);
    setPatientEpisodesPayload({
      ...patientEpisodesPayload,
      surgeryDateFrom,
      surgeryDateTo,
      physicianIDs: physicians.map((el) => el.value.toString()),
    });
    if (closeModal) {
      dispatch(setIsFilterModalVisible(false));
    }
  };

  const handleFilterClearClick = () => {
    setPatientEpisodesPayload({
      ...patientEpisodesPayload,
      surgeryDateFrom: null,
      surgeryDateTo: null,
      physicianIDs: [],
    });
    setAppliedFilters([]);
    dispatch(setIsFilterModalVisible(false));
  };

  const changeSort = (key: string, order: SortingOrderType) => {
    setOffset(0);
    setPatientEpisodesPayload((prev) => {
      return {
        ...prev,
        offset: 0,
        sortColumn: key,
        sortOrder: order,
      };
    });
  };

  const handleSorting = (selectedColumn: HeaderColumnInfo) => {
    const index = headerColumnsInfo.findIndex(
      (x) => x.key === selectedColumn.key
    );
    let sort: SortingOrderType = SortingOrderType.DEFAULT;
    switch (headerColumnsInfo[index].sortOrder) {
      case SortingOrderType.DESC:
      case SortingOrderType.DEFAULT:
        sort = SortingOrderType.ASC;
        break;
      case SortingOrderType.ASC:
        sort = SortingOrderType.DESC;
        break;
    }
    const tempHeaders = [...headerColumnsInfo];
    tempHeaders.forEach((header) => {
      if (header.name !== "") {
        header.sortOrder = SortingOrderType.DEFAULT;
      }
    });
    tempHeaders[index].sortOrder = sort;
    changeSort(selectedColumn.key, sort);
    setHeaderColumns(tempHeaders);
  };

  return (
    <div id="patient-episodes">
      <Header className="patient-episodes">
        <div className="heading">Patient Episodes</div>
        <div className="right-section">
          <div className="search-box-container">
            <SearchBox
              placeholder="Search by Patient name"
              icon="cross"
              inputClassName="patient-episodes-search-box"
              text={searchText}
              className={`search-box ${isSearching ? "opacity1" : "opacity0"}`}
              iconClassName="search-icon"
              SearchBoxSize={22}
              onClear={() => {
                if (patientEpisodesPayload.searchKeyword) {
                  setSearchText("");
                  handleSearchInput("");
                  dispatch(setSuggestions(""));
                }
                dispatch(setIsSearching(false));
              }}
              onClick={() => {
                setSearchText("");
                handleSearchInput("");
                dispatch(
                  setEpisodes({
                    totalRecords: 0,
                    episodes: [],
                  })
                );
                dispatch(setSuggestions(""));
                dispatch(setIsSearching(false));
              }}
              suggestionVisible
              onTextChange={(value: string) => {
                if (abortControllerForGetPatientEpisodesAsync.current) {
                  abortControllerForGetPatientEpisodesAsync.current();
                }
                setSearchText(value);
                debouncedSearch(value);
              }}
            />

            <Button
              icon="search"
              text="Search"
              showToolTip
              className="search-icon-container"
              iconClassName="search-icon"
              buttonSize={14}
              onClick={() => {
                dispatch(setIsSearching(true));
              }}
            />
          </div>
          <Button
            icon="filter"
            text="Filter"
            showToolTip
            className="filter-icon-container"
            iconClassName="filter-icon"
            buttonSize={14}
            onClick={() => dispatch(setIsFilterModalVisible(true))}
          />
          <Button
            text="Add Episode"
            onClick={() => history.push("/patient-episodes/add")}
            className="green-button add-episode"
          />
        </div>
      </Header>
      {appliedFilters?.length ? (
        <div className="filters-applied-container">
          <div className="filters-applied-label-container">
            <div className="filters-applied-label">Applied Filters:</div>
            <div className="clear-filters-cta" onClick={handleFilterClearClick}>
              Clear All
            </div>
          </div>
          <div className="filters-applied-pill-container">
            {appliedFilters.map(({ label, value }) => (
              <div key={label} className="filter-pill">
                <div className="filter-pill-content">
                  <div className="filter-pill-label">{label}</div>
                  <div className="filter-pill-value">{value}</div>
                </div>
                <div
                  className="remove-filter-pill-cta"
                  onClick={() => removeAppliedFiltersPill(label)}
                >
                  <Icon icon="close" size={10} />
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <div
        className={`patient-table ${
          appliedFilters.length === 0
            ? "scroll-component-container"
            : "scroll-component-container-with-filters"
        }`}
      >
        <CommonTable
          currentTableData={episodes.records}
          columns={headerColumnsInfo}
          handleSort={handleSorting}
          isLoading={isLoading}
          isInifiniteLoading={isSilentLoading}
          changePageOffset={changePageOffset}
          messageForNoData={EmptyStateType.NO_EPISODES}
          canFetchMoreEpisodes={canFetchMoreEpisodes}
          data={() =>
            episodes.records.map((item: IPatientEpisode, index: number) => (
              <Row key={`${item.id}-${index}`} {...item} />
            ))
          }
        />
      </div>
      {isFilterModalVisible ? (
        <PatientEpisodesFilterModal
          appliedFilter={{
            dateFrom: patientEpisodesPayload.surgeryDateFrom,
            dateTo: patientEpisodesPayload.surgeryDateTo,
            physicians: patientEpisodesPayload.physicianIDs,
          }}
          handleFilterApplyClick={handleFilterApplyClick}
          handleFilterClearClick={handleFilterClearClick}
        />
      ) : null}
    </div>
  );
}
