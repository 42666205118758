import Icon from "components/Icon";
import { INotification } from "state/types/notification-slice-type";
import "./notification-row.styles.scss";
import moment from "moment";
import { getNotificationDescription } from "shared/methods/utilityFunctions";
import { ActionType } from "pages/notification/common/action-type.enum";

interface INotificationRowProps {
  notification: INotification;
  onClick: () => void;
}

const NotificationRow = (notificationProps: INotificationRowProps) => {
  const { notification, onClick } = notificationProps;
  const iconName = notification.actionType
    ? notification.actionType.split("_").join("-").toLowerCase()
    : null;

  return (
    <div
      className={`notification ${notification.isRead ? "read" : "unread"}`}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <div className={`${iconName}-img-container`}>
        <Icon
          icon={`${iconName}-icon`}
          className={`${iconName}-img`}
          size={36}
        />
      </div>
      <div className="notification-content notification-title">
        {notification.actionType === ActionType.NEW_MESSAGE
          ? getNotificationDescription(notification)
          : notification.eventDesc}
      </div>
      <div className="notification-timeStamp">
        {moment(notification.createdOn).fromNow()}
      </div>
    </div>
  );
};

export default NotificationRow;
