import Icon from "components/Icon";
import Thumbnail, {
  ThumbnailProp,
} from "components/thumbnail/thumbnail.component";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAuth } from "state/features/authentication/authentication.slice";
import {
  requestcreateConversationAsync,
  requestfetchAllConversationMessagesAsync,
  requestGetConversationIfPresentAsync,
  requestGetMessageCountAsync,
  requestSendMessageAsync,
} from "state/features/messages/messages.action";
import {
  clearCurrentChat,
  getMessages,
  setCurrentChatMessages,
  setIsLoadingChat,
} from "state/features/messages/messages.slice";
import { useAppDispatch } from "state/store";
import ChatList from "../list/chat-list.component";
import ProgressBar from "../loader/progress-bar.component";
import "./chat-modal.styles.scss";
import RetryIcon from "shared/assets/images/retry.svg";
import { getCommon } from "state/features/common/common.slice";
import { Conversation, Message as messageTwilio } from "@twilio/conversations";
import { ChatParticipant } from "shared/types/chat-participant.type";
import ChatFooter from "../footer/chat-footer.component";
import ComposeContent from "../components/compose-content/compose-content.container";
import Button from "components/Button";
import {
  ComposeRefType,
  ISelectedDropdownValue,
} from "../components/compose-content/types";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { MessageOpenFrom } from "shared/types/enum";

type ComposeProps = {
  conversationState?: any;
  handleSearchInput?: (value: string) => void;
  setIsSearchListVisible?: (value: boolean) => void;
};
interface ChatModalContentProps {
  setConversation?: React.Dispatch<React.SetStateAction<any>>;
  addItemInSelectedList: (value: any) => void;
  selectedList: ISelectedDropdownValue[];
  openFrom: string;
  isOpen: boolean;
}

const ChatModalContent = ({
  setConversation,
  addItemInSelectedList,
  selectedList = [],
  openFrom,
  isOpen,
}: ChatModalContentProps & ComposeProps) => {
  const appDispatch = useAppDispatch();
  const authState = useSelector(getAuth);
  const { currentChat, isLoadingChat, currentChatMessages } =
    useSelector(getMessages);
  const [offset, setOffset] = useState(0);
  const [messageCount, setMessageCount] = useState(0);
  const [inputValue, setInputValue] = useState<string>("");
  const [previewThumbnail, setShowPreviewThumbnail] = useState<
    Array<ThumbnailProp>
  >([]);
  const [fileToBeUploaded, setFileToBeUploaded] = useState<File | null>(null);

  const [isMessageButtonDisabled, setIsMessageButtonDisabled] = useState(false);

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [nextButtonDisable, setNextButtonDisable] = useState(false);
  const [updatedContoller, setUpdatedController] =
    useState<AbortController | null>(null);
  const cancelController = useMemo(
    () => new AbortController(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updatedContoller]
  );

  const composeRef = useRef<ComposeRefType>(null);

  const [isCancelled, setIsCancelled] = useState(false);
  const CONVERSATIONREF = useRef<Conversation>();
  const unsubscribePartcipantUpdate = useRef<Conversation>();
  const unsubscribeMessageAdded = useRef<Conversation>();
  const commonState = useSelector(getCommon);
  const { twilioClientRef } = commonState.globalValues;
  const [isFirstTimemsgSend, setisFirstTimemsgSend] = useState(true);
  const [unreadCount, setunreadCount] = useState(Number.MAX_SAFE_INTEGER);
  const [readCount, setreadCount] = useState(0);

  useEffect(() => {
    if (!isOpen && unsubscribePartcipantUpdate.current) {
      CONVERSATIONREF.current = null as any;
      unsubscribePartcipantUpdate.current.removeAllListeners();
    }
    if (!isOpen && unsubscribeMessageAdded.current) {
      unsubscribeMessageAdded.current.removeAllListeners();
    }
  }, [isOpen]);

  const fetchAllMessages = useCallback(
    async () => {
      appDispatch(setIsLoadingChat(true));
      appDispatch(
        requestfetchAllConversationMessagesAsync({
          conversationId: currentChat.conversationId,
          offset: offset,
        })
      ).then(({ payload }) => {
        if (payload.messageDetails && payload.messageDetails.length > 0) {
          appDispatch(
            setCurrentChatMessages([
              ...currentChatMessages,
              ...payload.messageDetails,
            ])
          );
        } else {
          appDispatch(setCurrentChatMessages([]));
        }
        appDispatch(setIsLoadingChat(false));
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appDispatch, currentChat.conversationId, offset]
  );

  const getUnreadMessages = async (conversation: any) => {
    const participants = conversation.getParticipants();
    const count = (await conversation.getMessagesCount()) - 1;
    participants
      .then(async function (currentParticipants: any) {
        if (
          currentParticipants.every(
            (item: any) => item.lastReadMessageIndex !== null
          )
        ) {
          const filteredList = currentParticipants.filter(
            (item: any) => item.identity !== authState.user.email
          );
          setreadCount(
            Math.max(
              ...filteredList.map((item: any) => item.lastReadMessageIndex)
            ) + 1
          );
          setunreadCount(
            count -
              Math.min(
                ...filteredList.map((item: any) => item.lastReadMessageIndex)
              )
          );
        } else {
          setunreadCount(0);
          setunreadCount(Number.MAX_SAFE_INTEGER);
        }
      })
      .catch((err: any) => console.log(err));
  };

  const initializeTwilioEvents = () => {
    if (currentChat.twilioConversationId && twilioClientRef) {
      twilioClientRef
        .getConversationBySid(currentChat.twilioConversationId)
        .then(async (conversation: Conversation) => {
          CONVERSATIONREF.current = conversation;
          conversation.setAllMessagesRead();
          unsubscribePartcipantUpdate.current = conversation.on(
            "participantUpdated",
            async () => {
              await getUnreadMessages(conversation);
            }
          );
          unsubscribeMessageAdded.current = conversation.on(
            "messageAdded",
            async (message1: messageTwilio) => {
              const {
                body,
                attributes: { user, fileType, createdAt, fileName, fileSize },
                // eslint-disable-next-line @typescript-eslint/dot-notation
              } = message1["state"];
              if (body && user._id !== authState.user.id) {
                conversation.setAllMessagesRead();
                const newMessage = {
                  _id: message1.sid,
                  createdAt: moment(createdAt).toISOString(),
                  message: {
                    type: fileType.toLowerCase(),
                    content: body as string,
                    file: { type: fileType.toLowerCase() },
                    fileName,
                    fileSize,
                  },
                  user: {
                    name: user.name,
                    email: message1.author,
                    _id: user._id,
                  },
                };
                appDispatch(setCurrentChatMessages(newMessage));
              } else {
                if (isFirstTimemsgSend) {
                  conversation.setAllMessagesRead();
                  setisFirstTimemsgSend(false);
                }
                await getUnreadMessages(conversation);
              }
            }
          );
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const initializeConversation = async () => {
    if (selectedList && selectedList.length > 0) {
      const selectedParticipants = selectedList.map(({ key, id }) => {
        if (id) {
          return id?.toString();
        } else {
          return key.toString();
        }
      });
      try {
        const createConversationPayload = {
          participantsUserId: [...selectedParticipants],
          subject: composeRef.current?.getSelectedPatient().label,
          ownerUserId: authState.user.id,
          patientId: composeRef.current?.getSelectedPatient().patientId,
          tags: "",
          type: "GENERAL",
        };
        const response = await appDispatch(
          requestcreateConversationAsync(createConversationPayload)
        ).unwrap();
        appDispatch(setCurrentChatMessages([]));
        if (setConversation) {
          setConversation(response);
        }
        return response;
      } catch (error: any) {
        if (error?.status === 400 && error?.data?.conversationID === -1) {
          toast.error(error?.data?.response, {
            toastId: "create-conversation-error",
          });
        }
      }
    } else {
      appDispatch(setCurrentChatMessages([]));
      appDispatch(clearCurrentChat());
      if (setConversation) {
        setConversation(null);
      }
    }
  };

  const checkIfConversationPresent = useCallback(async () => {
    const selectedParticipants = selectedList.map(({ key, id }) => {
      if (id) {
        return id?.toString() ?? "";
      } else {
        return key?.toString() ?? "";
      }
    });
    if (selectedParticipants.length >= 1) {
      appDispatch(setIsLoadingChat(true));
      const getConversationIfPresentPayload = {
        ownerUserId: authState.user.id,
        participantsUserId: [...selectedParticipants],
        subject: composeRef.current?.getSelectedPatient().label,
        patientId: composeRef.current?.getSelectedPatient()?.patientId,
        tags: "",
        type: "general",
      };

      appDispatch(
        requestGetConversationIfPresentAsync(getConversationIfPresentPayload)
      ).then((res: any) => {
        if (!res.payload || (res.payload && isEmptyString(res.payload ?? ""))) {
          appDispatch(setIsLoadingChat(false));
          appDispatch(clearCurrentChat());
          initializeConversation();
        }
      });
    }
  }, [selectedList, appDispatch]);

  useEffect(() => {
    checkIfConversationPresent();
  }, [checkIfConversationPresent]);

  useEffect(() => {
    if (currentChat.conversationId !== -1) {
      fetchAllMessages();
    }
  }, [fetchAllMessages, currentChat.conversationId, offset]);

  useEffect(() => {
    if (twilioClientRef) {
      initializeTwilioEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twilioClientRef, currentChat.twilioConversationId]);

  const getConversationMessageCount = useCallback(async () => {
    if (currentChat.twilioConversationId) {
      appDispatch(
        requestGetMessageCountAsync(currentChat.conversationId.toString())
      ).then((res: any) => {
        setMessageCount(res.payload.count);
      });
    }
  }, [currentChat.name]);

  useEffect(() => {
    getConversationMessageCount();
  }, [getConversationMessageCount]);

  const sendMessage = async (
    fileUploaded: File | null = null,
    fileType?: string,
    retry?: boolean,
    id?: string | number
  ) => {
    setUploadPercentage(0);
    if (!isMessageButtonDisabled) {
      setIsMessageButtonDisabled(true);
      try {
        let newConversation: any = null;
        let convID = -1;
        let twillioConvID = "";

        if (currentChat?.twilioConversationId === "") {
          newConversation = await initializeConversation();
          if (
            newConversation?.conversationID &&
            newConversation?.conversationTwilioID
          ) {
            convID = newConversation.conversationID;
            twillioConvID = newConversation.conversationTwilioID;
          }
        } else {
          convID = currentChat.conversationId;
          twillioConvID = currentChat.twilioConversationId;
        }
        const date = new Date().getTime().toString();
        const newMessage = {
          _id: date,
          createdAt: moment(new Date()).toISOString(),
          message: {
            type: fileType || "TEXT",
            content: fileToBeUploaded
              ? URL.createObjectURL(fileToBeUploaded)
              : (inputValue as string),
            file: { type: fileType || "TEXT" },
            fileName: fileToBeUploaded?.name ?? "Test",
            fileSize: fileToBeUploaded?.size ?? 0,
          },
          user: {
            name: authState.user.username,
            email: authState.user.email,
            _id: authState.user.id,
          },
        };
        try {
          const res = await appDispatch(
            requestSendMessageAsync({
              conversationId: convID,
              conversationTwilioId: twillioConvID,
              message: fileUploaded === null ? inputValue : "",
              messageType: fileType || "TEXT",
              messageOwnerEmail: authState.user.email,
              messageOwnerUserId: authState.user.id,
              fileUpload: fileUploaded,
              onUpload: (progressEvent: ProgressEvent) => {
                if (fileUploaded) {
                  const percentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setUploadPercentage(percentage);
                  if (percentage === 100) {
                    setTimeout(() => {
                      setShowPreviewThumbnail([]);
                      setUploadPercentage(0);
                    }, 1000);
                  }
                }
              },
              abortController: cancelController,
            })
          ).unwrap();
          if (res) {
            if (fileToBeUploaded) {
              setFileToBeUploaded(null);
            }
            setunreadCount((prev) => prev + 1);
            appDispatch(
              setCurrentChatMessages([newMessage, ...currentChatMessages])
            );
            setInputValue("");
          }
          setIsMessageButtonDisabled(false);
        } catch (error: any) {
          if (error?.status === 500) {
            toast.error("Something went wrong", {
              toastId: "conversation-error",
            });
          }
          if (fileToBeUploaded) {
            setFileToBeUploaded(null);
          }
          setIsMessageButtonDisabled(false);
        }
        setIsMessageButtonDisabled(false);
      } catch (error: any) {
        setIsMessageButtonDisabled(false);
        console.log(error);
      }
    }
  };

  const fetchMoreData = () => {
    setOffset(currentChatMessages.length);
  };

  const onClickFileAttachment = async (e: any) => {
    const files: FileList = e.target.files;
    const file: File | null = files.item(0);
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (
          file?.type === "image/png" ||
          file?.type === "image/jpeg" ||
          file?.type === "image/jpg" ||
          file?.type === "application/pdf" ||
          file?.type === "application/msword" ||
          file?.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          setIsCancelled(false);
          const fileUrl = URL.createObjectURL(file);
          setFileToBeUploaded(file);
          setShowPreviewThumbnail((prev) => [
            ...prev,
            {
              url: fileUrl,
              type: file?.type,
              name: file?.name,
              size: file?.size,
            },
          ]);
        } else {
          toast.success(
            "Please select a valid format which include PNG, JPG, PDF, WORD files.",
            { containerId: "main" }
          );
        }
      };
    }
  };

  const onChangeInputValue = (value: string) => {
    setInputValue(value);
  };

  const onRemoveThumbnail = (index: number) => {
    const copyOfThumbnails = [...previewThumbnail];
    copyOfThumbnails.splice(index, 1);
    setShowPreviewThumbnail(copyOfThumbnails);
    setFileToBeUploaded(null);
  };

  const getFileType = () => {
    const file = previewThumbnail[0];
    const fileType = file?.type.split("/")[0].toUpperCase().trim();
    if (!file) {
      return "TEXT";
    } else if (fileType === "IMAGE") {
      return "IMAGE";
    } else if (file.type === "application/pdf") {
      return "PDF";
    } else if (
      file?.type === "application/msword" ||
      file?.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return "WORD";
    }
  };

  const cancelUpload = (controller?: AbortController) => {
    cancelController.abort();
    if (controller) {
      setUpdatedController(controller);
    }
  };

  const getNextButtonDisableStatus = (): boolean => {
    if (composeRef.current?.includePatientInParticipationList()) {
      return true;
    }
    return composeRef.current?.getSelectedNavigators() &&
      composeRef.current?.getSelectedNavigators().length > 0 &&
      (composeRef.current?.getSelectedPatient() !== undefined ||
        composeRef.current?.getSelectedPatient() !== null)
      ? true
      : false;
  };

  const onNextButtonClick = () => {
    const physician = {
      key: authState.user.id,
      name: `${authState.user.firstName} ${authState.user.lastName}`,
      label: `${authState.user.firstName} ${authState.user.lastName}`,
      value: `${authState.user.firstName} ${authState.user.lastName}`,
      ...authState.user,
    };
    const participantList = [
      { ...physician },
      ...(composeRef.current?.getSelectedNavigators() as ISelectedDropdownValue[]),
    ];
    if (composeRef.current?.includePatientInParticipationList()) {
      participantList.push(composeRef.current?.getSelectedPatient());
    } else {
      const index = participantList
        .map((item) => item.key)
        .indexOf(composeRef.current?.getSelectedPatient().key as string);
      if (index !== -1) {
        participantList.splice(index, 1);
      }
    }
    addItemInSelectedList?.(participantList);
  };

  const callDisable = () => {
    setNextButtonDisable(getNextButtonDisableStatus());
  };

  useEffect(() => {
    window.addEventListener("click", callDisable);

    return () => {
      window.removeEventListener("click", callDisable);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIfGroupConversationExist = () =>
    currentChat.participants.length > 2;

  const getInActiveParticipantInConversation = () =>
    currentChat.participants.find((el) => !el.isActive);
  const renderInActiveInfoBar = () => {
    const inActiveParticipant = getInActiveParticipantInConversation();

    if (!inActiveParticipant) {
      return null;
    }

    if (checkIfGroupConversationExist()) {
      return (
        <div className="inactive-infobar-container">
          <span className="note">
            <span className="note-label">Note:</span>{" "}
            {inActiveParticipant.fullName} has been removed from the
            conversation
          </span>
        </div>
      );
    } else {
      return (
        <div className="inactive-infobar-container">
          <span className="note">
            <span className="note-label">Note:</span>
            The account for {inActiveParticipant.fullName} has been deleted. As
            a result this conversation cannot be continued.
          </span>
        </div>
      );
    }
  };

  const shouldHideChatFooter = () =>
    !checkIfGroupConversationExist() &&
    !!getInActiveParticipantInConversation();

  return (
    <div
      className={`chat-content ${
        currentChat.conversationId === -1 ? MessageOpenFrom.COMPOSE : ""
      }`}
    >
      {currentChat.conversationId === -1 && (
        <div className="compose-message-section">
          <ComposeContent ref={composeRef} />
        </div>
      )}
      <ChatList
        messageDetails={currentChatMessages}
        totalLength={messageCount}
        fetchMoreData={fetchMoreData}
        isPreviewThumbnailVisible={previewThumbnail.length > 0}
        cancelUpload={cancelUpload}
        sendMessage={sendMessage}
        isGroupChat={
          !!currentChat?.name?.length &&
          currentChat?.name?.split(",").length > 1
        }
        unreadCount={unreadCount}
        readCount={readCount}
        openFrom={
          currentChat.conversationId === -1
            ? MessageOpenFrom.COMPOSE
            : MessageOpenFrom.EMPTY
        }
        loading={
          openFrom === MessageOpenFrom.COMPOSE
            ? selectedList.length > 0 && isLoadingChat
            : isLoadingChat
        }
        renderInfoBarWithoutFooter={
          shouldHideChatFooter() && !!renderInActiveInfoBar()
        }
        renderInfoBarWithFooter={
          !shouldHideChatFooter() && !!renderInActiveInfoBar()
        }
      />
      {renderInActiveInfoBar()}
      {!shouldHideChatFooter() && (
        <div className="footer-container">
          <div
            className={`preview-section ${
              previewThumbnail.length > 0 ? "show" : "hidden"
            }`}
          >
            {previewThumbnail.length > 0 &&
              previewThumbnail.map((thumbnail, index) => {
                return (
                  <>
                    <Thumbnail
                      key={index}
                      thumbnail={thumbnail}
                      onRemove={onRemoveThumbnail}
                      index={index}
                    />
                    {uploadPercentage > 0 && (
                      <div className="progress-bar">
                        {isCancelled ? (
                          <div
                            className="cancel-upload"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsCancelled(false);
                              sendMessage(
                                fileToBeUploaded,
                                getFileType(),
                                true
                              );
                            }}
                          >
                            <img src={RetryIcon} alt="retry" />
                          </div>
                        ) : (
                          <>
                            <ProgressBar percentage={uploadPercentage} />
                            <div
                              className="retry-upload"
                              onClick={(e) => {
                                e.stopPropagation();
                                cancelUpload(cancelController);
                                setIsCancelled(true);
                              }}
                            >
                              <Icon icon="close" size={14} />
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </>
                );
              })}
          </div>
          {currentChat.conversationId === -1 ? (
            <Button
              className="next-button green-button"
              text="Next"
              disabled={!getNextButtonDisableStatus()}
              onClick={onNextButtonClick}
            />
          ) : (
            <ChatFooter
              inputValue={inputValue}
              onChangeInputValue={onChangeInputValue}
              sendMessage={() => {
                if (fileToBeUploaded || inputValue.trim().length !== 0) {
                  sendMessage(fileToBeUploaded, getFileType(), false);
                }
              }}
              disabled={
                openFrom === MessageOpenFrom.COMPOSE
                  ? selectedList.length === 0 ||
                    isLoadingChat ||
                    isMessageButtonDisabled
                  : isLoadingChat || isMessageButtonDisabled
              }
              onClickFileAttachment={onClickFileAttachment}
              previewThumbnail={previewThumbnail}
              isSendDisabled={
                (!fileToBeUploaded && inputValue.trim().length === 0) ||
                isLoadingChat ||
                isMessageButtonDisabled
              }
              placeholder="Maximum allowed length is 3200"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ChatModalContent;
