import { useEffect } from "react";
import Breadcrumb from "components/Breadcrumb";
import Header from "components/Header";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { getGender } from "shared/methods/utilityFunctions";
import {
  fetchTransitionOfCareDetailsAsync,
  getAllTocFacilitiesAsync,
  getPacTypesAsync,
} from "state/features/toc/toc.action";
import { getTocs, resetTocs } from "state/features/toc/toc.slice";
import "./toc-detail.styles.scss";
import EmptyState from "components/empty-state/empty-state.component";
import { toast } from "react-toastify";
import { EmptyStateType } from "components/empty-state/empty-state.enum";
import { TOC_LISTING_HEADERS } from "./constants/index.constant";
import TocRevision from "./toc-revision/toc-revision";
import { useAppDispatch } from "state/store";

const TocDetail = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { intakeId } = useParams<{ intakeId: string }>();
  const tocState = useSelector(getTocs);
  const { isTocsLoading, tocDetails } = tocState;

  const isPresentTocData =
    tocState && tocState.tocDetails && tocState.tocDetails.length > 0;

  useEffect(() => {
    dispatch(fetchTransitionOfCareDetailsAsync(intakeId));
  }, [intakeId]);

  useEffect(() => {
    dispatch(getAllTocFacilitiesAsync(intakeId));
    dispatch(getPacTypesAsync());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetTocs());
    };
  }, []);

  useEffect(() => {
    if (tocState && tocState.isValid && !tocState.isError) {
      toast.success("TOC approved successfully.", {
        toastId: "toc-approval",
      });
      history.replace("/toc");
    }
  }, [tocState]);

  const getTocsList = () => {
    return tocDetails.map((toc) => <TocRevision toc={toc} key={toc.id} />);
  };
  if (isPresentTocData) {
    return (
      <div id="toc-detail">
        <div className="toc-detail-presentation">
          <div className="bread-crumb">
            <Breadcrumb
              pathname={location.pathname}
              extraInfo={tocState.tocDetails[0].patient.name}
            />
          </div>
          <Header className="toc-detail">
            <div className="heading">{tocState.tocDetails[0].patient.name}</div>
            <div className="age-gender">
              {getGender(tocState.tocDetails[0].patient.gender)}
              {"  "}
              {tocState.tocDetails[0].patient.age
                ? tocState.tocDetails[0].patient.age + " years"
                : " "}
            </div>
          </Header>
          <div className="more-details">
            <div className="info">
              <div className="row">Navigator Assigned</div>
              <div className="val">
                {tocState.tocDetails[0].careManagerName
                  ? tocState.tocDetails[0].careManagerName
                  : "-"}
              </div>
            </div>
            <div className="info">
              <div className="row">Current Location</div>
              <div className="val">
                {tocState.tocDetails[0].patient.currentLocation
                  ? tocState.tocDetails[0].patient.currentLocation
                  : "-"}
              </div>
            </div>
            <div className="info">
              <div className="row">Procedure</div>
              <div className="val">
                {tocState.tocDetails[0].procedure.procedureDescription
                  ? tocState.tocDetails[0].procedure.procedureDescription
                  : "-"}
              </div>
            </div>
            <div className="info">
              <div className="row">Date of Procedure</div>
              <div className="val">
                {tocState.tocDetails[0].procedure.surgeryDate
                  ? moment(tocState.tocDetails[0].procedure.surgeryDate).format(
                      "MM/DD/YYYY"
                    )
                  : "-"}
              </div>
            </div>
          </div>
          <div className="revisons">
            <div className="heading"> TOCs</div>
            <div className="revision-table-container">
              <div className="revision-table">
                <div className="revision-table-header">
                  {TOC_LISTING_HEADERS.map(({ name }) => {
                    return (
                      <div className="revision-table-header-cell" key={name}>
                        <div className="revision-table-header-content">
                          <div className="name">{name}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="revision-table-body">
                  {isTocsLoading ? (
                    <EmptyState type={EmptyStateType.LOADING} />
                  ) : (
                    getTocsList()
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (tocState.isLoading) {
    return (
      <div id="toc-detail">
        <EmptyState type={EmptyStateType.LOADING} />
      </div>
    );
  } else {
    return (
      <div id="toc-detail">
        <EmptyState type={EmptyStateType.NO_TOC_DETAILS} />
      </div>
    );
  }
};

export default TocDetail;
