import Modal from "components/Modal";
import Button from "components/Button";
import "./styles.scss";

type ConfirmationModalProps = {
  isOpen: boolean;
  content: string;
  handleCancelClick: () => void;
  handleConfirmClick: () => void;
  header?: string;
};

const ConfirmationModal = ({
  isOpen,
  content,
  handleCancelClick,
  handleConfirmClick,
  header,
}: ConfirmationModalProps) => {
  return (
    <Modal isOpen={isOpen} className="center">
      <div className="confirmation-modal-container">
        <div className="header">{header}</div>
        <div className="content">{content}</div>
        <div className="button-section">
          <Button
            text="Cancel"
            className="white-button cancel-button"
            onClick={handleCancelClick}
          />
          <Button
            text="Confirm"
            className="green-button confirm-button"
            onClick={handleConfirmClick}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
