import React, { useEffect, useState } from "react";
import Button from "components/Button";
import SingleDatePicker from "components/SingleDatePicker";
import { useDispatch, useSelector } from "react-redux";
import SidePopup from "components/Modal/SidePopup";
import { DischargeDateProps, FilterModalProps } from "./toc-filter-modal.types";
import "./toc-filter-modal.component.scss";
import { subDays } from "date-fns";
import {
  getCommon,
  setIsFilterModalVisible,
} from "state/features/common/common.slice";

const TOCFilterModal = (props: FilterModalProps) => {
  const { handleFilterApplyClick, handleFilterClearClick, dates } = props;
  const [isOpen, setIsOpen] = useState(false);
  const commonState = useSelector(getCommon);
  const dispatch = useDispatch();
  const [dischargeDates, setDischargeDates] = useState<DischargeDateProps>({
    dischargeDateFrom: null,
    dischargeDateTo: null,
  });
  const isApplyButtonDisabled =
    dischargeDates.dischargeDateFrom === null &&
    dischargeDates.dischargeDateTo === null;

  const isClearButtonDisabled =
    dischargeDates.dischargeDateFrom === null &&
    dischargeDates.dischargeDateTo === null;

  useEffect(() => {
    setDischargeDates({
      dischargeDateFrom: dates.dateFrom,
      dischargeDateTo: dates.dateTo,
    });
  }, [dates]);

  useEffect(() => {
    setTimeout(() => setIsOpen(commonState.isFilterModalVisible), 100);
  }, [commonState.isFilterModalVisible]);

  return (
    <SidePopup
      isOpen={isOpen}
      onClose={() => dispatch(setIsFilterModalVisible(false))}
      contentClass="filter-content-container"
      className="filter-side-popup"
      heading="Filter"
    >
      <div className="filter-content">
        <div className="filter-buttons">
          <div className="surgery-sub-heading">Discharge Start Date</div>
          <div className="checkbox-container">
            <SingleDatePicker
              defaultDate={dischargeDates.dischargeDateFrom}
              required={false}
              minDate={null}
              maxDate={null}
              isError={false}
              isDisabled={false}
              handleDateChange={(val: Date | null) => {
                setDischargeDates((prevState) => {
                  return {
                    ...prevState,
                    dischargeDateFrom: val,
                    dischargeDateTo: null,
                  };
                });
              }}
              errorMessage='Please select the date.'
              popperPlacement="bottom"
            />
          </div>
          <div className="surgery-sub-heading surgery-date-to">
            Discharge End Date
          </div>
          <div className="checkbox-container">
            <SingleDatePicker
              defaultDate={dischargeDates.dischargeDateTo}
              required={false}
              minDate={
                dischargeDates.dischargeDateFrom
                  ? subDays(dischargeDates.dischargeDateFrom, 1)
                  : null
              }
              maxDate={null}
              isError={false}
              isDisabled={false}
              handleDateChange={(val: Date | null) => {
                setDischargeDates((prevState) => {
                  return {
                    ...prevState,
                    dischargeDateTo: val,
                  };
                });
              }}
              errorMessage='Please select the date.'
              popperPlacement="bottom"
            />
          </div>
        </div>
        <div className="filter-buttons">
          <Button
            disabled={isApplyButtonDisabled}
            onClick={() => handleFilterApplyClick(dischargeDates)}
            text="Apply"
            className={`green-button apply-button ${
              isApplyButtonDisabled ? "disabled" : "enabled"
            }`}
          />
          <Button
            onClick={() => {
              setDischargeDates({
                dischargeDateFrom: null,
                dischargeDateTo: null,
              });
              handleFilterClearClick();
            }}
            text="Clear"
            className={`white-button clear-button ${
              isClearButtonDisabled ? "disabled" : "enabled"
            }`}
          />
        </div>
      </div>
    </SidePopup>
  );
};

export default TOCFilterModal;
