import { PatientFormTabs } from "../enums";

export const tablist = [
  {
    name: "Surgeon and Episode Details",
    id: PatientFormTabs.SURGEON_AND_EPISODE_DETAILS,
  },
  {
    name: "Patient Personal Details",
    id: PatientFormTabs.PATIENT_PERSONAL_DETAILS,
  },
  {
    name: "Patient Address",
    id: PatientFormTabs.PATIENT_ADDRESS,
  },
];

export const surgerySiteSideData = [
  { key: "1", name: "Cervical - N/A" },
  { key: "2", name: "Coccys - N/A" },
  { key: "3", name: "Lumbar - N/A" },
  { key: "4", name: "Sacral - N/A" },
  { key: "5", name: "Sacroiliac - Left" },
  { key: "6", name: "Sacroiliac - Right" },
  { key: "7", name: "Thoracic - N/A" },
  { key: "8", name: "N/A - N/A" },
  { key: "9", name: "A/P lumbar - N/A" },
  { key: "10", name: "A/P cervical - N/A" },
];

export const keys = {
  SURGEON_AND_EPISODE_DETAILS: "surgeonAndEpisodeDetails",
  PATIENT_PERSONAL_DETAILS: "patientPersonalDetails",
  PATIENT_ADDRESS: "patientAddress",
};

export const Warnings = [
  {
    Message:
      "The surgeon or admitting physician is not part of the contracted network for Episode (Chronic obstructive pulmonary disease, bronchitis/asthma).",
    RowNumber: -1,
    ColumnName: null,
  },
  {
    Message:
      "The surgeon or admitting physician is not part of the contracted network for Episode (Cardiac defibrillator).",
    RowNumber: -1,
    ColumnName: null,
  },
  {
    Message:
      "The surgeon or admitting physician is not part of the contracted network for Episode (Major joint replacement of the lower extremity).",
    RowNumber: -1,
    ColumnName: null,
  },
];
