import React from "react";
import Modal from "components/Modal";
import Icon from "components/Icon";
import { SidePopupProps } from "./props";
import "./styles.scss";
import { CustomToolTip } from "components/tool-tip/tool-tip.component";

const SidePopup = ({
  isOpen,
  onClose,
  children,
  contentClass,
  className,
  heading,
  headerClass,
  modalFooter,
  showPatientDetails,
}: SidePopupProps) => {
  return (
    <Modal
      className={`side-popup right ${className ? className : ""}`}
      animationClass="side-popup-animation"
      contentClass={`side-popup-content ${contentClass ? contentClass : ""}`}
      isOpen={isOpen}
      showPatientDetails={showPatientDetails}
      modalHeader={
        <div className={`header ${headerClass ? headerClass : ""}`}>
          <div data-tip data-for="Close" onClick={onClose}>
            <Icon icon="close" size={16} className="close-icon" />
            <CustomToolTip text="Close" place="bottom" />
          </div>
          <div className="heading">{heading}</div>
        </div>
      }
      modalFooter={modalFooter}
    >
      {children}
    </Modal>
  );
};

export default SidePopup;
