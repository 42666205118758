import React, { useCallback, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { HOME_CONSTANTS, OTP_CONSTANTS } from "../../shared/constant/constants";
import LoginScreenImage from "../../shared/assets/images/login-screen-image.png";
import Icon from "components/Icon";
import Button from "components/Button";
import ResendArrow from "shared/assets/images/green-resend-arrow.svg";
import "./otp-screen.styles.scss";
import { useHistory, useLocation } from "react-router";
import TextField from "components/TextField";
import Header from "components/Header";
import Logo from "../../shared/assets/images/logo.png";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import {
  requestSendOtpAsync,
  requestUpdatePasswordAsync,
  requestVerifyOtpAsync,
} from "state/features/authentication/authentication.action";
import { useAppDispatch } from "state/store";
import { getAuth } from "state/features/authentication/authentication.slice";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const OTPScreen = () => {
  const [seconds, setSeconds] = useState(HOME_CONSTANTS.remainingTimeSeconds);
  const [minute, setMinute] = useState(HOME_CONSTANTS.remainingTimeMinutes);
  const currentTime = moment().toDate().getTime();
  const deadlineCalculation = new Date(currentTime + 10 * 60 * 1000);
  const [deadline, setDeadLine] = useState(deadlineCalculation);
  const [disableInput, setDisableInput] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [otp, setOtp] = useState<string | null>(null);
  const [showResend, setShowResend] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [showVerifiedText, setShowVerifiedText] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const dispatch = useAppDispatch();
  const authState = useSelector(getAuth);
  const history = useHistory();

  const prepend = (n: number) => {
    return n < OTP_CONSTANTS.OTP_Seconds_Check ? "0" + n : n;
  };

  function time_remaining(endtime: Date) {
    var t = moment(endtime).diff(new Date(), "milliseconds");
    var seconds = Math.floor((t / 1000) % 60);
    var minutes = Math.floor((t / 1000 / 60) % 60);
    return { total: t, minutes: minutes, seconds: seconds };
  }

  useEffect(() => {
    let time: any;
    time = time_remaining(deadline);
    setMinute(time.minutes);
    setSeconds(time.seconds);
    var timeinterval = setInterval(() => {
      time = time_remaining(deadline);
      setMinute(time.minutes);
      setSeconds(time.seconds);
      if (time && time.total <= 0) {
        setShowResend(true);
        clearInterval(timeinterval);
      }
    }, 1000);
    return () => clearInterval(timeinterval);
  }, [deadline]);

  const handleChange = (otp: string) => setOtp(otp);

  const handleResend = async () => {
    setSeconds(HOME_CONSTANTS.remainingTimeSeconds);
    setMinute(HOME_CONSTANTS.remainingTimeMinutes);
    try {
      dispatch(requestSendOtpAsync(authState.user.username)).then((res) => {
        setOtp(null);
        setIsVerified(false);
        const current_time = moment().toDate().getTime();
        const deadlineCalculation = new Date(current_time + 10 * 60 * 1000);
        setDeadLine(deadlineCalculation);
      });
    } catch (error) {
      console.log(error);
    }
    setDisableInput(false);
  };

  const resetPasswordScreen = useCallback(() => {
    setShowResetPassword(true);
  }, []);

  useEffect(() => {
    if (otp && otp?.length === 4) {
      dispatch(
        requestVerifyOtpAsync({
          verificationCode: otp ?? "",
          phoneNumber: authState.user.phoneNumber,
        })
      ).then((res: any) => {
        if (res && res.error) {
          setOtp(null);
        } else {
          setShowVerifiedText(true);
          setTimeout(() => {
            setIsVerified(true);
            resetPasswordScreen();
          }, 2000);
        }
      });
    }
  }, [otp]);

  const updatePassword = async () => {
    dispatch(
      requestUpdatePasswordAsync({
        username: authState.user.username,
        password: passwordValue,
        confirmPassword: passwordValue,
        code: authState.auth.resetPasswordCode,
      })
    ).then((res) => {
      if (res.payload) {
        if (res.payload.isError) {
          toast.error(res.payload.errorMessage, {
            toastId: "password-changed",
          });
        }
        else {
          toast.success("Password successfully changed", {
            toastId: "password-changed-success",
          });
          setTimeout(() => history.push("/"), 1000);
        }
      }
    });
  };

  const passwordNotEmpty = passwordValue.length > 0;
  const isLengthGreaterThanOrEqualTo8 = passwordValue.length >= 8;
  const containsDigit = /\d/.test(passwordValue);
  const containsUppercase = /[A-Z]/.test(passwordValue);
  const containsLowercase = /[a-z]/.test(passwordValue);

  var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const containsSpecialCharacter = format.test(passwordValue);

  useEffect(() => {
    if (
      passwordNotEmpty &&
        isLengthGreaterThanOrEqualTo8 &&
      containsDigit &&
      containsUppercase &&
      containsLowercase &&
      containsSpecialCharacter
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [
    containsDigit,
    containsLowercase,
    containsSpecialCharacter,
    containsUppercase,
    isLengthGreaterThanOrEqualTo8,
    passwordNotEmpty,
    passwordValue,
  ]);

  const togglePassword = () => {
    setShowPassword((prev) => {
      const element = document.querySelector(
        ".password-input"
      ) as HTMLInputElement;
      if (!prev) {
        element.type = "text";
      } else {
        element.type = "password";
      }
      return !prev;
    });
  };

  return (
    <>
      <div className="otpScreenContainer">
        <div className="otpFormWrapper">
          <Header className="otp">
            <img src={Logo} alt="logo" className="logo" />
          </Header>
          <div className="left-content">
            <div className="heading">
              <div className="reset-password-icon-container">
                <Icon
                  icon="reset-password"
                  className="reset-password-icon"
                  size={15}
                />
              </div>
              <div className="reset-password-heading">Reset Password</div>
            </div>
            <div className="otpBoxes">
              {!showResetPassword && !isVerified && (
                <>
                  <div className="otp-line">
                    Enter the OTP we sent to your mobile phone via SMS
                  </div>
                  <OtpInput
                    value={otp ? otp : ""}
                    onChange={handleChange}
                    numInputs={OTP_CONSTANTS.OTP_Length}
                    inputStyle={`inputStyling ${
                      authState.isError ? "error" : ""
                    } ${showVerifiedText ? "verified" : ""}`}
                    placeholder={OTP_CONSTANTS.OTP_Placeholder}
                    isInputNum={true}
                    isDisabled={disableInput}
                  />
                </>
              )}
              {showResetPassword && isVerified && (
                <>
                  <div className="reset-password-section">
                    <TextField
                      label="Password"
                      className="input-field"
                      inputClassName="password-input"
                      type="password"
                      value={passwordValue}
                      setValue={(label, value) => setPasswordValue(value)}
                      icon={
                        <Icon
                          icon="password"
                          size={19}
                          className="username-icon"
                        />
                      }
                      placeholder="Enter New Password"
                      togglePassword={togglePassword}
                      isVisiblePassword={showPassword}
                    />
                    <Button
                      buttonWithLoader
                      isLoading={authState.isLoading}
                      text="Confirm"
                      className="green-button confirm-button"
                      onClick={() => updatePassword()}
                      disabled={disableButton}
                    />
                  </div>
                  <ul className="validations">
                    <li className={`${isLengthGreaterThanOrEqualTo8 ? "tick" : ""}`}>
                      <span>Must contain at least 8 characters</span>
                    </li>
                    <li className={`${containsDigit ? "tick" : ""}`}>
                      <span>Must contain a number</span>
                    </li>
                    <li
                      className={`${
                        passwordNotEmpty && containsUppercase ? "tick" : ""
                      }`}
                    >
                      <span>Must contain an upper-case character</span>
                    </li>
                    <li
                      className={`${
                        passwordNotEmpty && containsLowercase ? "tick" : ""
                      }`}
                    >
                      <span>Must contain a lower-case character</span>
                    </li>
                    <li className={`${containsSpecialCharacter ? "tick" : ""}`}>
                      <span>Must contain a special character i.e. @_!%</span>
                    </li>
                  </ul>
                </>
              )}
            </div>
            {!showResetPassword && !showResend && authState.isError && (
              <div className="error">Enter correct OTP</div>
            )}
            {!showResetPassword &&
              !showResend &&
              !authState.isError &&
              showVerifiedText && <div className="verified">Verified</div>}
            {!showResetPassword && !showResend ? (
              <div className="timeRemainingDiv">
                <span className="timeRemainingSpan">
                  Code expires in{" "}
                  <span className="seconds">{`${prepend(minute)} `}</span>
                  minutes
                  <span className="seconds">{` ${prepend(seconds)}`}</span>{" "}
                  seconds
                </span>
              </div>
            ) : (
              !showResetPassword &&
              showResend && (
                <div className="button-wrapper">
                  {authState.isError ? (
                    <div className="error">Enter correct OTP</div>
                  ) : showVerifiedText ? (
                    <div className="verified">Verified</div>
                  ) : (
                    <div />
                  )}
                  <div className="button-container">
                    <Button
                      text="Resend OTP"
                      onClick={() => handleResend()}
                      className="no-border-button resend-otp"
                      imgChildren={<img src={ResendArrow} alt="resend arrow" />}
                    />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        <div className="rightImageBlock">
          <img
            src={LoginScreenImage}
            alt="login screen"
            className="login-screen-image"
          ></img>
          <div className="big-text">
            <div className="line1">Manage Your Patients</div>
            <div className="line2">Effortlessly</div>
          </div>
        </div>
      </div>
    </>
  );
};
