import React from "react";
import { TextAreaProps } from "./props";
import "./styles.scss";

const TextArea = ({
  leftLabel,
  rightLabel,
  inputText,
  handleChange,
  showError,
  errorMessage,
  required,
  rows,
  isDisabled,
  id,
}: TextAreaProps) => {
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const handleOnFocus = () => {
    setIsFocused(true);
  };
  const handleOnBlur = () => {
    setIsFocused(false);
  };
  return (
    <div className="textarea-input-group-section" id={id}>
      <div className="label-container">
        <label className="left-label">
          {leftLabel}
          {required && <span className="red-color">*</span>}
        </label>
        <label className="right-label">{rightLabel}</label>
      </div>
      <textarea
        className={`${showError ? "red-border" : isFocused ? "focused" : ""}`}
        name="story"
        rows={rows !== null ? rows : 8}
        onChange={(event) => {
          event.preventDefault();
          handleChange(event.target.value);
        }}
        value={inputText}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        disabled={isDisabled}
      />
      {showError && <div className="error-wrap">{errorMessage}</div>}
    </div>
  );
};

export default TextArea;
