import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosPrivate } from "shared/api/axios-wrapper.service";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { setSuggestions } from "./patient-episodes.slice";
import { getConfig } from "../../../config/config";
import { IPatientEpisodePayloadProps } from "shared/types/patient-episodes.types";

export const getPatientEpisodesByPractice = createAsyncThunk(
  "patients/getPatientEpisodesByPractice",
  async (requestPayload: IPatientEpisodePayloadProps, { dispatch, signal }) => {
    const response = await axiosPrivate.post(
      `${getConfig().efusionBase}/api/v2/ExtPatient/getPatientsByClient`,
      requestPayload,
      { signal }
    );

    if (
      response.data.Patients.length > 0 &&
      !isEmptyString(requestPayload.searchKeyword) &&
      requestPayload.searchKeyword.length >= 2 &&
      response.data.Patients[0].FirstName.toLowerCase().startsWith(
        requestPayload.searchKeyword
      )
    ) {
      dispatch(
        setSuggestions(response.data.Patients[0].FirstName.toLowerCase())
      );
    } else {
      dispatch(setSuggestions(""));
    }

    return response.data;
  }
);
