export enum EmptyStateType {
  NO_PENDING_TOCS,
  NO_APPROVED_TOCS,
  NO_TOCS,
  NO_MESSAGES,
  NO_EPISODES,
  NO_PATIENT_EPISODE,
  NO_TOC_DETAILS,
  LOADING,
}
