import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { ModalType } from "./props";
import { CSSTransition } from "react-transition-group";
import "./styles.scss";

const Modal = ({
  modalHeader,
  modalFooter,
  children,
  isOpen,
  className,
  contentClass,
  animationClass,
  modalRef,
  id,
  showPatientDetails,
}: ModalType) => {
  const [isOpenModal, setOpenModal] = useState(isOpen);

  useEffect(() => {
    setOpenModal(isOpen);
  }, [isOpen]);

  return createPortal(
    <CSSTransition
      in={isOpenModal}
      timeout={{ enter: 0, exit: 0.3 }}
      unmountOnExit
      classNames={animationClass ? animationClass : "modal-wrapper"}
    >
      <div
        id={id}
        ref={modalRef}
        className={`modal-container ${className ? className : ""}`}
      >
        <div className={`modal-main ${contentClass ? contentClass : ""}`}>
          {modalHeader && (
            <div
              className={`modal-header ${
                showPatientDetails ? "modal-header-with-patient-details" : ""
              }`}
            >
              {modalHeader}
            </div>
          )}
          <div
            className={`modal-body ${
              showPatientDetails ? "modal-body-with-patient-details" : ""
            }`}
          >
            {children}
          </div>
          {modalFooter && <div className="modal-footer">{modalFooter}</div>}
        </div>
      </div>
    </CSSTransition>,
    document.querySelector("#modal") as HTMLElement
  );
};

export default Modal;
