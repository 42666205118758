import React from 'react';
import './styles.scss';
import loader from 'shared/assets/images/WhiteLoader.gif';

export const ButtonLoader = () => {
	return (
		<div className="loaderButton">
			<img className="button-loader-img" src={loader} alt="loader" />
		</div>
	);
};
