import React from "react";
import "./separator.styles.scss";

const Seprator = ({ time }: { time: string }) => {
  return (
    <div className="line-container">
      <div className="time">{time}</div>
    </div>
  );
};

export default Seprator;
