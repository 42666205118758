import React, { useState, useEffect } from "react";
import "./tags-search-dropdown.styles.scss";
import ClickAwayListener from "react-click-away-listener";
import Icon from "components/Icon";
import SearchBox from "components/search-box/search-box.component";
import { TagsSearchDropdownUseCase } from "./tags-search-dropdown.enum";
import HighlightedText from "components/highlighted-text/highlighted-text.component";
import TableLoader from "components/TableLoader";
import { ITagsSearchDropdownType } from "shared/types/dropdown.type";

type TagsSearchDropdownProps = {
  placeholder?: string;
  idFieldName?: string;
  handleToastDismiss?: () => void;
  dropDownMenuItems: Array<ITagsSearchDropdownType>;
  handleValueChanges: (value: Array<ITagsSearchDropdownType>) => void;
  value: Array<{ key: string; name: string; value?: any }>;
  itemClassName?: string;
  optionClassName?: string;
  dropDownContainerClass?: string;
  dropDownBoxClass?: string;
  selectionClass?: string;
  isDisabled?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  message?: string;
  onBlur?: () => void;
  onSearchTextChange: (value: string) => void;
  dropdownIsOpen?: boolean;
  maximumRange?: number;
  useCaseType: TagsSearchDropdownUseCase;
  isOptionDisabled?: string | boolean;
};

const TagsSearchDropdown = ({
  handleToastDismiss,
  placeholder,
  dropDownMenuItems,
  handleValueChanges,
  value,
  optionClassName,
  dropDownContainerClass,
  dropDownBoxClass = "",
  selectionClass = "",
  isDisabled,
  isError,
  isLoading,
  message,
  onBlur,
  onSearchTextChange,
  dropdownIsOpen,
  maximumRange,
  useCaseType,
  isOptionDisabled,
}: TagsSearchDropdownProps) => {
  const [activeOptions, setActiveOptions] = useState<
    Array<{ key: string; name: string; value?: any }>
  >([]);
  const [showOptionDropdown, setShowOptionDropdown] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  useEffect(() => {
    setActiveOptions(value);
  }, [value]);

  const handleDropdownChange = (state: any) => {
    if (handleToastDismiss) handleToastDismiss();
    setShowOptionDropdown((prev) => {
      const arrowEl = document.getElementById("select-box-bucket-down-arrow");
      if (prev) {
        arrowEl?.classList.remove("select-box-down-arrow-rotate");
        arrowEl?.classList.add("select-box-down-arrow-unrotate");
        return !prev;
      } else {
        if (state) {
          arrowEl?.classList.remove("select-box-down-arrow-unrotate");
          arrowEl?.classList.add("select-box-down-arrow-rotate");
          return !prev;
        } else return false;
      }
    });
  };

  const handleSearchInput = (searchValue: string) => {
    setShowOptionDropdown(searchValue.length > 0);
    setSearchText(searchValue);
    onSearchTextChange(searchValue);
  };

  const handleSelection = (option: any) => {
    if (maximumRange !== undefined && activeOptions.length === maximumRange) {
      return;
    }
    if (dropDownMenuItems.length === 1 || TagsSearchDropdownUseCase.ASSOCIATED_PHYSICIAN === useCaseType) {
      setShowOptionDropdown(false);
      setSearchText("");
      onSearchTextChange("");
    }

    const newSelections = [...activeOptions, option];
    setActiveOptions(newSelections);
    handleValueChanges(newSelections);
  };

  const removeSelection = (optionIdx: any) => {
    const activeOptionsReplica = [...activeOptions];
    activeOptionsReplica.splice(optionIdx, 1);
    setActiveOptions(activeOptionsReplica);
    handleValueChanges(activeOptionsReplica);
  };

  const getTextForTag = (el: any) => {
    if (
      useCaseType === TagsSearchDropdownUseCase.ASSOCIATED_PHYSICIAN ||
      useCaseType === TagsSearchDropdownUseCase.ALL_NAVIGATORS
    ) {
      return `${el.name}`;
    }
    return `${el.name}`;
  };

  const getOptionsDropdown = () => {
    if (
      useCaseType === TagsSearchDropdownUseCase.ASSOCIATED_PHYSICIAN ||
      useCaseType === TagsSearchDropdownUseCase.ALL_NAVIGATORS
    ) {
      return (
        <div className="options-box">
          {!isLoading ? (
            <ul>
              {dropDownMenuItems.length > 0 ? (
                dropDownMenuItems?.map((option: any, key) => (
                  <li key={key} onClick={() => handleSelection(option)}>
                    <p
                      className={`option ${
                        optionClassName ? optionClassName : ""
                      }`}
                    >
                      {HighlightedText(option.name, searchText)}
                    </p>
                  </li>
                ))
              ) : (
                <p className="no-result-found">No Result Found</p>
              )}
            </ul>
          ) : (
            <>
              <TableLoader />
            </>
          )}
        </div>
      );
    }
    return [];
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        handleDropdownChange(false);
        setSearchText("");
        setShowOptionDropdown(false);
      }}
    >
      <div
        className={`tag-search-dropdown-container ${
          dropDownContainerClass ? dropDownContainerClass : ""
        }`}
      >
        <span
          onBlur={onBlur}
          tabIndex={isDisabled ? -1 : 0}
          className={`select-span ${isError ? "error" : "no-error"} ${
            selectionClass ? selectionClass : ""
          } ${isDisabled ? "no-pointer-events" : ""}`}
        >
          <div className="name-container">
            {activeOptions.map((el, index) => {
              return (
                <span
                  key={`${el.key}-${index}`}
                  className="selected-option-tag"
                >
                  <span className="text">{getTextForTag(el)}</span>
                  <span
                    className="icon-content"
                    onClick={() => removeSelection(index)}
                  >
                    <Icon
                      icon="cross-in-circle"
                      size={13}
                      className="cross-in-circle-icon"
                    />
                  </span>
                </span>
              );
            })}
            <div className="search-box-wrapper">
              <SearchBox
                icon="cross"
                className="search-box"
                iconClassName="search-icon"
                SearchBoxSize={22}
                onClick={() => {
                  handleSearchInput("");
                }}
                text={searchText}
                onTextChange={(text) => handleSearchInput(text)}
                placeholder={placeholder ? placeholder : "Search"}
                autoFocus={false}
                onClear={() => {
                  handleSearchInput("");
                }}
                showCrossIcon={false}
                showSearchIcon={
                  useCaseType === TagsSearchDropdownUseCase.ALL_NAVIGATORS
                    ? true
                    : false
                }
              />
            </div>
          </div>
        </span>
        {isError && !showOptionDropdown && (
          <div className="error-color">{message}</div>
        )}
        {(dropdownIsOpen || showOptionDropdown) && (
          <div className={`option-box-wrapper ${dropDownBoxClass}`}>
            {getOptionsDropdown()}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default TagsSearchDropdown;
