import React from "react";
import "./dashboard-button.styles.scss";

type DashboardButtonProps = {
  image: string;
  text: string;
  onClick: () => void;
};

const DashboardButton = (props: DashboardButtonProps) => {
  const { onClick, image, text } = props;
  return (
    <div className="dashboard-button-container" role="button" onClick={onClick}>
      <div className="dashboard-button-container-inner">
      <img src={image} alt="dashboard button" />
      <div className="button-text">{text}</div>
      </div>
    </div>
  );
};
export default DashboardButton;
