import React, { useEffect, useState } from "react";
import Button from "components/Button";
import SingleDatePicker from "components/SingleDatePicker";
import { useDispatch, useSelector } from "react-redux";
import SidePopup from "components/Modal/SidePopup";
import {
  FilterFormType,
  FilterModalProps,
} from "./patient-episodes-filter-modal.types";
import "./patient-episodes-filter-modal.component.scss";
import { subDays } from "date-fns";
import {
  getCommon,
  setIsFilterModalVisible,
} from "state/features/common/common.slice";
import { ITagsSearchDropdownType } from "shared/types/dropdown.type";
import TagsSearchDropdown from "components/TagsSearchDropdown/tags-search-dropdown.component";
import { getAllSurgeonsAsync } from "state/features/add-edit-episode/add-edit-episode.action";
import { getAddEditEpisodeMetadata } from "state/features/add-edit-episode/add-edit-episode.slice";
import { TagsSearchDropdownUseCase } from "components/TagsSearchDropdown/tags-search-dropdown.enum";

const PatientEpisodesFilterModal = (props: FilterModalProps) => {
  const [assignedPhysiciansDropdownItems, setAssignedPhysiciansDropdownItems] =
    useState<Array<ITagsSearchDropdownType>>([]);
  const { handleFilterApplyClick, handleFilterClearClick, appliedFilter } =
    props;
  const [isOpen, setIsOpen] = useState(false);
  const commonState = useSelector(getCommon);
  const { surgeons } = useSelector(getAddEditEpisodeMetadata);
  const dispatch = useDispatch();
  const [physicianSearchText, setPhysicianSearchText] = useState<string>("");
  const [filterForm, setFilterForm] = useState<FilterFormType>({
    surgeryDateFrom: null,
    surgeryDateTo: null,
    physicians: [],
  });
  const isApplyButtonDisabled = () => {
    const { surgeryDateFrom, surgeryDateTo, physicians } = filterForm;
    return (
      !surgeryDateFrom &&
      !surgeryDateTo &&
      !appliedFilter.physicians.length &&
      !physicians.length
    );
  };

  const isClearButtonDisabled = () => {
    const { surgeryDateFrom, surgeryDateTo, physicians } = filterForm;
    return (
      !surgeryDateFrom &&
      !surgeryDateTo &&
      !appliedFilter.physicians.length &&
      !physicians.length
    );
  };

  useEffect(() => {
    if (!assignedPhysiciansDropdownItems.length) {
      dispatch(getAllSurgeonsAsync());
    }
  }, [assignedPhysiciansDropdownItems]);

  useEffect(() => {
    const { dateFrom, dateTo, physicians } = appliedFilter;
    let selectedPhysicians: Array<{ key: any; name: string; value: any }> = [];
    if (physicians.length && assignedPhysiciansDropdownItems.length) {
      physicians.forEach((physicianId: string) => {
        const idx = assignedPhysiciansDropdownItems.findIndex(
          (el) => el.key.toString() === physicianId.toString()
        );
        assignedPhysiciansDropdownItems[idx].isSelected = true;
        const physician = assignedPhysiciansDropdownItems[idx];
        selectedPhysicians.push({
          key: physician.key,
          name: physician.name,
          value: physician.value,
        });
      });
    }
    setFilterForm({
      ...filterForm,
      surgeryDateFrom: dateFrom,
      surgeryDateTo: dateTo,
      physicians: selectedPhysicians,
    });
  }, [appliedFilter, assignedPhysiciansDropdownItems]);

  useEffect(() => {
    if (surgeons.length) {
      const physicians: ITagsSearchDropdownType[] = surgeons.map(
        ({ key, name }, index) =>
          ({
            key,
            name,
            isDisabled: false,
            isSelected: false,
            value: key,
          } as ITagsSearchDropdownType)
      );

      setAssignedPhysiciansDropdownItems(physicians);
    }
  }, [surgeons]);

  useEffect(() => {
    setTimeout(() => setIsOpen(commonState.isFilterModalVisible), 100);
  }, [commonState.isFilterModalVisible]);

  const checkSearchText = (value1: string, value2: string): boolean => {
    return value1.toLowerCase().includes(value2.toLowerCase());
  };

  const filterPhysicianDropdown = (
    physician: ITagsSearchDropdownType
  ): boolean => {
    return filterForm.physicians.length > 0
      ? !filterForm.physicians.some(
          (val: ITagsSearchDropdownType) => val.key === physician.key
        ) && checkSearchText(physician.name, physicianSearchText)
      : checkSearchText(physician.name, physicianSearchText);
  };

  const getAssignedPhysicianDropdown = () => {
    return assignedPhysiciansDropdownItems.filter(filterPhysicianDropdown);
  };

  return (
    <SidePopup
      isOpen={isOpen}
      onClose={() => {
        dispatch(setIsFilterModalVisible(false));
      }}
      contentClass="filter-content-container"
      className="filter-side-popup"
      heading="Filter"
    >
      <div className="filter-content">
        <div className="filter-buttons">
          <div className="surgery-sub-heading">Surgery Date From</div>
          <div className="checkbox-container">
            <SingleDatePicker
              className="patient-episode-filter-date"
              defaultDate={filterForm.surgeryDateFrom}
              required={false}
              minDate={null}
              maxDate={null}
              isError={false}
              isDisabled={false}
              handleDateChange={(val: Date | null) => {
                setFilterForm({
                  ...filterForm,
                  surgeryDateFrom: val,
                  surgeryDateTo: null,
                });
              }}
              errorMessage="Please select the date."
              popperPlacement="bottom"
            />
          </div>
          <div className="surgery-sub-heading surgery-date-to">
            Surgery Date To
          </div>
          <div className="checkbox-container">
            <SingleDatePicker
              className="patient-episode-filter-date"
              defaultDate={filterForm.surgeryDateTo}
              required={false}
              minDate={
                filterForm.surgeryDateFrom
                  ? subDays(filterForm.surgeryDateFrom, 1)
                  : null
              }
              maxDate={null}
              isError={false}
              isDisabled={false}
              handleDateChange={(val: Date | null) => {
                setFilterForm({
                  ...filterForm,
                  surgeryDateTo: val,
                });
              }}
              errorMessage="Please select the date."
              popperPlacement="bottom"
            />
          </div>
          <div className="surgery-sub-heading surgery-date-to">
            Assigned Physician(s)
          </div>
          {assignedPhysiciansDropdownItems.length ? (
            <div className="checkbox-container">
              <TagsSearchDropdown
                dropDownMenuItems={getAssignedPhysicianDropdown()}
                value={filterForm.physicians}
                placeholder="Search By Physician Name"
                onBlur={() => {}}
                handleValueChanges={(
                  selectedTags: Array<ITagsSearchDropdownType>
                ) => {
                  setFilterForm({
                    ...filterForm,
                    physicians: selectedTags.map(({ key, name, value }) => ({
                      key,
                      name,
                      value,
                    })),
                  });
                }}
                isDisabled={!assignedPhysiciansDropdownItems.length}
                useCaseType={TagsSearchDropdownUseCase.ASSOCIATED_PHYSICIAN}
                onSearchTextChange={(searchValue: string) => {
                  setPhysicianSearchText(searchValue);
                }}
              />
            </div>
          ) : null}
        </div>
        <div className="filter-buttons">
          <Button
            disabled={isApplyButtonDisabled()}
            onClick={() => handleFilterApplyClick(filterForm, true)}
            text="Apply"
            className={`green-button apply-button ${
              isApplyButtonDisabled() ? "disabled" : "enabled"
            }`}
          />
          <Button
            onClick={() => {
              setFilterForm({
                physicians: [],
                surgeryDateFrom: null,
                surgeryDateTo: null,
              });
              handleFilterClearClick();
            }}
            text="Clear"
            className={`white-button clear-button ${
              isClearButtonDisabled() ? "disabled" : "enabled"
            }`}
          />
        </div>
      </div>
    </SidePopup>
  );
};

export default PatientEpisodesFilterModal;
