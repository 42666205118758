export const genderType = {
  MALE: { text: "Male", id: "1052" },
  FEMALE: { text: "Female", id: "1051" },
  NOT_KNOWN: { text: "Not known", id: "1050" },
};

export const prefferedPhoneType = {
  CELL: { id: "24", text: "Cell" },
  HOME: { id: "22", text: "Home" },
};

export const genderDropdownItems = [
  { id: genderType.MALE.id, name: genderType.MALE.text },
  { id: genderType.FEMALE.id, name: genderType.FEMALE.text },
  { id: genderType.NOT_KNOWN.id, name: genderType.NOT_KNOWN.text },
];
