import TagsSearchDropdown from "components/TagsSearchDropdown/tags-search-dropdown.component";
import Checkbox from "components/checkbox/checkbox.component.tsx";
import SearchWithReset from "components/search-with-reset/search-with-reset.component";
import { useSelector } from "react-redux";
import { defaultPatientValue } from "./compose-content.container";
import { ITagsSearchDropdownType } from "shared/types/dropdown.type";
import moment from "moment";
import { getMessages } from "state/features/messages/messages.slice";
import { TagsSearchDropdownUseCase } from "components/TagsSearchDropdown/tags-search-dropdown.enum";
import { NAVIGATOR_MAXIMUM_LIMIT } from "shared/constant/constants";
import {
  ComposeContentPresentationProps,
  ISelectedDropdownValue,
} from "./types";
const ComposeContentPresentation = (props: ComposeContentPresentationProps) => {
  const {
    selectedPatient,
    onChangePatient,
    isSearchable,
    onChangePatientSearch,
    patientCheckbox,
    onChangePatientCheckox,
    selectedNavigators,
    onChangeSelectedNavigators,
    navigatorSearchText,
    onChangeNavigatorSearchText,
  } = props;

  const { allContacts, isLoadingContacts } = useSelector(getMessages);

  const checkSearchText = (value1: string, value2: string): boolean => {
    return value1.toLowerCase().includes(value2.toLowerCase());
  };

  const filterNavigatorDropdown = (obj: ITagsSearchDropdownType): boolean => {
    return selectedNavigators.length > 0
      ? !selectedNavigators.some(
          (val: ITagsSearchDropdownType) => val.key === obj.key
        ) && checkSearchText(obj.name, navigatorSearchText)
      : checkSearchText(obj.name, navigatorSearchText);
  };

  const getAllNavigators = () => {
    const navigators = allContacts
      ?.filter((item: any) => item.contactType.toLowerCase() === "navigator")
      ?.map((item: any) => {
        return {
          key: item.userId,
          name: `${item.firstName} ${item.lastName}`,
          value: `${item.practiceName}`,
          ...item,
        };
      })
      ?.filter((obj: any) => filterNavigatorDropdown(obj));
    return navigators;
  };

  const getIsPatientDisabled = () => {
    if (selectedPatient === null) {
      return true;
    }
    return selectedPatient.key?.toLowerCase() === "general";
  };

  const loadOptionsOnSearch = (
    val: string,
    callback: (option: ISelectedDropdownValue[]) => void
  ) => {
    onChangePatientSearch(val).then((patientContactData) => {
      const patients = patientContactData.map((item: any) => {
        return {
          key: item.userId,
          name: `${item.firstName}${item.lastName ? ` ${item.lastName}` : ""}`,
          value: `${moment(item.dob).format("MM/DD/YYYY")}`,
          label: `${item.firstName}${item.lastName ? ` ${item.lastName}` : ""}`,
          ...item,
        };
      });
      callback(patients);
    });
  };

  const onClickResetPatient = () => {
    if (
      selectedPatient === null ||
      (typeof selectedPatient === "object" &&
        Object.hasOwn(selectedPatient, "key") &&
        selectedPatient.key !== "general")
    ) {
      onChangePatient(defaultPatientValue);
    } else {
      onChangePatient(null);
    }
  };
  
  return (
    <div className="compose-message-popup-container">
      <div className="row">
        <label>Subject</label>
        <SearchWithReset
          className="basic-single single-select-dropdown-with-search"
          inputId="subject-box"
          value={selectedPatient}
          defaultValue={defaultPatientValue}
          onTextChange={() => {
            onChangePatient(null);
          }}
          placeholder="Add Patient"
          isSearchable={isSearchable}
          onClick={onChangePatient}
          defaultOptions={[defaultPatientValue]}
          notFoundMessage="No patients found"
          loadOptions={loadOptionsOnSearch}
          onClickReset={onClickResetPatient}
          buttonText={
            selectedPatient !== null && selectedPatient.key === "general"
              ? "Or Add Patient"
              : "Reset to Default"
          }
        />
      </div>
      <div className={`row ${selectedPatient === null ? "disabled-row" : ""}`}>
        <label>Recipients</label>
        <div className="recipient-and-patient">
          <div className="recipient-text">Navigator</div>
          <div className="recipient-dropdown-container">
            <TagsSearchDropdown
              idFieldName=""
              value={selectedNavigators}
              handleValueChanges={(val: any) => {
                if (selectedNavigators.length <= NAVIGATOR_MAXIMUM_LIMIT) {
                  onChangeSelectedNavigators(val);
                }
              }}
              onSearchTextChange={(val: string) => {
                onChangeNavigatorSearchText(val);
              }}
              useCaseType={TagsSearchDropdownUseCase.ALL_NAVIGATORS}
              dropDownMenuItems={getAllNavigators()}
              placeholder="Add Navigator"
              isDisabled={selectedPatient === null}
              isLoading={isLoadingContacts}
              maximumRange={NAVIGATOR_MAXIMUM_LIMIT}
            />
            <div className="selected-navigators-count">
              Selected:
              <span>
                {" "}
                {selectedNavigators.length}/{NAVIGATOR_MAXIMUM_LIMIT}
              </span>
            </div>
          </div>
        </div>

        <div
          className={`patient-checkbox ${
            getIsPatientDisabled() ? "disabled" : ""
          }`}
        >
          <Checkbox
            id="patient"
            value="Patient"
            name="patient"
            isDisabled={getIsPatientDisabled()}
            isChecked={patientCheckbox}
            onChange={(e) => onChangePatientCheckox(e.target.checked)}
          />
        </div>
      </div>
    </div>
  );
};

export default ComposeContentPresentation;
