export const countryList = [
  { key: "AL", name: "Alabama" },
  { key: "AK", name: "Alaska" },
  { key: "AS", name: "American Samoa" },
  { key: "AZ", name: "Arizona" },
  { key: "AR", name: "Arkansas" },
  { key: "CA", name: "California" },
  { key: "CO", name: "Colorado" },
  { key: "CT", name: "Connecticut" },
  { key: "DE", name: "Delaware" },
  { key: "DC", name: "District Of Columbia" },
  { key: "FM", name: "Federated States Of Micronesia" },
  { key: "FL", name: "Florida" },
  { key: "GA", name: "Georgia" },
  { key: "GU", name: "Guam" },
  { key: "HI", name: "Hawaii" },
  { key: "ID", name: "Idaho" },
  { key: "IL", name: "Illinois" },
  { key: "IN", name: "Indiana" },
  { key: "IA", name: "Iowa" },
  { key: "KS", name: "Kansas" },
  { key: "KY", name: "Kentucky" },
  { key: "LA", name: "Louisiana" },
  { key: "ME", name: "Maine" },
  { key: "MH", name: "Marshall Islands" },
  { key: "MD", name: "Maryland" },
  { key: "MA", name: "Massachusetts" },
  { key: "MI", name: "Michigan" },
  { key: "MN", name: "Minnesota" },
  { key: "MS", name: "Mississippi" },
  { key: "MO", name: "Missouri" },
  { key: "MT", name: "Montana" },
  { key: "NE", name: "Nebraska" },
  { key: "NV", name: "Nevada" },
  { key: "NH", name: "New Hampshire" },
  { key: "NJ", name: "New Jersey" },
  { key: "NM", name: "New Mexico" },
  { key: "NY", name: "New York" },
  { key: "NC", name: "North Carolina" },
  { key: "ND", name: "North Dakota" },
  { key: "MP", name: "Northern Mariana Islands" },
  { key: "OH", name: "Ohio" },
  { key: "OK", name: "Oklahoma" },
  { key: "OR", name: "Oregon" },
  { key: "PW", name: "Palau" },
  { key: "PA", name: "Pennsylvania" },
  { key: "PR", name: "Puerto Rico" },
  { key: "RI", name: "Rhode Island" },
  { key: "SC", name: "South Carolina" },
  { key: "SD", name: "South Dakota" },
  { key: "TN", name: "Tennessee" },
  { key: "TX", name: "Texas" },
  { key: "UT", name: "Utah" },
  { key: "VT", name: "Vermont" },
  { key: "VI", name: "Virgin Islands" },
  { key: "VA", name: "Virginia" },
  { key: "WA", name: "Washington" },
  { key: "WV", name: "West Virginia" },
  { key: "WI", name: "Wisconsin" },
  { key: "WY", name: "Wyoming" },
];

export const surgeonPracticeList = [
  { key: "1", name: "AAA Care" },
  { key: "2", name: "All Care Practices" },
  { key: "3", name: "Blue Diamond Orthopedic Group" },
  { key: "4", name: "Concord Primary Care" },
  { key: "5", name: "ES General Medicine" },
  { key: "6", name: "Good Care" },
  { key: "7", name: "Health Aids" },
  { key: "8", name: "New Practice" },
  { key: "9", name: "Old post road practice" },
  { key: "10", name: "Practice Name Inactive" },
  {
    key: "11",
    name: "Quick Fix Ambulance and Surgery Center",
  },
  { key: "12", name: "Rite Care" },
  { key: "13", name: "Take Care Practices" },
  { key: "14", name: "Test Orthopedics" },
  { key: "15", name: "Test Primary Care Practice" },
  { key: "16", name: "TP Sepsis" },
  { key: "17", name: "Unknown" },
  { key: "18", name: "Wonder Cure" },
];

export const programList = [
  { key: "1", name: "ABC Athletics" },
  { key: "2", name: "ABC Employees" },
  { key: "3", name: "ABC Students" },
  { key: "4", name: "ABC Visitors" },
  { key: "5", name: "BPCI Advances" },
  { key: "6", name: "Commnercial Program" },
  { key: "7", name: "Covid Programs" },
  { key: "8", name: "Fusion Programs" },
  { key: "9", name: "LE for BCBC" },
  { key: "10", name: "LimitedMedEpisodesTest" },
  {
    key: "11",
    name: "MY3 BPCI Advanced",
  },
  { key: "12", name: "New Program" },
  { key: "13", name: "New program February BPCI" },
  { key: "14", name: "Timezone test" },
  { key: "15", name: "UHC-Chronic care" },
  { key: "16", name: "UHC-COVID" },
];

export const episodeList = [
  { key: "1", name: "2773 Test" },
  { key: "2", name: "5456" },
  { key: "3", name: "ABA Test" },
  { key: "4", name: "Acute Myocardial Infarction" },
  { key: "5", name: "Back and neck except spinal fusion" },
  { key: "6", name: "Bariatric Surgery" },
  {
    key: "7",
    name: "Chronic obstructive pulmonary disease, brinchitis/asthma",
  },
  { key: "8", name: "Congestive heart failure" },
  { key: "9", name: "CRA test" },

  { key: "10", name: "Gastrointestinal obstruction" },
  {
    key: "11",
    name: "Hstest Ortho",
  },
  { key: "12", name: "January 2021" },
  {
    key: "13",
    name: "Major joint replacement of upper extreity",
  },
  { key: "14", name: "MJRLE - Multi-setting" },
  { key: "15", name: "Multidomain new episode" },
  { key: "16", name: "New Episode 1" },
  { key: "17", name: "OP Cardiac defibrillator" },
  { key: "18", name: "Ortho Trauma" },
  { key: "19", name: "Renal failure" },
  { key: "20", name: "Sepsis" },
  { key: "21", name: "TestTAVR" },
  { key: "21", name: "Trial Vaccine" },
  { key: "21", name: "Urinary tract infection" },
];

export const surgeonList = [
  { key: "1", name: "Atkins Care" },
  { key: "2", name: "Baker, Steve" },
  { key: "3", name: "Boris Vasilievich Spassky, PhD" },
  { key: "4", name: "CIN Physician_Sepsis" },
  { key: "5", name: "Donna Fall" },
  { key: "6", name: "Harry Potter M.D" },
  { key: "7", name: "Ken Starr (non preferred), MD" },
  { key: "8", name: "Lobovits, Alan, M.D." },
  { key: "9", name: "Mass General at Watham" },
  { key: "10", name: "Non preferred provider" },
  { key: "11", name: "Practical Providers" },
  { key: "12", name: "Preferred provider" },
  { key: "13", name: "Sam Edwards, M.D." },
  { key: "14", name: "Test" },
  { key: "15", name: "Test 234" },
  { key: "16", name: "Test PCP, MD" },
  { key: "17", name: "Test Surgeon, MD" },
  { key: "18", name: "Unknown" },
  { key: "19", name: "Urgent Care Practices" },
];

export const acuteCareFacilityList = [
  { key: "1", name: "Aaron O'Neal FRCS" },
  { key: "2", name: "Acton Gastronenterology Associates" },
  { key: "3", name: "Atkins Homes" },
  { key: "4", name: "Azalea Clinics" },
  { key: "5", name: "BMC Care" },
  { key: "6", name: "Care" },
  { key: "7", name: "Care Dimension" },
  { key: "8", name: "Concord Acute Care Hospital" },
  { key: "9", name: "Creative Healthcare2" },
  { key: "10", name: "Emersor Hospital at Sudbury" },
  {
    key: "11",
    name: "Gentle Care Rehabilitation Center",
  },
  { key: "12", name: "Good Samaritan Medical Center" },
  { key: "13", name: "Home" },
  { key: "14", name: "Hospitalist" },
  { key: "15", name: "Orthopedics New England" },
  { key: "16", name: "Palliative care facility" },
  { key: "17", name: "Reliance Medical Facility" },
  { key: "18", name: "Senior Long Term Adult Care" },
  { key: "18", name: "Senior Daytime Home Away From Home" },
  { key: "19", name: "Sharon Gates FRCP" },
  { key: "20", name: "SO Clinics" },
  { key: "21", name: "Suburban 2" },
  { key: "22", name: "Sunrise Rehabilitation Center" },
  { key: "23", name: "Test Acute Care Facility" },
  { key: "24", name: "Test Skilled Nursing Facility" },
  { key: "25", name: "Unknown Facility" },
];

export const patientEpisodeStatusList = [
  // { key: "1", name: "Canceled" },
  // { key: "2", name: "Closed" },
  // { key: "3", name: "Deceased" },
  // { key: "4", name: "Discharged" },
  // { key: "5", name: "Excluded" },
  { key: "6", name: "New" },
  // { key: "7", name: "On-Hold" },
  // { key: "8", name: "Processing" },
];

export const PAGE_LIMIT = 30;

export const PENDING = "Pending";
export const APPROVED = "Approved";

export const ZIP_CODE_LENGTH = 5;
