import React from "react";
import Icon from "../../../components/Icon";
import { StatusTimelineProps } from "./props";
import "./styles.scss";

const StatusTimeline = ({
  tabList,
  currentTabId,
  onTabChange,
  intakeId,
}: StatusTimelineProps) => {
  return (
    <div className="active-tab-with-top-line">
      {tabList.map((tab, index) => (
        <div
          key={"key_" + index}
          className={`single-tab ${currentTabId === tab.id ? "active" : ""}`}
        >
          <div className="single-tab-section">
            <div
              className={`top-line ${
                currentTabId === tab.id ? "top-line-active" : ""
              }`}
            />
            <label
              className={`container ${
                currentTabId === tab.id ? "active" : ""
              } ${intakeId ? "pointer" : "non-pointer"}`}
              onClick={(e: any) => {
                e.stopPropagation();
                onTabChange(tab.id);
              }}
            >
              {currentTabId > tab.id ? (
                <Icon
                  icon="tick-in-black-background"
                  className="tick-in-black-background"
                  size={16}
                />
              ) : (
                <input
                  type="radio"
                  checked={currentTabId === tab.id}
                  name={tab.name}
                  value={tab.id}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    onTabChange(tab.id);
                  }}
                  onChange={(e: any) => {
                    e.stopPropagation();
                  }}
                  className={`${intakeId ? "pointer" : "non-pointer"}`}
                />
              )}
              {tab.name}
            </label>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StatusTimeline;
