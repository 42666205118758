import {
  hasSpecialCharacter,
  validateMBI,
  validateNumber,
} from "shared/methods/utilityFunctions";

export const isFirstNameValid = (value: string) =>
  !!value && value.length <= 20;

export const isMiddleNameValid = (value: string) => value.length <= 20;

export const isLastNameValid = (value: string) => !!value && value.length <= 50;

export const isMbiValid = (value: string) =>
  !!value &&
  value.length === 11 &&
  validateMBI(value) &&
  !hasSpecialCharacter(value);

export const isEmergencyContactNameValid = (value: string) =>
  value.length <= 40;

export const isEmergencyContactNumberValid = (value: string) =>
  !!value && value.length === 10 && validateNumber(value);

export const isPhoneNumberValid = (value: string) =>
  !!value && value.length === 10 && validateNumber(value);

export const isMrnValid = (value: string) => value.length <= 50;

export const isDobValid = (value: Date | string) => {
  let isValid = false;
  if (!value) {
    return isValid;
  }
  let year = new Date(value).getFullYear();
  let age = Math.abs(
    new Date(Date.now() - new Date(value).getTime()).getUTCFullYear() - 1970
  );
  isValid = year >= 1900 && age >= 18;
  return isValid;
};
