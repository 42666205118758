import { useSelector } from "react-redux";

import CustomDropDown from "components/CustomDropdown";
import TextField from "components/TextField";
import {
  hasSpecialCharacter,
  isEmptyString,
  validateEmail,
} from "shared/methods/utilityFunctions";
import "./styles.scss";
import SingleDatePicker from "components/SingleDatePicker";
import PhoneInput from "components/PhoneInput";
import Icon from "components/Icon";
import { genderDropdownItems, prefferedPhoneType } from "./constants";
import {
  isDobValid,
  isEmergencyContactNameValid,
  isEmergencyContactNumberValid,
  isFirstNameValid,
  isLastNameValid,
  isMbiValid,
  isMiddleNameValid,
  isMrnValid,
  isPhoneNumberValid,
} from "./validations";
import { getAddEditEpisodeMetadata } from "state/features/add-edit-episode/add-edit-episode.slice";
import { IPatientPersonalDetailsFormType } from "state/types/add-edit-episode-slice.type";

type PatientPersonalDetailsProps = {
  form: IPatientPersonalDetailsFormType;
  setForm: (va: IPatientPersonalDetailsFormType) => void;
};

const PatientPersonalDetails = ({
  form,
  setForm,
}: PatientPersonalDetailsProps) => {
  const metadata = useSelector(getAddEditEpisodeMetadata);

  const {
    firstName,
    middleName,
    lastName,
    suffix,
    email,
    gender,
    dob,
    emegencyContactName,
    emegencyContactNumber,
    cell,
    home,
    preferredPhone,
    mbi,
    mbiEffectiveDate,
    mrn,
  } = form;

  const handleFirstNameChange = (value: string) => {
    setForm({
      ...form,
      firstName: {
        ...firstName,
        value,
        isValid: isFirstNameValid(value),
      },
    });
  };

  const handleMiddleNameChange = (value: string) => {
    setForm({
      ...form,
      middleName: {
        ...middleName,
        value,
        isValid: isMiddleNameValid(value),
      },
    });
  };

  const handleLastNameChange = (value: string) => {
    setForm({
      ...form,
      lastName: {
        ...lastName,
        value,
        isValid: isLastNameValid(value),
      },
    });
  };

  const handleSuffixChange = (value: string) => {
    setForm({
      ...form,
      suffix: { ...suffix, value, isValid: true },
    });
  };

  const handleEmailChange = (value: string) => {
    setForm({
      ...form,
      email: {
        ...email,
        value,
        isValid: isEmptyString(value) ? true : validateEmail(value),
      },
    });
  };

  const handleDobChange = (value: string) => {
    setForm({
      ...form,
      dob: {
        ...dob,
        value,
        isValid: isDobValid(value),
      },
    });
  };

  const handleEmergencyContactNameChange = (value: string) => {
    setForm({
      ...form,
      emegencyContactName: {
        ...emegencyContactName,
        value,
        isValid: isEmergencyContactNameValid(value),
      },
    });
  };

  const handleEmergencyContactNumberChange = (value: string) => {
    setForm({
      ...form,
      emegencyContactNumber: {
        ...emegencyContactNumber,
        value: { code: "+1", number: value },
        isValid: isEmptyString(value) ? true : isPhoneNumberValid(value),
      },
    });
  };

  const handleHomePhoneNumberChange = (value: string) => {
    let isValid = false;
    if (preferredPhone.value.name === prefferedPhoneType.HOME.text) {
      isValid = isPhoneNumberValid(value);
    } else {
      isValid = isEmptyString(value) ? true : isPhoneNumberValid(value);
    }
    setForm({
      ...form,
      home: {
        ...home,
        value: { code: "+1", number: value },
        isValid,
      },
    });
  };

  const handleMobilePhoneNumberChange = (value: string) => {
    let isValid = false;
    if (preferredPhone.value.name === prefferedPhoneType.CELL.text) {
      isValid = isPhoneNumberValid(value);
    } else {
      isValid = isEmptyString(value) ? true : isPhoneNumberValid(value);
    }
    setForm({
      ...form,
      cell: {
        ...cell,
        value: { code: "+1", number: value },
        isValid,
      },
    });
  };

  const handleMbiChange = (value: string) => {
    setForm({
      ...form,
      mbi: {
        ...mbi,
        value,
        isValid: isMbiValid(value),
      },
    });
  };

  const handleMbiEffectiveDateChange = (value: string) => {
    setForm({
      ...form,
      mbiEffectiveDate: {
        ...mbiEffectiveDate,
        value,
        isValid: true,
      },
    });
  };

  const handleMrnChange = (value: string) => {
    setForm({
      ...form,
      mrn: {
        ...mrn,
        value,
        isValid: isEmptyString(value) ? true : isMrnValid(value),
      },
    });
  };

  const handlePreferredPhoneNumberChange = (value: string) => {
    const preferredPhoneNumberId = metadata.preferredPhones.find(
      (phone: any) => phone.ItemLongName === value
    ).ID;
    setForm({
      ...form,
      preferredPhone: {
        ...preferredPhone,
        value: { id: preferredPhoneNumberId.toString(), name: value },
        isValid: true,
      },
      home: {
        ...home,
        isValid: true,
      },
      cell: {
        ...cell,
        isValid: true,
      },
    });
  };

  const handleGenderChange = (val: any) => {
    setForm({
      ...form,
      gender: { ...gender, value: { name: val.name, id: val.id } },
    });
  };

  const getMBIErrorMessage = () => {
    if (hasSpecialCharacter(mbi.value)) {
      return "Special characters are not allowed";
    }
    if (!mbi.isValid) {
      return "Enter MBI exactly as it appears on the Medicare Card";
    }
  };

  const getDobErrorMessage = () => {
    if (!!dob.value) {
      const year = new Date(dob.value).getFullYear();
      return year < 1900
        ? "DOB year must be 1900 or greater"
        : "Patient's age must be 18 or greater";
    }
    return "DOB is required";
  };

  const getFirstNameErrorMessage = () => {
    if (!firstName.value) {
      return "Patient First Name is required";
    }
    return "Length should be less than 20 characters.";
  };

  const getLastNameErrorMessage = () => {
    if (!lastName.value) {
      return "Patient Last Name is required";
    }
    return "Length should be less than 50 characters.";
  };

  return (
    <div className="patient-personal-details-section">
      <div className="alert-container">
        <div className="info-block">
          <Icon icon="info" size={18} />
          <div className="info-line">
            Please enter Name exactly as it appears on the Medicare Card
          </div>
        </div>
      </div>

      <div className="input-row-container">
        <div className="input-container">
          <div className="input-wrapper">
            <TextField
              label="Patient First Name"
              type="text"
              value={firstName.value}
              setValue={(label, value) => handleFirstNameChange(value)}
              inputClassName="cpt-name-input"
              placeholder=""
              errorMessage={getFirstNameErrorMessage()}
              showError={!firstName.isDisabled && !firstName.isValid}
              isRequired={true}
              id={firstName.id}
              disabled={firstName.isDisabled}
            />
          </div>
        </div>
        <div className="input-container">
          <div className="input-wrapper">
            <TextField
              label="Patient Middle Name"
              type="text"
              value={middleName.value}
              setValue={(label, value) => handleMiddleNameChange(value)}
              inputClassName="cpt-name-input"
              placeholder=""
              errorMessage="Length should be less than 20 characters."
              showError={!middleName.isDisabled && !middleName.isValid}
              disabled={middleName.isDisabled}
            />
          </div>
        </div>

        <div className="input-container">
          <div className="input-wrapper">
            <TextField
              label="Patient Last Name"
              type="text"
              value={lastName.value}
              setValue={(label, value) => handleLastNameChange(value)}
              inputClassName="cpt-name-input"
              placeholder=""
              errorMessage={getLastNameErrorMessage()}
              showError={!lastName.isDisabled && !lastName.isValid}
              isRequired={true}
              id={lastName.id}
              disabled={lastName.isDisabled}
            />
          </div>
        </div>
      </div>
      <div className="input-row-container">
        <div className="input-container">
          <div className="input-wrapper">
            <TextField
              label="Suffix"
              type="text"
              value={suffix.value}
              setValue={(label, value) => handleSuffixChange(value)}
              inputClassName="cpt-name-input"
              placeholder=""
              disabled={suffix.isDisabled}
            />
          </div>
        </div>

        <div className="input-container">
          <div className="input-wrapper">
            <TextField
              label="Email"
              className="cpt-name-input"
              type="email"
              value={email.value}
              setValue={(label, value) => handleEmailChange(value)}
              errorMessage="Incorrect email address."
              showError={!email.isDisabled && !email.isValid}
              disabled={email.isDisabled}
            />
          </div>
        </div>

        <div className="input-container">
          <div className="input-wrapper">
            <div className="dropdown-container">
              <div className="label">
                Gender
                <span className="red-color-asterisk">*</span>
              </div>
              <CustomDropDown
                placeholder="Male"
                dropDownMenuItems={genderDropdownItems}
                handleValueChanges={handleGenderChange}
                defaultValue={gender.value}
                idFieldName="id"
                dropDownBoxClass="gender-dropdown"
                selectionClass="gender-selection"
                dropDownContainerClass="gender-dropdown-container"
                isDisabled={gender.isDisabled}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="input-row-container">
        <div className="input-container" id={dob.id}>
          <div className="input-wrapper">
            <div className="label">
              Date of Birth
              <span className="red-color-asterisk">*</span>
            </div>
            <SingleDatePicker
              defaultDate={dob.value}
              minDate={null}
              maxDate={new Date()}
              required
              isError={!dob.isDisabled && !dob.isValid}
              isDisabled={dob.isDisabled}
              handleDateChange={(val: any) => handleDobChange(val)}
              errorMessage={getDobErrorMessage()}
              popperPlacement="bottom"
            />
          </div>
        </div>

        <div className="input-container">
          <div className="input-wrapper">
            <TextField
              label="Emergency Contact Name"
              className="emergency-contact-name"
              type="text"
              value={emegencyContactName.value}
              setValue={(label, value) =>
                handleEmergencyContactNameChange(value)
              }
              errorMessage="Length should be less than 40 characters."
              showError={
                !emegencyContactName.isDisabled && !emegencyContactName.isValid
              }
              disabled={emegencyContactName.isDisabled}
            />
          </div>
        </div>

        <div className="input-container">
          <div className="input-wrapper">
            <div className="phone-number-container emergency-contact-number">
              <div className="label">Emergency Contact Number</div>
              <div className="phone-number-inputs">
                <input
                  type="text"
                  placeholder="+1"
                  className="phone-number-code"
                  readOnly
                />
                <PhoneInput
                  type="tel"
                  name="emergency-contact"
                  className="phone-number-input"
                  value={emegencyContactNumber.value.number}
                  onChange={(val) => {
                    handleEmergencyContactNumberChange(val);
                  }}
                  errorMessage="Please enter a valid phone number."
                  showError={
                    !emegencyContactNumber.isDisabled &&
                    !emegencyContactNumber.isValid
                  }
                  disabled={emegencyContactNumber.isDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="input-row-container">
        <div className="input-container">
          <div className="input-wrapper">
            <div className="preffered-number-container" id={cell.id}>
              <div className="label">
                Mobile Phone
                {preferredPhone.value.name === prefferedPhoneType.CELL.text && (
                  <span className="red-color-asterisk">*</span>
                )}
              </div>
              <div className="phone-number-inputs">
                <input
                  type="text"
                  placeholder="+1"
                  className="phone-number-code"
                  readOnly
                />
                <PhoneInput
                  type="tel"
                  name="mobile-phone"
                  className="phone-number-input"
                  value={cell.value.number}
                  onChange={(val) => handleMobilePhoneNumberChange(val)}
                  errorMessage="Please enter a valid phone number."
                  showError={!cell.isDisabled && !cell.isValid}
                  disabled={cell.isDisabled}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="input-container">
          <div className="input-wrapper">
            <div className="preffered-number-container">
              <div className="label">
                Home Phone
                {preferredPhone.value.name === prefferedPhoneType.HOME.text && (
                  <span className="red-color-asterisk">*</span>
                )}
              </div>
              <div className="phone-number-inputs">
                <input
                  type="text"
                  placeholder="+1"
                  className="phone-number-code"
                  readOnly
                />
                <PhoneInput
                  type="tel"
                  name="home-phone"
                  className="phone-number-input"
                  value={home.value.number}
                  onChange={(val) => handleHomePhoneNumberChange(val)}
                  errorMessage="Please enter a valid phone number."
                  showError={!home.isDisabled && !home.isValid}
                  disabled={home.isDisabled}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="input-container">
          <div className="input-wrapper">
            <div className="label">
              Preffered Calling Number
              <span className="red-color-asterisk">*</span>
            </div>
            <div className="prefferd-calling-radio-inputs">
              <label htmlFor="mobile" className="preferred-calling-number">
                <input
                  id="mobile"
                  type="radio"
                  name="preferred_calling_number"
                  checked={
                    preferredPhone.value.name === prefferedPhoneType.CELL.text
                  }
                  onChange={(e) =>
                    handlePreferredPhoneNumberChange(e.target.value)
                  }
                  value={prefferedPhoneType.CELL.text}
                  disabled={preferredPhone.isDisabled}
                />
                Mobile
              </label>
              <label htmlFor="home" className="preferred-calling-number">
                <input
                  id="home"
                  type="radio"
                  name="preferred_calling_number"
                  checked={
                    preferredPhone.value.name === prefferedPhoneType.HOME.text
                  }
                  onChange={(e) =>
                    handlePreferredPhoneNumberChange(e.target.value)
                  }
                  value={prefferedPhoneType.HOME.text}
                  disabled={preferredPhone.isDisabled}
                />
                Phone
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="alert-container">
        <div className="info-block">
          <Icon icon="info" size={18} />
          <div className="info-line">
            Please enter MBI exactly as it appears on the Medicare Card
          </div>
        </div>
      </div>

      <div className="input-row-container">
        <div className="input-container" id={mbi.id}>
          <div className="input-wrapper">
            <TextField
              label="MBI"
              type="text"
              value={mbi.value}
              setValue={(label, value) => handleMbiChange(value)}
              inputClassName="mbi-input"
              placeholder=""
              errorMessage={getMBIErrorMessage()}
              showError={!mbi.isDisabled && !mbi.isValid}
              isRequired
              disabled={mbi.isDisabled}
            />
          </div>
        </div>

        <div className="input-container">
          <div className="input-wrapper">
            <div className="label">MBI Effective Date</div>
            <SingleDatePicker
              defaultDate={mbiEffectiveDate.value}
              required={false}
              minDate={null}
              maxDate={null}
              isError={false}
              isDisabled={mbiEffectiveDate.isDisabled}
              handleDateChange={(val: any) => handleMbiEffectiveDateChange(val)}
              errorMessage={"Please select the date."}
              popperPlacement="bottom"
            />
          </div>
        </div>

        <div className="input-container">
          <div className="input-wrapper">
            <TextField
              label="Medical Record Number (MRN)"
              type="text"
              value={mrn.value}
              setValue={(label, value) => handleMrnChange(value)}
              inputClassName="mrn-name-input"
              placeholder="XXXXXXXX"
              errorMessage="Length should be less than 50 characters."
              showError={!mrn.isDisabled && !mrn.isValid}
              disabled={mrn.isDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientPersonalDetails;
