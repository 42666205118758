import { useEffect, useRef, useState } from "react";
import Icon from "components/Icon";
import { MessageLogProps } from "components/Table/props";
import { useDispatch, useSelector } from "react-redux";
import { getCommon } from "state/features/common/common.slice";
import { CustomToolTip } from "components/tool-tip/tool-tip.component";
import { getAuth } from "state/features/authentication/authentication.slice";
import { Conversation, Message as messageTwilio } from "@twilio/conversations";
import SentIcon from "shared/assets/images/sentIcon.svg";
import ReadIcon from "shared/assets/images/readIcon.svg";
import receivedIcon from "shared/assets/images/received_msg.svg";
import moment from "moment";
import "./messages-table.styles.scss";
import {
  getMessages,
  setCurrentChat,
  setOpenMessagingParticipantsPopup,
} from "state/features/messages/messages.slice";
import { GENERAL } from "shared/constant/constants";
import { ICurrentChat } from "state/types/messages-slice.type";
import { isNotificationStale } from "shared/methods/utilityFunctions";
export interface MessageLogAllProps extends MessageLogProps {
  index: number;
}

const MessageLogRow = ({
  item,
  searchText,
  isReplyButtonDisabled,
  getAllMessages,
  handleOpenMessage,
}: {
  item: MessageLogProps;
  searchText?: string;
  isReplyButtonDisabled?: boolean;
  getAllMessages: () => void;
  handleOpenMessage: (current: ICurrentChat) => void;
}) => {
  const dispatch = useDispatch();
  const { currentChat } = useSelector(getMessages);
  const authState = useSelector(getAuth);
  const commonState = useSelector(getCommon);
  const { twilioClientRef } = commonState.globalValues;
  const CONVERSATIONREF = useRef<Conversation>();
  const unsubscribePartcipantUpdate = useRef<Conversation>();
  const [isRowHovered, setIsRowHovered] = useState(false);
  const [isRead, setisRead] = useState(false);

  const checkReadStatus = async (conversationBySid: any) => {
    CONVERSATIONREF.current = conversationBySid;
    const participants = conversationBySid.getParticipants();
    const count = (await conversationBySid.getMessagesCount()) - 1;
    participants.then(async function (currentParticipants: any) {
      const filteredList = currentParticipants.filter(
        (item: any) => item.identity !== authState.user.email
      );
      if (
        filteredList.every((item: any) => item.lastReadMessageIndex !== null)
      ) {
        setisRead(
          count ===
            Math.min(
              ...filteredList.map((item: any) => item.lastReadMessageIndex)
            )
        );
      } else setisRead(false);
    });
  };

  const getUnreadMessages = async () => {
    if (item.twilioConversationId && twilioClientRef) {
      twilioClientRef
        .getConversationBySid(item.twilioConversationId)
        .then(async (conversationBySid: Conversation) => {
          checkReadStatus(conversationBySid);
          unsubscribePartcipantUpdate.current = conversationBySid.on(
            "participantUpdated",
            async () => {
              checkReadStatus(conversationBySid);
            }
          );
        })
        .catch((err: any) => {
          console.log("configuration error: ", err);
        });
    }
  };

  useEffect(() => {
    getUnreadMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, unsubscribePartcipantUpdate]);

  useEffect(() => {
    if (item.twilioConversationId && twilioClientRef) {
      twilioClientRef
        .getConversationBySid(item.twilioConversationId)
        .then(async (conversationBySid: Conversation) => {
          conversationBySid.on(
            "messageAdded",
            async (message1: messageTwilio) => {
              getAllMessages();
            }
          );
        })
        .catch((err: any) => {
          console.log("configuration error: ", err);
        });
    }
  }, []);

  const getMessageStatus = () => {
    if (item.lastMessageSenderId !== authState.user.id) {
      return <img className="messageSentIcon" src={receivedIcon} alt="" />;
    } else if (isRead) {
      return <img className="messageSentIcon" src={ReadIcon} alt="" />;
    } else {
      return <img className="messageSentIcon" src={SentIcon} alt="" />;
    }
  };

  const showParticipantDetails = function () {
    dispatch(setCurrentChat(item));
    dispatch(setOpenMessagingParticipantsPopup(true));
  };

  const getClassName = () => {
    let className = "common-table-listing-row";

    if (isRowHovered) {
      className += " hovered";
    }

    if (
      item.isLastNotificationRead != null &&
      !item.isLastNotificationRead &&
      !isNotificationStale(
        item.conversationId,
        commonState.isChatScreenVisible.isOpen,
        currentChat.conversationId
      )
    ) {
      className += " unread-message";
    }
    return className;
  };

  return (
    <div
      onMouseEnter={() => setIsRowHovered(true)}
      onMouseLeave={() => setIsRowHovered(false)}
      className={getClassName()}
      onClick={() => {
        handleOpenMessage({
          conversationId: item.conversationId,
          twilioConversationId: item.twilioConversationId,
          chatTitle: item.chatTitle,
          participants: item.participants,
          name: item.chatTitle,
          subject: item.subject ?? "-",
          patientDOB: item.patientDOB,
        } as ICurrentChat);
      }}
    >
      <div className="column">
        <div className="content">{item.chatTitle}</div>
      </div>
      <div className="column">
        <div className="content">{item.subject}</div>
        {isRowHovered &&
          item?.subject?.toLowerCase() !== GENERAL.toLowerCase() && (
            <div
              className="subject-icon"
              data-tip
              data-for="Patient Details"
              onClick={(e) => {
                e.stopPropagation();
                showParticipantDetails();
              }}
            >
              <Icon
                icon="participant-info"
                size={20}
                className="participants-info"
              />
              <CustomToolTip text={"Patient Details"} place="bottom" />
            </div>
          )}
      </div>
      <div className="column">
        <div className="content">
          {item.dateTime
            ? moment(item.dateTime).format("MM-DD-YYYY, hh:mm A")
            : "-"}
        </div>
      </div>
      <div className="column">
        <div className="content">
          <div className="second-last-column">
            {item.message && getMessageStatus()}
            <div className="second-last-column-content">{item.message}</div>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="content">
          <div className={`last-column ${isRowHovered ? "visible" : "hidden"}`}>
            <div
              className={`table-icon ${
                isReplyButtonDisabled ? "table-icon-disabled" : ""
              }`}
              data-tip
              data-for="Reply"
              onClick={() => {
                handleOpenMessage({
                  conversationId: item.conversationId,
                  twilioConversationId: item.twilioConversationId,
                  chatTitle: item.chatTitle,
                  name: item.chatTitle,
                  subject: item.subject ?? "-",
                  participants: item.participants,
                } as ICurrentChat);
              }}
            >
              <CustomToolTip text="Reply" place="top" />
              <Icon icon="message-reply" size={14} className="chat-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageLogRow;
