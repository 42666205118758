import { createSlice } from "@reduxjs/toolkit";
import { TOAST_MESSAGES } from "shared/constant/constants";
import { toast } from "react-toastify";
import {
  deleteAllCookies,
  getCookie,
  setCookie,
} from "shared/methods/utilityFunctions";
import {
  loginUserAsync,
  refreshTokenAsync,
  requestGenerateTwilioTokenAsync,
  requestSendOtpAsync,
  requestUpdatePasswordAsync,
  requestVerifyOtpAsync,
} from "state/features/authentication/authentication.action";
import { AuthProps, UserProps } from "shared/types/state-types.type";
import { IAuthenticationState } from "state/types/authentication-slice.type";

function getLoggedInUserFromCookies(): UserProps {
  const userFromCookie = getCookie("loggedInUser");
  if (userFromCookie) {
    return JSON.parse(userFromCookie); // TODO: try catch
  } else {
    return {
      id: "",
      email: "",
      username: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      roles: [],
    };
  }
}

export function getTokensFromCookies(): AuthProps {
  const tokensFromCookie = getCookie("authTokens");
  if (tokensFromCookie) {
    return JSON.parse(tokensFromCookie); // TODO: try catch
  } else {
    return {
      isAuthenticated: false,
      resetPasswordCode: "",
      jwtToken: "",
      refreshToken: "",
    };
  }
}

const initialState: IAuthenticationState = {
  isLoading: false,
  isError: false,
  twilioToken: "",
  auth: getTokensFromCookies(),
  user: getLoggedInUserFromCookies(),
};
const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setIsLoading: (state: any, action) => {
      state.isLoading = action.payload;
    },
    setError: (state: any, action) => {
      state.isError = action.payload;
    },
    setAuthenticationTokens: (state: any, action) => {
      state.isLoading = false;
      state.auth = action.payload;
    },
    logout: (state: any, action) => {
      state.isLoading = false;
      state.auth = {
        isAuthenticated: false,
        jwtToken: "",
        refreshToken: "",
      };
    },
    setUsername: (state: any, action) => {
      state.isLoading = false;
      state.username = action.payload;
    },
    setEmail: (state: any, action) => {
      state.isLoading = false;
      state.email = action.payload;
    },
    setId: (state: any, action) => {
      state.isLoading = false;
      state.id = action.payload;
    },
    clearUser: (state: any) => {
      state.user = {
        id: "",
        email: "",
        username: "",
        firstName: "",
        lastName: "",
        roles: [],
      };
      state.auth = {
        isAuthenticated: false,
        resetPasswordCode: "",
        jwtToken: "",
        refreshToken: "",
      };
      state.twilioToken = null;
      deleteAllCookies();
      sessionStorage.clear();
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(loginUserAsync.pending, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
      }),
      builder.addCase(loginUserAsync.fulfilled, (state: any, action: any) => {
        state.isLoading = false;
        state.isError = false;
        if (action.payload.succeeded) {
          state.user.username = action.meta.arg.username;
          state.user.email = action.payload.email;
          state.user.firstName = action.payload.firstName;
          state.user.lastName = action.payload.lastName;
          state.user.id = action.payload.id;
          state.auth = {
            isAuthenticated: true,
            jwtToken: action.payload.jwtToken,
            refreshToken: action.payload.refreshToken,
          };
          setCookie("loggedInUser", JSON.stringify(state.user));
          setCookie("authTokens", JSON.stringify(state.auth));
        } else if (action.payload.isLockedOut) {
          toast.error(TOAST_MESSAGES.USER_LOCKED_OUT, {
            toastId: "login-error",
          });
        } else {
          toast.error(TOAST_MESSAGES.USER_ERROR_MESSAGE, {
            toastId: "login-error",
          });
        }
      }),
      builder.addCase(loginUserAsync.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.USER_ERROR_MESSAGE, {
          toastId: "login-error",
        });
      }),
      builder.addCase(
        refreshTokenAsync.fulfilled,
        (state: any, action: any) => {
          state.user.username = action.meta.arg.username;
          state.user.email = action.payload.email;
          state.user.firstName = action.payload.firstName;
          state.user.lastName = action.payload.lastName;
          state.user.id = action.payload.id;
          state.auth = {
            isAuthenticated: true,
            jwtToken: action.payload.jwtToken,
            refreshToken: action.payload.refreshToken,
          };
          setCookie("loggedInUser", JSON.stringify(state.user));
          setCookie("authTokens", JSON.stringify(state.auth));
        }
      ),
      builder.addCase(refreshTokenAsync.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.SESSION_EXPIRED, {
          toastId: "session-error",
        });
      }),
      builder.addCase(requestSendOtpAsync.pending, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
      }),
      builder.addCase(
        requestSendOtpAsync.fulfilled,
        (state: any, action: any) => {
          state.isLoading = false;
          state.isError = false;
          state.user.username = action.meta.arg;
          state.user.phoneNumber = action.payload.phoneNUmber;
          state.auth.resetPasswordCode = action.payload.code;
        }
      ),
      builder.addCase(
        requestSendOtpAsync.rejected,
        (state: any, action: any) => {
          state.isLoading = false;
          state.isError = true;
          if (action.payload) {
            toast.error(action.payload, { toastId: "otp-error" });
          } else {
            toast.error(TOAST_MESSAGES.ERROR, { toastId: "otp-error" });
          }
        }
      ),
      builder.addCase(requestVerifyOtpAsync.pending, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
      }),
      builder.addCase(requestVerifyOtpAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.isError = false;
      }),
      builder.addCase(
        requestVerifyOtpAsync.rejected,
        (state: any, action: any) => {
          state.isLoading = false;
          state.isError = true;
          if (action.payload) {
            toast.error(action.payload, { toastId: "otp-verification-error" });
          } else {
            toast.error(TOAST_MESSAGES.ERROR, {
              toastId: "otp-verification-error",
            });
          }
        }
      ),
      builder.addCase(
        requestUpdatePasswordAsync.pending,
        (state: any, action) => {
          state.isLoading = true;
          state.isError = false;
        }
      ),
      builder.addCase(
        requestUpdatePasswordAsync.fulfilled,
        (state: any, action: any) => {
          state.isLoading = false;
          state.isError = false;
          if (action.payload.isValid) {
            state.auth.resetPasswordCode = "";
          }
        }
      ),
      builder.addCase(
        requestUpdatePasswordAsync.rejected,
        (state: any, action) => {
          state.isLoading = false;
          state.isError = true;
        }
      ),
      builder.addCase(
        requestGenerateTwilioTokenAsync.fulfilled,
        (state: any, action: any) => {
          state.twilioToken = action.payload.accessToken;
          setCookie("twilioToken", action.payload.accessToken);
          state.isLoading = false;
          state.isError = true;
        }
      ),
      builder.addCase(
        requestGenerateTwilioTokenAsync.rejected,
        (state: any, action) => {
          state.isLoading = false;
          state.isError = true;
        }
      )
    );
  },
});

export default authSlice.reducer;
export const {
  setAuthenticationTokens,
  setIsLoading,
  setError,
  setUsername,
  setEmail,
  setId,
  clearUser,
} = authSlice.actions;
export const getAuth = (state: any): IAuthenticationState => state.auth;
