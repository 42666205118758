import React, { useMemo, useState } from "react";
import "./message.styles.scss";
import PDFIcon from "shared/assets/images/pdf-icon.svg";
import WordIcon from "shared/assets/images/document-icon.svg";
import Icon from "components/Icon";
import RetryIcon from "shared/assets/images/retry.svg";
import ProgressBar from "../loader/progress-bar.component";
import SentIcon from "shared/assets/images/sentIcon.svg";
import ReadIcon from "shared/assets/images/readIcon.svg";
import SendingIcon from "shared/assets/images/sendingIcon.svg";
import {
  isFileDocument,
  isFileTypeImage,
} from "shared/methods/utilityFunctions";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import ConversationLoading from "../loader/chat-loading.component";

type Props = {
  text: string;
  time: string;
  isColored: boolean;
  type: string;
  fileName?: string;
  id?: string | number;
  name?: string;
  fileSize?: string;
  progress?: number;
  isProgressVisible?: boolean;
  cancelUpload: (controller: AbortController) => void;
  messageFile: File | null;
  isCancelled?: boolean;
  sendMessage?: (
    fileUploaded: File | null,
    fileType?: string,
    retry?: boolean,
    id?: string | number
  ) => void;
  readCount: number;
  unreadCount: number;
  index: number;
  isGroupChat: boolean;
};

const Message = ({
  text,
  time,
  isColored,
  type,
  fileName,
  id,
  name,
  fileSize,
  progress,
  isProgressVisible,
  cancelUpload,
  messageFile,
  sendMessage,
  readCount,
  unreadCount,
  index,
  isCancelled,
  isGroupChat,
}: Props) => {
  const [cancelled, setIsCancelled] = useState(isCancelled);
  const controller = useMemo(() => new AbortController(), []);
  const [isSendTickDisplay, setisSendTickDisplay] = useState(true);
  const typeOfTheFile = type.toLowerCase();

  const getMessageStatus = () => {
    if (!isSendTickDisplay)
      return <img className="messageSentIcon" src={SendingIcon} />;
    else if (isSendTickDisplay && readCount && unreadCount <= index)
      return <img className="messageSentIcon" src={ReadIcon} />;
    else if (isSendTickDisplay)
      return <img className="messageSentIcon" src={SentIcon} />;
  };

  const getContentBasedOnType = () => {
    if (isFileTypeImage(typeOfTheFile)) {
      return { src: text, className: "image" };
    } else if (typeOfTheFile === "application/pdf" || typeOfTheFile === "pdf") {
      return { src: PDFIcon, className: "pdf-icon" };
    } else if (
      typeOfTheFile === "application/msword" ||
      typeOfTheFile ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      typeOfTheFile === "word"
    ) {
      return { src: WordIcon, className: "pdf-icon" };
    }
  };

  const getFileType = () => {
    const fileType = messageFile?.type.split("/")[0].toUpperCase().trim();
    if (!messageFile) {
      return "TEXT";
    } else if (fileType === "IMAGE") {
      return "IMAGE";
    } else if (messageFile.type === "application/pdf") {
      return "PDF";
    } else if (
      messageFile?.type === "application/msword" ||
      messageFile?.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return "WORD";
    }
    return "TEXT";
  };

  const retry = async (
    file: File | null,
    fileType: string,
    _id?: string | number
  ) => {
    if (typeof sendMessage === "function") {
      setIsCancelled(false);
      sendMessage(file, fileType, true, _id);
    }
  };

  function openInNewTab(url: string) {
    const anchor = document.createElement("a");
    if (
      url &&
      anchor &&
      fileName &&
      typeOfTheFile &&
      !isFileTypeImage(typeOfTheFile)
    ) {
      anchor.href = url;
      anchor.target = "_blank";
      anchor.download = fileName;
      anchor.click();
    }
  }

  function getSentBy() {
    if (!isColored && isGroupChat) {
      return <div className="message-sent-by">{`Sent By ${name}`}</div>;
    }
    return null;
  }

  const renderOverlayImageView = ({ onClose }: any) => (
    <div className="overlay-image-view">
      <span onClick={() => onClose()}>
        <Icon icon="close" size={15} className="close-icon" />
      </span>
    </div>
  );

  return (
    <>
      {getSentBy()}
      <div id={id?.toString()} className="message">
        <div
          className={`message-container ${
            !isColored ? "white" : "right-aligned"
          }`}
          onClick={() => {
            openInNewTab(text);
          }}
        >
          {isFileDocument(typeOfTheFile) && (
            <>
              <div className="media-with-progress">
                <div className="media">
                  <img
                    className={`${getContentBasedOnType()?.className}`}
                    src={getContentBasedOnType()?.src}
                    alt={fileName}
                  />
                  <div>
                    <div className="text">{fileName}</div>
                    <div className="file-size">{fileSize}</div>
                  </div>
                </div>
              </div>
            </>
          )}
          {isFileTypeImage(typeOfTheFile) && (
            <>
              <div className="image-with-progress">
                <PhotoProvider
                  maskClosable={false}
                  bannerVisible={false}
                  overlayRender={renderOverlayImageView}
                  loadingElement={<ConversationLoading />}
                >
                  <PhotoView src={getContentBasedOnType()?.src}>
                    <img
                      className={`${getContentBasedOnType()?.className}`}
                      src={getContentBasedOnType()?.src}
                      alt={fileName}
                    />
                  </PhotoView>
                </PhotoProvider>
                {isProgressVisible &&
                  progress &&
                  progress < 100 &&
                  progress > 0 && (
                    <div className="progress-bar">
                      <ProgressBar percentage={progress} />
                      {cancelled ? (
                        <div
                          className="retry-upload"
                          onClick={() =>
                            retry(messageFile, getFileType(), id?.toString())
                          }
                        >
                          <img src={RetryIcon} alt="retry" />
                        </div>
                      ) : (
                        <div
                          className="cancel-upload"
                          onClick={(e) => {
                            e.stopPropagation();
                            cancelUpload(controller);
                            setIsCancelled(true);
                          }}
                        >
                          <Icon icon="close" size={14} />
                        </div>
                      )}
                    </div>
                  )}
              </div>
            </>
          )}
          {typeOfTheFile === "text" && (
            <>
              <div className="text">{text}</div>
            </>
          )}
          <div className="timeRow">
            <div className="time">{time}</div>
            {isColored && getMessageStatus()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Message;
