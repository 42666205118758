import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  createEpisodeAsync,
  getAllEpisodesAsync,
  getAllFacilitiesAsync,
  getAllPracticesAsync,
  getAllProgramsAsync,
  getAllSurgeonsAsync,
  getEpisodeDetailsByIntakeIdAsync,
  getPreferredPhoneNumberIdAsync,
  getSurgerySiteSideAsync,
  updateEpisodeAsync,
} from "./add-edit-episode.action";
import {
  IAddEditEpisodeMetadata,
  IAddEditEpisodeState,
  IPatientPersonalDetailsFormType,
  ISurgeonEpisodeDetailsFormType,
} from "state/types/add-edit-episode-slice.type";
import {
  PATIENT_ADDRESS_DETAILS_FORM,
  PATIENT_PERSONAL_DETAILS_FORM,
  SURGEON_EPISODE_DETAILS_FORM,
} from "state/constants/add-edit-patient";
import { keys } from "pages/add-edit-episode/constants";

const intializeSurgeonAndEpisodeDetailsForm =
  (): ISurgeonEpisodeDetailsFormType => {
    const surgeonAndEpisodeDetails = localStorage.getItem(
      keys.SURGEON_AND_EPISODE_DETAILS
    );

    if (surgeonAndEpisodeDetails) {
      return JSON.parse(
        surgeonAndEpisodeDetails
      ) as ISurgeonEpisodeDetailsFormType;
    }
    return SURGEON_EPISODE_DETAILS_FORM;
  };

const intializePatientPersonalDetailsForm =
  (): IPatientPersonalDetailsFormType => {
    const patientPersonalDetails = localStorage.getItem(
      keys.PATIENT_PERSONAL_DETAILS
    );

    if (patientPersonalDetails) {
      return JSON.parse(
        patientPersonalDetails
      ) as IPatientPersonalDetailsFormType;
    }
    return PATIENT_PERSONAL_DETAILS_FORM;
  };

const initialState: IAddEditEpisodeState = {
  isLoading: false,
  isError: false,
  isPatientSaving: false,
  metadata: {
    preferredPhones: [],
    surgerySiteSides: [],
    episodes: [],
    surgeons: [],
    facilities: [],
    practices: [],
    programs: [],
  },
  existingEpisodeDetails: null,
  surgeonAndEpisodeDetailsForm: null,
  patientPersonalDetailsForm: null,
  patientAddressDetailsForm: null,
  isDirty: false,
};

const addEditEpisodeSlice = createSlice({
  name: "addEditEpisode",
  initialState,
  reducers: {
    setIsLoading: (state: any, action) => {
      state.isLoading = action.payload;
    },
    setError: (state: any, action) => {
      state.isError = action.payload;
    },
    setIsDirty: (state: any, action) => {
      state.isDirty = action.payload;
    },
    intializeFormsForAddPatient: (state: any) => {
      state.surgeonAndEpisodeDetailsForm =
        intializeSurgeonAndEpisodeDetailsForm();
      state.patientPersonalDetailsForm = intializePatientPersonalDetailsForm();
      state.patientAddressDetailsForm = PATIENT_ADDRESS_DETAILS_FORM;
    },
    setSurgeonAndEpisodeDetailsForm: (state: any, action) => {
      state.surgeonAndEpisodeDetailsForm = action.payload
        ? action.payload.formValue
        : null;
      state.isDirty = action.payload ? action.payload.isFormDirty : false;
    },
    setPatientPersonalDetailsForm: (state: any, action) => {
      state.isDirty = action.payload ? action.payload.isFormDirty : false;
      state.patientPersonalDetailsForm = action.payload
        ? action.payload.formValue
        : null;
    },
    setPatientAddressDetailsForm: (state: any, action) => {
      state.isDirty = action.payload ? action.payload.isFormDirty : false;
      state.patientAddressDetailsForm = action.payload
        ? action.payload.formValue
        : null;
    },
    clearSurgeonAndEpisodeDetailsForm: (state: any) => {
      state.surgeonAndEpisodeDetailsForm = SURGEON_EPISODE_DETAILS_FORM;
      state.isDirty = true;
      localStorage.removeItem(keys.SURGEON_AND_EPISODE_DETAILS);
    },
    clearPatientPersonalDetailsForm: (state: any) => {
      state.patientPersonalDetailsForm = PATIENT_PERSONAL_DETAILS_FORM;
      state.isDirty = true;
      localStorage.removeItem(keys.PATIENT_PERSONAL_DETAILS);
    },
    clearPatientAddressDetailsForm: (state: any) => {
      state.isDirty = true;
      state.patientAddressDetailsForm = PATIENT_ADDRESS_DETAILS_FORM;
    },
    resetExistingEpisodeDetails: (state: any) => {
      state.existingEpisodeDetails = null;
    },
    resetMetadata: (state: any) => {
      state.metadata = {
        preferredPhones: [],
        surgerySiteSides: [],
        episodes: [],
        surgeons: [],
        facilities: [],
        practices: [],
        programs: [],
      };
    },
    setEpisodeDropdownMetaData: (state, action) => {
      state.metadata.episodes = action.payload;
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(
        getPreferredPhoneNumberIdAsync.fulfilled,
        (state: any, action) => {
          state.isLoading = true;
          state.isError = false;
          state.metadata.preferredPhones = action.payload;
        }
      ),
      builder.addCase(
        getSurgerySiteSideAsync.fulfilled,
        (state: any, action) => {
          state.isLoading = true;
          state.isError = false;
          state.metadata.surgerySiteSides = action.payload.map((site: any) => {
            return {
              key: site.ID,
              name: `${site.SurgerySite} - ${site.SurgerySide}`,
            };
          });
        }
      ),
      builder.addCase(getAllEpisodesAsync.fulfilled, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
        state.metadata.episodes = action.payload.map((episode: any) => {
          return {
            key: `${episode.ID}:${episode.SurgeryCategoryID}`,
            name: episode.LongName,
          };
        });
      }),
      builder.addCase(getAllSurgeonsAsync.fulfilled, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
        state.metadata.surgeons = action.payload
          .filter((surgeon: any) => {
            return surgeon.FirstName !== null;
          })
          .map((surgeon: any) => {
            const { ID, FirstName, LastName, NPI, PracticeId } = surgeon;
            return {
              key: ID,
              name: `${FirstName ?? ""} ${LastName ?? ""}`,
              PracticeId: PracticeId ?? "",
              NPI,
            };
          });
      }),
      builder.addCase(getAllFacilitiesAsync.fulfilled, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
        state.metadata.facilities = action.payload.map((facility: any) => {
          return {
            key: facility.ID,
            name: facility.ProviderName,
          };
        });
      }),
      builder.addCase(getAllPracticesAsync.fulfilled, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
        state.metadata.practices = action.payload.map((practice: any) => {
          return {
            key: practice.ID,
            name: practice.LongName,
          };
        });
      }),
      builder.addCase(getAllProgramsAsync.fulfilled, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
        state.metadata.programs = action.payload.map((program: any) => {
          return {
            key: program.ID,
            name: program.Name,
          };
        });
      }),
      builder.addCase(createEpisodeAsync.pending, (state: any) => {
        state.isLoading = true;
        state.isPatientSaving = true;
      }),
      builder.addCase(createEpisodeAsync.fulfilled, (state: any) => {
        state.isLoading = true;
        state.isError = false;
        state.isPatientSaving = false;
        toast.success("Episode added successfully.", {
          toastId: "create-episode",
        });
      }),
      builder.addCase(
        createEpisodeAsync.rejected,
        (state: any, action: any) => {
          state.isLoading = false;
          state.isError = true;
          state.isPatientSaving = false;
          toast.error(action.payload, {
            toastId: "create-patient-error",
          });
        }
      ),
      builder.addCase(updateEpisodeAsync.pending, (state: any) => {
        state.isLoading = true;
        state.isPatientSaving = true;
      }),
      builder.addCase(updateEpisodeAsync.fulfilled, (state: any) => {
        state.isLoading = true;
        state.isError = false;
        state.isPatientSaving = false;
        toast.success("Episode updated successfully.", {
          toastId: "update-episode",
        });
      }),
      builder.addCase(
        updateEpisodeAsync.rejected,
        (state: any, action: any) => {
          state.isLoading = false;
          state.isError = true;
          state.isPatientSaving = false;
          toast.error(action.payload, {
            toastId: "create-patient-error",
          });
        }
      ),
      builder.addCase(
        getEpisodeDetailsByIntakeIdAsync.pending,
        (state: any) => {
          state.isLoading = true;
        }
      ),
      builder.addCase(
        getEpisodeDetailsByIntakeIdAsync.rejected,
        (state: any) => {
          state.isLoading = false;
          state.isError = true;
        }
      ),
      builder.addCase(
        getEpisodeDetailsByIntakeIdAsync.fulfilled,
        (state: any, action) => {
          state.isLoading = false;
          state.isError = false;
          if (action.payload) {
            state.existingEpisodeDetails = action.payload;
          }
        }
      )
    );
  },
});

export const {
  setIsLoading,
  setIsDirty,
  setSurgeonAndEpisodeDetailsForm,
  setPatientPersonalDetailsForm,
  setPatientAddressDetailsForm,
  clearSurgeonAndEpisodeDetailsForm,
  clearPatientPersonalDetailsForm,
  clearPatientAddressDetailsForm,
  intializeFormsForAddPatient,
  resetExistingEpisodeDetails,
  resetMetadata,
  setEpisodeDropdownMetaData,
} = addEditEpisodeSlice.actions;

export default addEditEpisodeSlice.reducer;

export const getAddEditEpisodeMetadata = (
  state: any
): IAddEditEpisodeMetadata => state.addEditEpisode.metadata;

export const getAddEditEpisodeState = (state: any): IAddEditEpisodeState =>
  state.addEditEpisode;
