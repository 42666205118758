export enum PatientFormTabs {
  SURGEON_AND_EPISODE_DETAILS,
  PATIENT_PERSONAL_DETAILS,
  PATIENT_ADDRESS,
}

export enum TextLabels {
  EPISODE_SETTING_GERROR = "Please select Episode Settings.",
  EPISODE_TYPE_ERROR = "Please select Episode Type.",
  EPISODE_CARE_ERROR = "Please select Episode Care.",
}
