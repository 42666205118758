import { IConfig } from "./config";

/**
 * These values can be updated as needed. These values are only
 * used during local development
 */
export const developmentConfig: IConfig = {
  overrideConfigurationUrl: false,
  messagingBase: "https://esmessagingdev.azurewebsites.net",
  authenticationBase:
    "https://devapppserviceauthenticationf5.azurewebsites.net",
  taskManagementBase: "https://estaskmanagementdev.azurewebsites.net/",
  efusionBase: "https://devmainvm2.fusion5.us",
  notificationBase: "https://esappnotificationdev.azurewebsites.net",
  subscriptionKey: "0cbf58e25f784625adc48059e44e7ef8",
  configurationUrl:
    "Endpoint=https://es-dev-ui-app-config.azconfig.io;Id=ajat-l4-s0:Mvr6tJbi5gFO5CfnTwNT;Secret=wNzfZCurW7yVhxCiAKKWtjoH9WRnLIdzOnaL2OgsEoY=",
  environment: "qa",
};
