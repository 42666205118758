import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import TOCPresentation from "./toc-presentation.component";
import "./toc.styles.scss";
import { PAGE_LIMIT, PENDING } from "shared/assets/constants/commonConstants";
import { TOCRequest } from "./types";
import { fetchTransitionOfCareItemsAsync } from "state/features/toc/toc.action";
import { setIsFilterModalVisible } from "state/features/common/common.slice";
import { setSuggestions } from "state/features/patient-episodes/patient-episodes.slice";
import {
  resetTocs,
  setIsLoading,
  setIsSearching,
} from "state/features/toc/toc.slice";
import { DischargeDateProps } from "components/Modal/TOCFilterModal/toc-filter-modal.types";
import moment from "moment";
import { SortingOrderType } from "shared/types/enum";
import { debounce } from "lodash";
import { useAppDispatch } from "state/store";
import {
  getEndDate,
  getUTCDate,
  isEmptyString,
} from "shared/methods/utilityFunctions";

export type HeaderColumnInfo = {
  key: string;
  name: string;
  sortOrder?: SortingOrderType;
};

const TOC = () => {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const abortControllerForFetchMessagesAsync = useRef<() => void>();
  const [searchText, setSearchText] = useState<string>("");
  const initialPayload = {
    limit: PAGE_LIMIT,
    offset: 0,
    dischargeStartDate: null,
    dischargeEndDate: null,
    procedureEndDateFrom: null,
    procedureEndDateTo: null,
    status: PENDING,
    patientNameFilter: "",
    sortColumn: "createddate",
    sortOrder: "asc",
  };
  const [tocPayload, setTocPayload] = useState<TOCRequest>(initialPayload);
  const pendingTocColumns = [
    {
      key: "name",
      name: "Patient Name",
      sortOrder: SortingOrderType.DEFAULT,
    },
    {
      key: "navigator",
      name: "Assigned Navigator",
      sortOrder: SortingOrderType.DEFAULT,
    },
    {
      key: "procedure",
      name: "Procedure",
      sortOrder: SortingOrderType.DEFAULT,
    },
    {
      key: "dischargeDate",
      name: "Discharge Date",
      sortOrder: SortingOrderType.DEFAULT,
    },
    {
      key: "createddate",
      name: "Creation Date Time",
      sortOrder: SortingOrderType.ASC,
    },
    {
      key: "actions",
      name: "",
    },
  ];

  const approvedTocColumns = [
    {
      key: "name",
      name: "Patient Name",
      sortOrder: SortingOrderType.DEFAULT,
    },
    {
      key: "navigator",
      name: "Assigned Navigator",
      sortOrder: SortingOrderType.DEFAULT,
    },
    {
      key: "procedure",
      name: "Procedure",
      sortOrder: SortingOrderType.DEFAULT,
    },
    {
      key: "dischargeDate",
      name: "Discharge Date",
      sortOrder: SortingOrderType.DEFAULT,
    },
    {
      key: "approvalDate",
      name: "Approval Date Time",
      sortOrder: SortingOrderType.DESC,
    },
    {
      key: "actions",
      name: "",
    },
  ];
  const [pendingHeaderColumnsInfo, setPendingHeaderColumns] =
    useState<HeaderColumnInfo[]>(pendingTocColumns);
  const [approvedHeaderColumnsInfo, setApprovedHeaderColumns] =
    useState<HeaderColumnInfo[]>(approvedTocColumns);

  const handleSearchInput = useCallback((value: string) => {
    setTocPayload((prevState) => {
      return {
        ...prevState,
        offset: 0,
        patientNameFilter: value,
      };
    });
  }, []);

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearchInput, 500);
  }, [handleSearchInput]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const onClear = () => {
    if (tocPayload.patientNameFilter) {
      setSearchText("");
      handleSearchInput("");
      dispatch(setSuggestions(""));
    }
    dispatch(setIsSearching(false));
  };

  const onClickSearchBox = () => {
    setSearchText("");
    handleSearchInput("");
    dispatch(setSuggestions(""));
    dispatch(setIsSearching(false));
  };

  const onClickSearchButton = () => {
    dispatch(setIsSearching(true));
  };

  const onClickFilterButton = () => {
    dispatch(setIsFilterModalVisible(true));
  };

  const resetTocPayload = useCallback(() => {
    setTocPayload((prev) => {
      return {
        limit: PAGE_LIMIT,
        offset: 0,
        dischargeStartDate: null,
        dischargeEndDate: null,
        procedureEndDateFrom: null,
        procedureEndDateTo: null,
        status: tocPayload.status,
        patientNameFilter: "",
        sortColumn: "createdDate",
        sortOrder: "asc",
      };
    });
  }, [tocPayload.status]);

  const onClickTabName = (tabName: string) => {
    dispatch(setIsLoading(true));
    if (tocPayload.sortColumn === "approvalDate") {
      setTocPayload((prev) => {
        return {
          ...prev,
          offset: 0,
          status: tabName,
          sortColumn: "createddate",
          sortOrder: SortingOrderType.ASC,
        };
      });
      const updatedPendingColumns = pendingHeaderColumnsInfo.map(
        (header, index) => {
          if (header.key === "createddate") {
            header.sortOrder = SortingOrderType.ASC;
            return header;
          }
          return header;
        }
      );
      setPendingHeaderColumns(updatedPendingColumns);
    } else if (tocPayload.sortColumn === "createddate") {
      setTocPayload((prev) => {
        return {
          ...prev,
          offset: 0,
          status: tabName,
          sortColumn: "approvalDate",
          sortOrder: SortingOrderType.DESC,
        };
      });
      const updatedApprovedColumns = approvedHeaderColumnsInfo.map(
        (header, index) => {
          if (header.key === "approvalDate") {
            header.sortOrder = SortingOrderType.DESC;
            return header;
          }
          return header;
        }
      );

      setApprovedHeaderColumns(updatedApprovedColumns);
    } else {
      setTocPayload((prevState) => {
        return {
          ...prevState,
          offset: 0,
          status: tabName,
        };
      });
    }
  };

  const changePageOffset = () => {
    setTocPayload((prevState) => {
      return {
        ...prevState,
        dischargeEndDate: null,
        dischargeStartDate: null,
        offset: prevState.offset + PAGE_LIMIT,
      };
    });
  };

  const onApplyFilter = (date: DischargeDateProps) => {
    setTocPayload((prevState) => ({
      ...prevState,
      dischargeEndDate: date.dischargeDateTo,
      dischargeStartDate: date.dischargeDateFrom,
      offset: 0,
    }));
    dispatch(setIsFilterModalVisible(false));
  };

  const onClearFilter = () => {
    setTocPayload((prevState) => {
      return {
        ...prevState,
        offset: 0,
        dischargeEndDate: null,
        dischargeStartDate: null,
      };
    });
  };

  const getDateText = () => {
    const { dischargeEndDate, dischargeStartDate } = tocPayload;
    const dateFrom = dischargeStartDate
      ? moment(dischargeStartDate).format("MM-DD-YYYY")
      : null;

    const dateTo = dischargeEndDate
      ? moment(dischargeEndDate).format("MM-DD-YYYY")
      : null;

    if (dateFrom && dateTo) {
      return `${dateFrom} - ${dateTo}`;
    } else if (dateFrom) {
      return dateFrom;
    } else if (dateTo) {
      return dateTo;
    }
    return "";
  };

  useEffect(() => {
    return () => {
      dispatch(resetTocs());
    };
  }, []);

  useEffect(() => {
    let payloadAction = appDispatch(
      fetchTransitionOfCareItemsAsync({
        ...tocPayload,
        dischargeEndDate: tocPayload.dischargeEndDate
          ? getEndDate(tocPayload.dischargeEndDate)
          : null,
        dischargeStartDate: tocPayload.dischargeStartDate
          ? getUTCDate(tocPayload.dischargeStartDate)
          : null,
        patientNameFilter: tocPayload.patientNameFilter.trim().toLowerCase(),
      })
    );
    abortControllerForFetchMessagesAsync.current = payloadAction.abort;
    return () => {
      if (abortControllerForFetchMessagesAsync.current) {
        abortControllerForFetchMessagesAsync.current();
      }
    };
  }, [appDispatch, tocPayload]);

  const handlePendingListSorting = (selectedColumn: HeaderColumnInfo) => {
    const index = pendingHeaderColumnsInfo.findIndex(
      (x) => x.key === selectedColumn.key
    );
    let sort: SortingOrderType = SortingOrderType.DEFAULT;
    switch (pendingHeaderColumnsInfo[index].sortOrder) {
      case SortingOrderType.DESC:
      case SortingOrderType.DEFAULT:
        sort = SortingOrderType.ASC;
        break;
      case SortingOrderType.ASC:
        sort = SortingOrderType.DESC;
        break;
    }
    const tempHeadersPending = [...pendingHeaderColumnsInfo];
    tempHeadersPending.forEach((header) => {
      if (header.name !== "") {
        header.sortOrder = SortingOrderType.DEFAULT;
      }
    });
    const tempHeaderApproved = approvedHeaderColumnsInfo.map((header, idx) => {
      if (selectedColumn.key === "createddate" && idx === 4) {
        header.sortOrder = SortingOrderType.DESC;
        return header;
      }
      if (header.key === selectedColumn.key) {
        header.sortOrder = sort;
      } else if (header.name !== "") {
        header.sortOrder = SortingOrderType.DEFAULT;
      }
      return header;
    });
    tempHeadersPending[index].sortOrder = sort;
    tempHeaderApproved[index].sortOrder = sort;
    changeSort(selectedColumn.key, sort);
    setPendingHeaderColumns(tempHeadersPending);
    setApprovedHeaderColumns(tempHeaderApproved);
  };

  const changeSort = (key: string, order: SortingOrderType) => {
    setTocPayload((prev) => {
      return {
        ...prev,
        offset: 0,
        sortColumn: key,
        sortOrder: order,
      };
    });
  };

  const handleApprovedListSorting = (selectedColumn: HeaderColumnInfo) => {
    const index = approvedHeaderColumnsInfo.findIndex(
      (x) => x.key === selectedColumn.key
    );
    let sort: SortingOrderType = SortingOrderType.DEFAULT;
    switch (approvedHeaderColumnsInfo[index].sortOrder) {
      case SortingOrderType.DESC:
      case SortingOrderType.DEFAULT:
        sort = SortingOrderType.ASC;
        break;
      case SortingOrderType.ASC:
        sort = SortingOrderType.DESC;
        break;
    }
    const tempHeadersApproved = [...approvedHeaderColumnsInfo];
    tempHeadersApproved.forEach((header) => {
      if (header.name !== "") {
        header.sortOrder = SortingOrderType.DEFAULT;
      }
    });
    const tempHeadersPending = pendingHeaderColumnsInfo.map((header, idx) => {
      if (selectedColumn.key === "approvalDate" && idx === 4) {
        header.sortOrder = SortingOrderType.ASC;
        return header;
      }
      if (header.key === selectedColumn.key) {
        header.sortOrder = sort;
      } else if (header.name !== "") {
        header.sortOrder = SortingOrderType.DEFAULT;
      }
      return header;
    });
    tempHeadersApproved[index].sortOrder = sort;
    changeSort(selectedColumn.key, sort);
    setApprovedHeaderColumns(tempHeadersApproved);
    setPendingHeaderColumns(tempHeadersPending);
  };

  return (
    <TOCPresentation
      tocPayload={tocPayload}
      onClickTabName={onClickTabName}
      onClearSearchBox={onClear}
      onClickSearchBox={onClickSearchBox}
      onClickSearchButton={onClickSearchButton}
      onClickFilterButton={onClickFilterButton}
      handleSearchInput={(value) => {
        if (abortControllerForFetchMessagesAsync.current) {
          abortControllerForFetchMessagesAsync.current();
        }
        setSearchText(value);
        debouncedSearch(value);
      }}
      searchText={searchText}
      onNextData={changePageOffset}
      onRemoveFilter={onClearFilter}
      onApplyFilter={onApplyFilter}
      onClearFilter={onClearFilter}
      pendingTocColumns={pendingHeaderColumnsInfo}
      approvedTocColumns={approvedHeaderColumnsInfo}
      getDateText={getDateText}
      handleApprovedListSort={handleApprovedListSorting}
      handlePendingListSort={handlePendingListSorting}
    />
  );
};

export default TOC;
