import React, { useEffect, useRef, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { deleteAllCookies } from "shared/methods/utilityFunctions";
import * as workerTimers from "worker-timers";

const useIdleTimerContainer = () => {
  const timeout = 1000 * 60 * 30;
  const [timeRemaining, setRemaining] = useState(timeout);
  const [isIdle, setIsIdle] = useState(false);

  const handleOnActive = () => setIsIdle(false);

  const handleOnIdle = () => {
    deleteAllCookies();
    localStorage.clear();
    sessionStorage.clear();
    setIsIdle(true);
  };

  const { getRemainingTime } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    timers: workerTimers,
  });

  useEffect(() => {
    setRemaining(getRemainingTime());

    workerTimers.setInterval(() => {
      setRemaining(getRemainingTime());
    }, 1000);
  }, []);

  return {
    isIdle,
    timeRemaining,
  };
};

export default useIdleTimerContainer;
