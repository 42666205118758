import React from "react";
import NoTOC from "shared/assets/images/no-tocs-found.svg";
import NoResults from "shared/assets/images/patients-no-search-results.svg";
import NoPendingTOC from "shared/assets/images/no-pending-tocs.png";
import NoApprovedTOC from "shared/assets/images/no-approved-tocs.svg";
import Loading from "shared/assets/images/loader.gif";
import "./empty-state.styles.scss";
import Button from "components/Button";
import { EmptyStateType } from "./empty-state.enum";
import {
  NO_APPROVED_TOCS_HEADER,
  NO_APPROVED_TOCS_TEXT,
  NO_TOCS_HEADER,
  NO_TOCS_TEXT,
} from "./empty-state.constant";

type EmptyStateProps = {
  type?: EmptyStateType;
  onClick?: () => void;
};

const EmptyState = (props: EmptyStateProps) => {
  const { type, onClick } = props;
  if (type === EmptyStateType.NO_PENDING_TOCS) {
    return (
      <div className="empty-state-container">
        <img src={NoPendingTOC} alt="No pending tocs" />
      </div>
    );
  }
  if (type === EmptyStateType.NO_APPROVED_TOCS) {
    return (
      <div className="empty-state-container">
        <div className="no-approved-toc-found">
          <img src={NoApprovedTOC} alt="No approved tocs" />
          <div className="no-approved-toc-found-heading">
            {NO_APPROVED_TOCS_HEADER}
          </div>
          <div className="no-approved-toc-found-text">
            {NO_APPROVED_TOCS_TEXT}
          </div>
        </div>
      </div>
    );
  }
  if (type === EmptyStateType.NO_TOCS) {
    return (
      <div className="empty-state-container">
        <div className="no-toc-found">
          <img src={NoTOC} alt="No tocs found" />
          <div className="no-toc-found-heading">{NO_TOCS_HEADER}</div>
          <div className="no-toc-found-text">{NO_TOCS_TEXT}</div>
        </div>
      </div>
    );
  }
  if (type === EmptyStateType.NO_MESSAGES) {
    return (
      <div className="empty-state-container">
        <img src={NoResults} alt="No Messages found" />
        <div className="button-text">No Messages found</div>
      </div>
    );
  }
  if (type === EmptyStateType.NO_EPISODES) {
    return (
      <div className="empty-state-container">
        <img src={NoResults} alt="No episodes found" />
        <div className="button-text">No episodes found</div>
      </div>
    );
  }
  if (type === EmptyStateType.NO_PATIENT_EPISODE) {
    return (
      <div className="empty-state-container">
        <img src={NoResults} alt="No patient episode found" />
        <div className="button-text">No Patient Episode found</div>
        <div>
          <Button
            text={"Back to Patient Episodes"}
            onClick={onClick ? onClick : () => {}}
            className="no-border-button back-button"
          />
        </div>
      </div>
    );
  }
  if (type === EmptyStateType.NO_TOC_DETAILS) {
    return (
      <div className="empty-state-container loader">
        <img src={NoResults} alt="loading" />
        <div className="button-text">TOC Details not found</div>
      </div>
    );
  }
  if (type === EmptyStateType.LOADING) {
    return (
      <div className="empty-state-container loader">
        <img src={Loading} alt="loading" />
      </div>
    );
  }

  return null;
};

export default EmptyState;
