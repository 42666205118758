import Icon from "components/Icon";
import DocumentIcon from "shared/assets/images/document-icon.svg";
import PdfIcon from "shared/assets/images/pdf-icon.svg";
import { formatBytes } from "shared/methods/utilityFunctions";
import "./thumbnail.styles.scss";

export type ThumbnailProp = {
  url: string;
  type: string;
  name: string;
  size: number;
};

const Thumbnail = ({
  thumbnail,
  onRemove,
  index,
}: { thumbnail: ThumbnailProp } & { onRemove: (index: number) => void } & {
  index: number;
}) => {

  const getImage = () => {
    if (
      thumbnail.type === "image/png" ||
      thumbnail.type === "image/jpeg" ||
      thumbnail.type === "image/jpg"
    ) {
      return thumbnail.url;
    } else if (
      thumbnail?.type === "application/msword" ||
      thumbnail?.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return DocumentIcon;
    } else if (thumbnail.type === "application/pdf") {
      return PdfIcon;
    }
  };

  return (
    <div className="thumbnail-container">
      <div>
        <div className="cross-icon" onClick={() => onRemove(index)}>
          <Icon icon="close" size={8} />
        </div>

        <img src={getImage()} className="preview-image" />
      </div>
      <div className="thumbnail-right-section">
        <div className="name">{thumbnail.name}</div>
        <div className="size">{formatBytes(thumbnail.size)}</div>
      </div>
    </div>
  );
};

export default Thumbnail;
