import { developmentConfig } from "./development.config";
import { releaseConfig } from "./release.config";
import { AppConfigurationClient } from "@azure/app-configuration";

/**
 * The configurations the application uses
 */
export interface IConfig {
  /**
   * Flag indicating whether we are specifying the urls manually
   * or loading them from Azure.
   * This is useful to set to true when you want to point to a server-side
   * API that is running locally
   *
   * This can be set in the development.config.ts only
   */
  overrideConfigurationUrl?: boolean;

  /**
   * The Url of the Azure Configuration
   */
  configurationUrl: string;

  /**
   * The current environment being used
   * ex: qa, int01, int02, etc
   */
  environment: string;

  /**
   * The base url for the Authentication API
   */
  authenticationBase?: string;

  /**
   * The base url for eFusion
   */
  efusionBase?: string;

  /**
   * The base url for the Messaging API
   */
  messagingBase?: string;

  /**
   * The base url for the Task Management API
   */
  taskManagementBase?: string;

  /**
   * The base url for the Notifications API
   */
  notificationBase?: string;

  /**
   * The subscription key used by the APIs
   */
  subscriptionKey?: string;
}

/**
 * Contains all the configurations used by the application
 */
const config: IConfig = {
  configurationUrl: "",
  environment: "",
};

/**
 * Determine if we are running the app in development mode
 */
function isDev(): boolean {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
}

/**
 * Fetches the configurations of the application from the specified url and environment
 * and stores the values locally to use throughout the app
 */
export const initConfiguration = async () => {
  if (isDev() && developmentConfig.overrideConfigurationUrl) {
    for (const devSettingKey in Object.keys(developmentConfig)) {
      (config as any)[devSettingKey] = (developmentConfig as any)[
        devSettingKey
      ];
    }
    (config as any) = developmentConfig;
    return;
  }

  const appConfigUrl = isDev()
    ? developmentConfig.configurationUrl
    : releaseConfig.configurationUrl;
  const environment = isDev()
    ? developmentConfig.environment
    : releaseConfig.environment;

  // Setup Azure AppConfiguration Client to get settings
  const client = new AppConfigurationClient(appConfigUrl);

  // Fetch all the configurations
  const configurations = await client.listConfigurationSettings({
    labelFilter: environment,
  });

  for await (const setting of configurations) {
    (config as any)[setting.key] = setting.value;
  }
};

/**
 * Getter for the configurations
 */
export function getConfig(): IConfig {
  return config;
}
