import { SortingOrderType } from "shared/types/enum";
import { HeaderColumnInfo } from "../types";

export const headerColumns: HeaderColumnInfo[] = [
  {
    key: "name",
    name: "Patient Name",
    sortOrder: SortingOrderType.DEFAULT,
  },
  {
    key: "dob",
    name: "Date of Birth",
    sortOrder: SortingOrderType.DEFAULT,
  },
  {
    key: "episode",
    name: "Episode",
    sortOrder: SortingOrderType.DEFAULT,
  },
  {
    key: "surgerydate",
    name: "Surgery Date",
    sortOrder: SortingOrderType.DEFAULT,
  },
  {
    key: "physician",
    name: "Physician",
    sortOrder: SortingOrderType.DEFAULT,
  },
  {
    key: "navigator",
    name: "Navigator",
    sortOrder: SortingOrderType.DEFAULT,
  },
  {
    key: "createddate",
    name: "Creation Date Time",
    sortOrder: SortingOrderType.DESC,
  },
];

export const filterLabelConstants = {
  SURGERY_DATE: "Surgery Date",
  PHYSICIAN: "Physician",
};
