import React from "react";
import Icon from "components/Icon";
import { PillProps } from "./type";
import "./styles.scss";

const Pill = ({ text, onClear }: PillProps) => {
  return (
    <div className="pill">
      <div className="text">{text}</div>
      <div className="close-icon" onClick={onClear}>
        <Icon icon="close" size={10} />
      </div>
    </div>
  );
};

export default Pill;
