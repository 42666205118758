import { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationState,
  resetNotifications,
  setRunTasksPostAcknowledge,
} from "state/features/notification/notification.slice";
import {
  acknowledgeNotification,
  getNotificationCountAsync,
  getNotificationsAsync,
} from "state/features/notification/notification.action";
import ConversationLoading from "components/chat/loader/chat-loading.component";
import {
  IGetNotificationsAsyncRequestPayload,
  INotification,
} from "state/types/notification-slice-type";
import NotificationRow from "pages/notification/components/notification-row/notification-row.component";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import NoNotification from "shared/assets/images/no-notifications.svg";
import "./notification-listing.styles.scss";
import { ActionType } from "pages/notification/common/action-type.enum";
import moment from "moment";
import EmptyState from "components/empty-state/empty-state.component";
import { EmptyStateType } from "components/empty-state/empty-state.enum";
import { MessageOpenFrom, UserTypes } from "shared/types/enum";
import { useHistory } from "react-router";
import { setIsChatScreenVisible } from "state/features/common/common.slice";
import {
  setCurrentChat,
  setLastMessageNotificationStatus,
} from "state/features/messages/messages.slice";
import { getConversationParticipants } from "state/features/messages/messages.action";
import { useAppDispatch } from "state/store";

const NotificationListing = ({ userEmail }: { userEmail: string }) => {
  useAxiosAuthenticated();
  const history = useHistory();
  const {
    notifications,
    isLoadingNotifications,
    isNotificationDrawerOpen,
    runTasksPostAcknowledge,
    shouldRefreshNotifications,
    prevNotificationsCount,
  } = useSelector(getNotificationState);
  const appDispatch = useAppDispatch();
  const [currentNotification, setCurrentNotification] =
    useState<INotification>();
  const [requestPayload, setRequestPayload] =
    useState<IGetNotificationsAsyncRequestPayload>({
      ownerUserId: userEmail,
      offset: 0,
      limit: 10,
      userType: UserTypes.PHYSICIAN,
    });

  const incrementPage = () => {
    setRequestPayload((prev) => {
      return {
        ...prev,
        offset: prev?.offset + prev?.limit,
      };
    });
  };

  const openConversation = async (notification: INotification) => {
    const { payload, eventTitle, ownerUserId, internalId } = notification;
    appDispatch(getConversationParticipants(payload.conversationId)).then(
      (response) => {
        appDispatch(
          setCurrentChat({
            conversationId: parseInt(payload.conversationId, 10),
            twilioConversationId: payload.twilioConversationId,
            participants: response?.payload?.participantDetails,
            patientDOB: payload.patientDOB,
            subject: payload.subject,
            name: eventTitle,
          })
        );
        history.push("/messages");
        appDispatch(
          setIsChatScreenVisible({
            isOpen: true,
            openFrom: MessageOpenFrom.EMPTY,
          })
        );
      }
    );

    if (!notification.isRead) {
      appDispatch(
        acknowledgeNotification({
          ownerUserId,
          internalId,
          createdBefore: moment().toISOString(),
          runTasksPostAcknowledge: true,
        })
      );
    }
  };

  const handleTocNotificationClick = (notification: INotification) => {
    const { payload, ownerUserId, internalId } = notification;
    history.push(`/toc/${payload.intakeId}`);
    if (!notification.isRead) {
      appDispatch(
        acknowledgeNotification({
          ownerUserId,
          internalId,
          createdBefore: moment().toISOString(),
          runTasksPostAcknowledge: true,
        })
      );
      appDispatch(getNotificationCountAsync({ ownerUserId: userEmail }));
    }
  };

  const handleOfftrackNotificationClick = (notification: INotification) => {
    const { payload, ownerUserId, internalId } = notification;
    history.push(`/patient-episodes/edit/${payload.intakeId}`);
    if (!notification.isRead) {
      appDispatch(
        acknowledgeNotification({
          ownerUserId,
          internalId,
          createdBefore: moment().toISOString(),
          runTasksPostAcknowledge: true,
        })
      );
      appDispatch(getNotificationCountAsync({ ownerUserId: userEmail }));
    }
  };

  useEffect(() => {
    if (
      runTasksPostAcknowledge &&
      currentNotification &&
      currentNotification.actionType === ActionType.NEW_MESSAGE
    ) {
      appDispatch(
        setLastMessageNotificationStatus(
          currentNotification.payload.conversationId
        )
      );
      appDispatch(getNotificationCountAsync({ ownerUserId: userEmail }));
      appDispatch(setRunTasksPostAcknowledge(false));
    }
  }, [runTasksPostAcknowledge, currentNotification, userEmail, appDispatch]);

  const handleNotificationClick = (notification: INotification): void => {
    setCurrentNotification(notification);

    switch (notification.actionType) {
      case ActionType.NEW_MESSAGE:
        openConversation(notification);
        break;
      case ActionType.NEW_TOC:
        handleTocNotificationClick(notification);
        break;
      case ActionType.PATIENT_OFFTRACK:
        handleOfftrackNotificationClick(notification);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    appDispatch(getNotificationsAsync(requestPayload));
  }, [
    isNotificationDrawerOpen,
    appDispatch,
    requestPayload,
    shouldRefreshNotifications,
  ]);

  useEffect(() => {
    return () => {
      appDispatch(resetNotifications());
    };
  }, [appDispatch]);

  return notifications.length > 0 ? (
    <>
      <InfiniteScroll
        className="notification-scroll-container"
        next={() => {
          incrementPage();
        }}
        hasMore={
          notifications.length > 0 &&
          prevNotificationsCount !== notifications.length
        }
        loader={isLoadingNotifications ? <ConversationLoading /> : <></>}
        dataLength={notifications.length}
        height="100%"
        endMessage={
          <div className="end-message">
            <b>{"No more notifications available."}</b>
          </div>
        }
      >
        {notifications?.map((notification: INotification) => (
          <NotificationRow
            key={notification.id}
            notification={notification}
            onClick={() => handleNotificationClick(notification)}
          />
        ))}
      </InfiniteScroll>
    </>
  ) : (
    <>
      {isLoadingNotifications ? (
        <div className="loading-container">
          <EmptyState type={EmptyStateType.LOADING} />
        </div>
      ) : (
        <div className="empty-notifications-container">
          <img
            src={NoNotification}
            className="empty-notification-img"
            alt="no-notifications-img"
          />
          <p className="empty-title">No notification yet!</p>
          <p className="empty-content">
            Looks like there is nothing to notify you.
          </p>
        </div>
      )}
    </>
  );
};

export default NotificationListing;
