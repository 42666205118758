import { IConfig } from "./config";

/**
 * WARNING!!
 *
 * These values should never change
 */
export const releaseConfig: IConfig = {
    configurationUrl: "#{appConfigurationUrl}#",
    environment: "#{appConfigurationEnvironment}#",
};
