import Button from "components/Button";
import "./styles.scss";
import { PatientFormTabs } from "../enums";

type Footer = {
  activeTabId: PatientFormTabs;
  isSaveCtaDisabled: boolean;
  isSaveCtaLoading: boolean;
  handleNextClick: () => void;
  handleBackClick: () => void;
  handleSaveClick: () => void;
  handleClearClick: () => void;
  hideSaveCta?: boolean;
  hideClearCta?: boolean;
};

const Footer = ({
  activeTabId,
  isSaveCtaDisabled,
  isSaveCtaLoading,
  handleBackClick,
  handleNextClick,
  handleSaveClick,
  handleClearClick,
  hideSaveCta,
  hideClearCta,
}: Footer) => {
  const renderBackButton = () => {
    if (
      activeTabId === PatientFormTabs.PATIENT_PERSONAL_DETAILS ||
      activeTabId === PatientFormTabs.PATIENT_ADDRESS
    ) {
      return (
        <Button
          text="Back"
          className="clear-button"
          onClick={handleBackClick}
        />
      );
    }
  };

  const renderNextButton = () => {
    if (
      activeTabId === PatientFormTabs.SURGEON_AND_EPISODE_DETAILS ||
      activeTabId === PatientFormTabs.PATIENT_PERSONAL_DETAILS
    ) {
      return (
        <Button text="Next" className="next-button" onClick={handleNextClick} />
      );
    }
  };

  return (
    <div className="footer-button-section">
      {!hideClearCta ? (
        <Button
          text="Clear"
          className="clear-button"
          onClick={() => {
            handleClearClick();
          }}
        />
      ) : null}
      {renderBackButton()}
      {renderNextButton()}
      {activeTabId === PatientFormTabs.PATIENT_ADDRESS && !hideSaveCta ? (
        <Button
          text="Save"
          className="next-button"
          onClick={handleSaveClick}
          disabled={isSaveCtaDisabled}
          buttonWithLoader={true}
          isLoading={isSaveCtaLoading}
        />
      ) : null}
    </div>
  );
};

export default Footer;
