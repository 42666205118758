import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import "./chat-list.styles.scss";
import { formatBytes } from "shared/methods/utilityFunctions";
import { useSelector } from "react-redux";
import { getAuth } from "state/features/authentication/authentication.slice";
import { MessageProps } from "components/side-popups/props";
import ConversationLoading from "../loader/chat-loading.component";
import Message from "../message/message.component";
import Seprator from "../separator/separator.component";
import { MessageOpenFrom } from "shared/types/enum";

const ChatList = ({
  messageDetails,
  endMessage,
  totalLength,
  fetchMoreData,
  isPreviewThumbnailVisible,
  cancelUpload,
  sendMessage,
  readCount,
  unreadCount,
  openFrom,
  loading = true,
  isGroupChat,
  renderInfoBarWithoutFooter,
  renderInfoBarWithFooter,
}: MessageProps) => {
  const authState = useSelector(getAuth);
  const allMessages = messageDetails.map(
    (message: any, index: any, messages: any) => {
      let sepratorToBeShown;
      if (index === messages.length - 1) {
        sepratorToBeShown = true;
      } else {
        sepratorToBeShown =
          index <= messages.length - 1 &&
          moment(message.createdAt).format("MM-DD-YYYY") !==
            moment(messages[index + 1]?.createdAt).format("MM-DD-YYYY");
      }
      return {
        ...message,
        sepratorToBeShown,
      };
    }
  );

  const renderMessages = () => {
    return allMessages.map(
      ({ message, createdAt, user, _id, sepratorToBeShown }, index: number) => {
        let date;
        if (sepratorToBeShown) {
          date = moment(createdAt).calendar({
            sameDay: "[Today]",
            nextDay: "[Tomorrow]",
            nextWeek: "MM-DD-YYYY dddd",
            lastDay: "[Yesterday]",
            lastWeek: "[Last] dddd MM-DD-YYYY",
            sameElse: "MM-DD-YYYY dddd",
          });
        }
        return (
          <>
            <Message
              key={`${createdAt}${_id}${message.content}`}
              id={_id}
              type={message.type}
              text={message.content}
              fileName={message.fileName}
              fileSize={formatBytes(message.fileSize)}
              time={moment(createdAt).format("hh:mm A")}
              isColored={user._id === authState.user.id}
              name={user.name}
              isProgressVisible={message.isProgressVisible}
              progress={message.progress}
              cancelUpload={cancelUpload}
              messageFile={message.file}
              sendMessage={sendMessage}
              isCancelled={message.isCancelled}
              readCount={readCount}
              unreadCount={unreadCount}
              index={index}
              isGroupChat={isGroupChat}
            />
            {sepratorToBeShown && (
              <Seprator key={`${createdAt}${_id}separator`} time={`${date}`} />
            )}
          </>
        );
      }
    );
  };
  return (
    <div
      className={`chat-messages ${
        openFrom === MessageOpenFrom.COMPOSE ? MessageOpenFrom.COMPOSE : ""
      } ${isPreviewThumbnailVisible ? "reduce-height" : "height"} ${
        renderInfoBarWithoutFooter ? "inactive-height" : ""
      } ${renderInfoBarWithFooter ? "height-with-infobar" : ""}`}
      id="chat-messages"
    >
      <InfiniteScroll
        dataLength={messageDetails.length}
        next={() => {
          fetchMoreData();
        }}
        style={{ display: "flex", flexDirection: "column-reverse" }}
        inverse={true}
        hasMore={messageDetails.length < totalLength}
        loader={<ConversationLoading />}
        height="100%"
        endMessage={<div className="end-message">{endMessage}</div>}
      >
        {loading && <ConversationLoading />}
        {renderMessages()}
      </InfiniteScroll>
    </div>
  );
};

export default ChatList;
