import { createAsyncThunk } from "@reduxjs/toolkit";
import { initConfiguration } from "../../../config/config";
import { getConfig } from "../../../config/config";
import { axiosPrivate } from "shared/api/axios-wrapper.service";

export const getConfigurationSettings = createAsyncThunk(
  "common/getConfigurationSettings",
  async () => {
    return initConfiguration();
  }
);

export const checkIfFeatureEnabledAsync = createAsyncThunk(
  "common/checkIfFeatureEnabledAsync",
  async (requestPayload: { featureFlagName: string }) => {
    const response = await axiosPrivate.get(
      `${getConfig().efusionBase}/api/v2/ExtFeature/${
        requestPayload.featureFlagName
      }`
    );
    return response;
  }
);
