/* eslint-disable @typescript-eslint/dot-notation */
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import addEditEpisodeSlice from "state/features/add-edit-episode/add-edit-episode.slice";
import authSlice from "state/features/authentication/authentication.slice";
import commonSlice from "state/features/common/common.slice";
import messagesSlice from "state/features/messages/messages.slice";
import notificationSlice from "state/features/notification/notification.slice";
import patientEpisodesSlice from "state/features/patient-episodes/patient-episodes.slice";
import tocSlice from "state/features/toc/toc.slice";

const rootReducer = combineReducers({
  auth: authSlice,
  patientEpisodes: patientEpisodesSlice,
  addEditEpisode: addEditEpisodeSlice,
  toc: tocSlice,
  messages: messagesSlice,
  common: commonSlice,
  notification: notificationSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .prepend(thunk)
      .concat(logger),
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
