export const inputFieldNames = {
  PROCEDURE_DESCRIPTION: "procedureDescription",
  SURGEON_NAME: "surgeonName",
  SURGEON_PRACTICE: "surgeonPractice",
  PROGRAM: "program",
  EPISODE: "episode",
  CPT: "cpt",
  EPISODE_SETTINGS: "episodeSettings",
  EPISODE_TYPE: "episodeType",
  EPISODE_CARE: "episodeCare",
  SURGERY_SITE_SIDE: "surgerySiteSide",
  ADMIT_DATE: "admitDate",
  SURGERY_DATE: "surgeryDate",
  ACUTE_CARE_FACILITY: "acuteCareFacility",
  CNN: "cnn",
  PATIENT_EPISODE_STATUE: "patientEpisodeStatus",
  NPI: "npi",
};

export const CPT_LENGTH = 5;
export const CCN_LENGTH = 6;
export const NPI_LENGTH = 10;

export const episodeSettingType = {
  INPATIENT: { text: "Inpatient", id: "5" },
  OUTPATIENT: { text: "Outpatient", id: "6" },
};

export const episodePlanType = {
  PLANNED: { text: "Planned", id: "6" },
  UNPLANNED: { text: "Unplanned", id: "7" },
};

export const episodeCareType = {
  MEDICAL: { text: "Medical", id: "8" },
  SURGICAL: { text: "Surgical", id: "9" },
};

// export const SURGEON_EPISODE_DETAILS_FORM: ISurgeonEpisodeDetailsFormType = {
//   surgeonName: {
//     value: "",
//     isValid: true,
//     isRequired: true,
//   },
//   surgeonId: {
//     value: "",
//     isValid: true,
//     isRequired: true,
//   },
//   npi: {
//     value: "",
//     isValid: true,
//     isRequired: false,
//   },
//   surgeonPractice: {
//     value: { id: "", name: "" },
//     isValid: true,
//     isRequired: true,
//   },
//   program: {
//     value: { id: "", name: "" },
//     isValid: true,
//     isRequired: true,
//   },
//   episode: {
//     value: { id: "", name: "" },
//     isValid: true,
//     isRequired: true,
//   },
//   cpt: {
//     value: "",
//     isValid: true,
//     isRequired: false,
//   },
//   procedureDescription: {
//     value: "",
//     isValid: true,
//     isRequired: true,
//   },
//   episodeSettings: {
//     value: {
//       name: episodeSettingType.INPATIENT.text,
//       id: episodeSettingType.INPATIENT.id,
//     },
//     isValid: true,
//     isRequired: true,
//   },
//   episodeType: {
//     value: {
//       name: episodePlanType.PLANNED.text,
//       id: episodePlanType.PLANNED.id,
//     },
//     isValid: true,
//     isRequired: true,
//   },
//   episodeCare: {
//     value: {
//       name: episodeCareType.SURGICAL.text,
//       id: episodeCareType.SURGICAL.id,
//     },
//     isValid: true,
//     isRequired: true,
//   },
//   surgerySiteSide: {
//     value: { name: "", id: "" },
//     isValid: true,
//     isRequired: true,
//   },
//   acuteCareFacility: {
//     value: { name: "", id: "" },
//     isValid: true,
//     isRequired: true,
//   },
//   cnn: {
//     value: "",
//     isValid: true,
//     isRequired: false,
//   },
//   admitDate: {
//     value: null,
//     isValid: true,
//     isRequired: true,
//   },
//   surgeryDate: {
//     value: null,
//     isValid: true,
//     isRequired: true,
//   },
//   patientEpisodeStatus: {
//     value: "New",
//     isValid: true,
//     isRequired: true,
//   },
// };

export const episodeSettingRadioList = [
  {
    text: episodeSettingType.INPATIENT.text,
    id: episodeSettingType.INPATIENT.id,
    checked: false,
  },
  {
    text: episodeSettingType.OUTPATIENT.text,
    id: episodeSettingType.OUTPATIENT.id,
    checked: false,
  },
];

export const episodeTypeRadioList = [
  {
    text: episodePlanType.PLANNED.text,
    id: episodePlanType.PLANNED.id,
    checked: false,
  },
  {
    text: episodePlanType.UNPLANNED.text,
    id: episodePlanType.UNPLANNED.id,
    checked: false,
  },
];

export const episodeCareRadioList = [
  {
    text: episodeCareType.MEDICAL.text,
    id: episodeCareType.MEDICAL.id,
    checked: false,
  },
  {
    text: episodeCareType.SURGICAL.text,
    id: episodeCareType.SURGICAL.id,
    checked: false,
  },
];
