import Icon from "../Icon";
import React from "react";
import { ButtonProps } from "./props";
import "./styles.scss";
import { ButtonLoader } from "components/ButtonLoader";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { CustomToolTip } from "components/tool-tip/tool-tip.component";

const Button = ({
  text,
  className,
  onClick,
  icon,
  iconClassName,
  buttonSize,
  disabled,
  imgChildren,
  isLoading,
  buttonWithLoader,
  showToolTip,
}: ButtonProps) => {
  if (imgChildren) {
    return (
      <div className="img-button-container">
        <input
          type="button"
          className={`${className}`}
          onClick={onClick}
          value={text}
          disabled={disabled}
        />
        {imgChildren}
      </div>
    );
  }
  if (icon) {
    return (
      <div
        onClick={onClick}
        data-tip
        data-for={text}
        className={`${className ? className : ""} icon-button`}
      >
        <Icon
          icon={icon}
          className={iconClassName ? iconClassName : ""}
          size={buttonSize}
        />
        {showToolTip && text && !isEmptyString(text) && (
          <CustomToolTip text={text} place="bottom" />
        )}
      </div>
    );
  }

  if (buttonWithLoader) {
    return (
      <button
        className={className || ""}
        onClick={onClick}
        disabled={disabled || isLoading}
      >
        {isLoading ? <ButtonLoader /> : <div>{text}</div>}
      </button>
    );
  }

  return (
    <input
      type="button"
      className={`${className}`}
      onClick={onClick}
      value={text}
      disabled={disabled}
    />
  );
};

export default Button;
