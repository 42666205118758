import { CCN_LENGTH, CPT_LENGTH } from "../constants";

export const isCptValid = (value: string) => value.length === CPT_LENGTH;

export const isCcnValid = (value: string) => value.length === CCN_LENGTH;

export const isStringEmpty = (str: string) =>
!str || (typeof str === "string" && str.trim() === "");

export const isProcedureDescriptionValid = (value: string) =>
  0 < value.length && value.length <= 500;

export const isSurgeryDateValid = (admitDate: string, val: string) =>
  !!admitDate && new Date(admitDate) <= new Date(val);
