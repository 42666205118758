import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays, subDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import "./single-date-picker.styles.scss";
import MaskedInput from "react-input-mask";
import Icon from "components/Icon";
import PropTypes from "prop-types";
import { isEmptyString } from "shared/methods/utilityFunctions";
import moment from "moment";

const SingleDatePicker = ({
  handleDateChange,
  isError,
  isDisabled,
  placeholder,
  defaultDate,
  minDate,
  maxDate,
  className,
  popperModifiers,
  required,
  errorMessage,
  popperPlacement,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [openCalendar, setOpenCalendar] = useState(false);
  const dateRef = useRef();

  useEffect(() => {
    if (defaultDate) {
      setStartDate(new Date(defaultDate));
    } else {
      setStartDate(null);
    }
  }, [defaultDate]);

  const onDateChange = (date, e) => {
    setStartDate(date);
    handleDateChange(date);
  };

  const formatChars = {
    y: "[12]",
    x: "[09]",
    Y: "[0-9]",
    d: "[0-3]",
    D: "[0-9]",
    m: "[0-1]",
    M: "[0-9]",
    Q: "[0-9]",
  };

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    if (!newState.value) return newState;
    let { value } = newState;
    let dateParts = value.split("/");
    let monthPart = dateParts[0];
    let dayPart = dateParts.length > 1 ? dateParts[1] : null;
    let yearPart = dateParts.length > 2 ? dateParts[2] : null;
    // Conditional mask for the 2nd digit of day based on the first digit
    if (dayPart != null && dayPart.startsWith("3"))
      formatChars.D = "[0-1]"; // To block 39, 32, etc.
    else if (dayPart != null && dayPart.startsWith("0"))
      formatChars.D = "[1-9]"; // To block 00.
    else formatChars.D = "[0-9]"; // To allow 05, 15, 25  etc.

    // Conditional mask for the 2nd digit of month based on the first digit
    if (monthPart.startsWith("1"))
      formatChars.M = "[0-2]"; // To block 15, 16, etc.
    else formatChars.M = "[1-9]"; // To allow 05, 06  etc - but blocking 00.

    // Conditional mask for the 2nd digit of year based on the first digit
    if (yearPart != null && yearPart.startsWith("1"))
      formatChars.x = "[9]"; // To block 15, 16, etc.
    else formatChars.x = "[0]";
    if (
      maxDate &&
      yearPart !== null &&
      monthPart !== null &&
      moment(value).diff(maxDate, "days") > 0
    ) {
      value = moment().format("MM/DD/YYYY");
    } else if (
      minDate &&
      dateParts.length >= 2 &&
      yearPart !== null &&
      yearPart.length === 4 &&
      monthPart !== null &&
      moment(value).diff(minDate, "days") < 0
    ) {
      value = moment().format("MM/DD/YYYY");
    }
    return { value, selection: newState.selection };
  };

  return (
    <div
      id="single-date-picker-selector"
      className={`${isDisabled ? "disabled-date-selector" : ""} ${
        className ? className : ""
      }`}
    >
      <div>
        <DatePicker
          ref={dateRef}
          required={required}
          selected={startDate}
          onChange={onDateChange}
          dateFormat="MM/dd/yyyy"
          className={`date-picker-input ${isError ? "red-border" : ""}`}
          calendarClassName="date-picker-calender"
          monthClassName={() => {
            return "date-picker-month";
          }}
          dayClassName={() => {
            return "date-picker-day";
          }}
          weekDayClassName={() => {
            return "date-picker-week-day";
          }}
          customInput={
            <MaskedInput
              className="date-picker-mask-input"
              mask="mM/dD/yxYQ"
              formatChars={formatChars}
              maskChar={null}
              value={startDate}
              onChange={onDateChange}
              beforeMaskedValueChange={beforeMaskedValueChange}
            />
          }
          popperPlacement={popperPlacement ? popperPlacement : "auto"}
          shouldCloseOnSelect
          isClearable
          disabled={isDisabled}
          placeholderText={placeholder ? placeholder : "MM/DD/YYYY"}
          minDate={minDate ? addDays(minDate, 1) : new Date("01/01/1900")}
          maxDate={maxDate ? maxDate : subDays(new Date("01/01/2100"), 1)}
          onInputClick={() => setOpenCalendar(true)}
          onClickOutside={() => setOpenCalendar(false)}
          onSelect={() => setOpenCalendar(false)}
          open={openCalendar}
          popperModifiers={popperModifiers}
        />
        {(startDate === null || isEmptyString(startDate)) && !isDisabled && (
          <span
            className={`calender-icon  ${isDisabled ? "disabled" : ""}`}
            onClick={(e) => {
              if (!isDisabled) {
                dateRef.current.state.open = true;
                dateRef.current.state.preSelection = minDate
                  ? addDays(minDate, 1)
                  : new Date();
                setOpenCalendar(true);
              }
            }}
          >
            <Icon icon="Calender" size={16} />
          </span>
        )}
      </div>
      {isError && <div className="red-color">{errorMessage}</div>}
    </div>
  );
};

SingleDatePicker.propTypes = {
  handleDateChange: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    undefined,
    null,
  ]).isRequired,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), undefined, null])
    .isRequired,
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), undefined, null])
    .isRequired,
  className: PropTypes.string,
  popperModifiers: PropTypes.array,
  required: PropTypes.bool,
  errorMessage: "Please select the date.",
  popperPlacement: PropTypes.string,
};

export default SingleDatePicker;
