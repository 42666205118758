import React from "react";
import { Link } from "react-router-dom";
import {
  isEmptyString,
  removeAllSpecialCharacters,
  removeAllSpecialCharactersButNotSpace,
} from "shared/methods/utilityFunctions";
import "./styles.scss";

const Breadcrumb = ({
  pathname,
  extraInfo,
  isPatientPage,
}: {
  pathname: string;
  extraInfo?: string;
  isPatientPage?: boolean;
}) => {
  const individualLinks = pathname.split("/");

  return (
    <nav aria-label="breadcrumb">
      <ul className="breadcrumb">
        {individualLinks.map((name, index) => {
          if (name !== "") {
            return (
              <li
                key={name}
                className={`breadcrumb-item ${
                  index === individualLinks.length - 1 ? "inactive" : "active"
                }`}
                aria-current="page"
              >
                <Link to={`/${name}`}>
                  {index > 1 ? (
                    <div className="name">
                      {isPatientPage
                        ? name
                        : removeAllSpecialCharactersButNotSpace(
                            name
                          ).toUpperCase()}
                    </div>
                  ) : (
                    removeAllSpecialCharactersButNotSpace(name).toUpperCase()
                  )}
                </Link>
              </li>
            );
          }
        })}
        {extraInfo && !isEmptyString(extraInfo) && (
          <div className="extra-breadcrumb-info">{extraInfo}</div>
        )}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
