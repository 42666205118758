import { CustomToolTip } from "components/tool-tip/tool-tip.component";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInitialsIfPictureNotPresent } from "shared/methods/taskSpecificMethods";
import {
  getCommon,
  setIsLogoutModalVisible,
} from "state/features/common/common.slice";
// import { showLogoutModal } from "state/actions";
import "./styles.scss";

const Avatar = ({ personName }: { personName: string }) => {
  const initials = getInitialsIfPictureNotPresent(personName);
  const dispatch = useDispatch();
  return (
    <div
      className="avatar-container"
      onClick={() => {
        dispatch(setIsLogoutModalVisible(true as boolean));
      }}
      data-tip
      data-for={personName}
    >
      {initials}
      <CustomToolTip text={personName} place="right" />
    </div>
  );
};

export default Avatar;
