import { ZIP_CODE_LENGTH } from "shared/assets/constants/commonConstants";

export const isAddressValid = (value: string) =>
  value.length >= 3 && value.length <= 95;

export const isCityValid = (value: string) =>
  value.length >= 3 && value.length <= 85;

export const isZipcodeValid = (value: string) =>
  value.length === ZIP_CODE_LENGTH;
