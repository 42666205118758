import ReactTooltip from "react-tooltip";
import { ToolTipProps } from "./custom-tooltip.types";
import "./custom-tooltip.styles.scss";

export const CustomToolTip = (props: ToolTipProps) => {
  const { text, place, id } = props;
  return (
    <ReactTooltip
      id={id}
      place={place}
      type="dark"
      effect="solid"
      className={`custom-tooltip ${place}`}
      delayShow={100}
      padding={"12px 21px"}
    >
      <span>{text}</span>
    </ReactTooltip>
  );
};
