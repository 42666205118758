export const getInitialsIfPictureNotPresent = (name: string): string => {
  if(name) {
    const arrayOfNames = name.trim().split(" ");
    // if name is present and surname is also present
    if (name && arrayOfNames.length > 1) {
      const nameIntials = arrayOfNames[0][0] + arrayOfNames[1][0];
      return nameIntials.toUpperCase();
    } else {
      return arrayOfNames[0][0].toUpperCase();
    }
  }
  else {
    return "U";
  }
};
