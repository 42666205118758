import { GroupBase, OptionsOrGroups } from "react-select";
import AsyncSelect from "react-select/async";
import { searchWithResetStyles } from "./search-with-reset.styles";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { useState } from "react";
import "./search-with-reset.styles.scss";
import Button from "components/Button";
type SearchWithResetProps = {
  value: any;
  onClick: (val: any) => void;
  onTextChange: (val: string) => void;
  placeholder: string;
  className: string;
  defaultOptions: any;
  loadOptions: (
    inputValue: string,
    callback: (options: any) => void
  ) => void | Promise<OptionsOrGroups<string, GroupBase<string>>>;
  onClickReset: () => void;
  buttonText: string;
  isSearchable: boolean;
  defaultValue: any;
  notFoundMessage: string;
  inputId?: string;
};

const SearchWithReset = (props: SearchWithResetProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    value,
    className,
    onClick,
    onTextChange,
    placeholder,
    defaultOptions,
    loadOptions,
    onClickReset,
    buttonText,
    isSearchable,
    defaultValue,
    notFoundMessage,
    inputId,
  } = props;
  return (
    <div className="search-with-reset-button-container">
      <AsyncSelect
        value={value}
        formatOptionLabel={(data: any) => (
          <div className="option-text">
            <div className="left">{data.label}</div>
            <div className="right">{data.value}</div>
          </div>
        )}
        className={className}
        onChange={onClick}
        onInputChange={(val) => {
          if (!isEmptyString(val)) {
            setIsMenuOpen(true);
          } else {
            setIsMenuOpen(false);
          }
          if (!isEmptyString(val) && isSearchable && value) {
            onTextChange(val);
          }
        }}
        loadingMessage={() => <div className="loading">Loading...</div>}
        noOptionsMessage={() => (
          <div className="loading">{notFoundMessage}</div>
        )}
        inputId={inputId ? inputId : ""}
        isLoading={false}
        loadOptions={loadOptions}
        defaultOptions={defaultOptions}
        placeholder={placeholder}
        styles={searchWithResetStyles}
        defaultMenuIsOpen={false}
        closeMenuOnSelect
        isSearchable={isSearchable}
        menuIsOpen={isMenuOpen}
      />
      <Button
        className="reset-button no-border-button"
        text={buttonText}
        onClick={onClickReset}
      />
    </div>
  );
};

export default SearchWithReset;
