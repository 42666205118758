import { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import TextField from "components/TextField";
import CustomDropDown from "components/CustomDropdown";
import RadioInputGroup from "components/RadioInputGroup";
import TextArea from "components/TextArea";
import SingleDatePicker from "components/SingleDatePicker";
import {
  getAddEditEpisodeMetadata,
  getAddEditEpisodeState,
  setEpisodeDropdownMetaData,
} from "state/features/add-edit-episode/add-edit-episode.slice";
import { isEmptyString } from "shared/methods/utilityFunctions";
import {
  getAcuteFacilityCareByIdAsync,
  getSurgerySiteSideAsync,
} from "state/features/add-edit-episode/add-edit-episode.action";
import {
  isCcnValid,
  isCptValid,
  isProcedureDescriptionValid,
  isStringEmpty,
  isSurgeryDateValid,
} from "./validations";
import {
  CPT_LENGTH,
  episodeSettingRadioList,
  episodeTypeRadioList,
  episodeCareRadioList,
  episodeCareType,
  episodePlanType,
  NPI_LENGTH,
  CCN_LENGTH,
} from "./constants";
import { ISurgeonEpisodeDetailsFormType } from "state/types/add-edit-episode-slice.type";
import { useAppDispatch } from "state/store";
import { SURGEON_EPISODE_DETAILS_FORM } from "state/constants/add-edit-patient";
import { TextLabels } from "pages/add-edit-episode/enums";

type SurgeonAndEpisodeDetailsProps = {
  form: ISurgeonEpisodeDetailsFormType;
  setForm: (val: ISurgeonEpisodeDetailsFormType) => void;
};
type RadioButtonListType = {
  text: string;
  id: string;
  checked: boolean;
};

const SurgeonAndEpisodeDetails = ({
  form,
  setForm,
}: SurgeonAndEpisodeDetailsProps) => {
  const dispatch = useDispatch();
  const metadata = useSelector(getAddEditEpisodeMetadata);
  const { existingEpisodeDetails } = useSelector(getAddEditEpisodeState);
  const allowPracticeUpdate = useRef(false);
  const appDispatch = useAppDispatch();
  const [episodeSettingInputs, setEpisodeSettingInputs] = useState<
    Array<RadioButtonListType>
  >(episodeSettingRadioList);
  const [episodeTypeInputs, setEpisodeTypeInputs] =
    useState<Array<RadioButtonListType>>(episodeTypeRadioList);
  const [episodeCareInputs, setEpisodeCareInputs] =
    useState<Array<RadioButtonListType>>(episodeCareRadioList);
  const [isEpisodeCareInputDisabled, setIsEpisodeCareInputDisabled] =
    useState<boolean>(true);
  const {
    surgeonId,
    surgeonName,
    npi,
    surgeonPractice,
    program,
    episode,
    cpt,
    procedureDescription,
    episodeSettings,
    episodeType,
    episodeCare,
    surgerySiteSide,
    acuteCareFacility,
    ccn,
    admitDate,
    surgeryDate,
    dischargeDate,
  } = form;

  const setDefaultSelectionForEpisodeSettingInput = () => {
    const defaultEpisodeSettingSelections = [...episodeSettingRadioList].map(
      (input) => {
        input.checked = false;
        if (input.id === episodeSettings.value.id) {
          input.checked = true;
        }
        return input;
      }
    );
    setEpisodeSettingInputs(defaultEpisodeSettingSelections);
  };

  const setDefaultSelectionForEpisodeTypeInput = () => {
    const defaultEpisodeTypeSelections = [...episodeTypeRadioList].map(
      (input) => {
        input.checked = false;
        if (input.id === episodeType.value.id) {
          input.checked = true;
          setIsEpisodeCareInputDisabled(
            input.text === episodePlanType.PLANNED.text
          );
        }
        return input;
      }
    );
    setEpisodeTypeInputs(defaultEpisodeTypeSelections);
  };

  const setDefaultSelectionForEpisodeCareInput = () => {
    const defaultEpisodeCareSelections = [...episodeCareRadioList].map(
      (input) => {
        input.checked = false;
        if (input.id === episodeCare.value.id) {
          input.checked = true;
        }
        return input;
      }
    );
    setEpisodeCareInputs(defaultEpisodeCareSelections);
  };

  useEffect(() => {
    setDefaultSelectionForEpisodeSettingInput();
    setDefaultSelectionForEpisodeTypeInput();
    setDefaultSelectionForEpisodeCareInput();
  }, [episodeSettings.value, episodeType.value, episodeCare.value]);

  const handleSurgeonNameChange = (value: any) => {
    const { name, PracticeId, NPI } = value;
    const surgeonpracticeData = SURGEON_EPISODE_DETAILS_FORM.surgeonPractice;
    let practiceDetails = null;
    let practiceToChange: any = surgeonpracticeData;
    let isValid = false;
    if (name) {
      practiceDetails =
        PracticeId &&
        metadata.practices &&
        metadata.practices.length &&
        metadata.practices.find((item) => item.key === PracticeId);
      practiceToChange = practiceDetails || surgeonpracticeData;
      isValid = practiceDetails ? true : surgeonPractice.isValid;
    }
    setForm({
      ...form,
      surgeonName: {
        ...surgeonName,
        value: name,
        NPI,
        PracticeId,
        isValid: true,
      },
      surgeonId: { ...surgeonId, value: value.key },
      surgeonPractice: {
        ...surgeonPractice,
        value: {
          name: practiceToChange.name ?? "",
          id: practiceToChange.key ?? "",
        },
        isValid,
      },
      npi: {
        ...npi,
        value: NPI,
        isValid: !isStringEmpty(NPI),
      },
    });
  };

  const handleSurgeonPracticeChange = (value: any, isValid = true) => {
    setForm({
      ...form,
      surgeonPractice: {
        ...surgeonPractice,
        value: { name: value.name ?? "", id: value.key ?? "" },
        isValid,
      },
    });
  };

  const handleProgramChange = (value: any) => {
    dispatch(setEpisodeDropdownMetaData([]));
    setForm({
      ...form,
      program: {
        ...program,
        value: { name: value.name, id: value.key },
        isValid: true,
      },
      episode: {
        ...episode,
        value: { name: "", id: "" },
        isValid: true,
      },
    });
  };

  const handleEpisodeChange = (value: any) => {
    const [episodeId, surgeryCategoryId] = value.key.split(":");
    setForm({
      ...form,
      surgerySiteSide: { ...surgerySiteSide, value: { name: "", id: "" } },
      episode: {
        ...episode,
        value: { name: value.name, id: episodeId },
        isValid: true,
      },
    });
    dispatch(getSurgerySiteSideAsync(surgeryCategoryId));
  };

  const handleCptChange = (value: any) => {
    setForm({
      ...form,
      cpt: {
        ...cpt,
        value,
        isValid: isEmptyString(value) ? true : isCptValid(value),
      },
    });
  };

  const handleProcedureDescriptionChange = (value: any) => {
    setForm({
      ...form,
      procedureDescription: {
        ...procedureDescription,
        value,
        isValid: isProcedureDescriptionValid(value),
      },
    });
  };

  const handleEpisodeSettingsChange = (value: any) => {
    setForm({
      ...form,
      episodeSettings: { ...episodeSettings, value },
    });
  };

  const handleEpisodeTypeChange = (value: any) => {
    const { id, text } = episodeCareType.SURGICAL;
    let data = {
      ...form,
      episodeType: { ...episodeType, value },
      episodeCare: { ...episodeCare, value: { id, name: text } },
    };
    if (value.name === episodePlanType.UNPLANNED.text)
      data.episodeCare = { ...episodeCare, value: {} as any };
    setForm(data);
  };

  const handleEpisodeCareChange = (value: any) => {
    setForm({
      ...form,
      episodeCare: { ...episodeCare, value },
    });
  };

  const handleSurgerySiteSideChange = (value: any) => {
    setForm({
      ...form,
      surgerySiteSide: {
        ...surgerySiteSide,
        value: { name: value.name, id: value.key },
        isValid: true,
      },
    });
  };

  const handleAcuteCareFacilityChange = (value: any) => {
    setForm({
      ...form,
      acuteCareFacility: {
        ...acuteCareFacility,
        value: { name: value.name, id: value.key },
        isValid: true,
      },
    });
  };

  const handleCnnChange = (value: any) => {
    setForm({
      ...form,
      ccn: {
        ...ccn,
        value,
        isValid: isEmptyString(value) ? true : isCcnValid(value),
      },
    });
  };

  const handleAdmitDateChange = (value: any) => {
    setForm({
      ...form,
      admitDate: { ...admitDate, value, isValid: true },
      surgeryDate: { ...surgeryDate, value: null },
      dischargeDate: { ...dischargeDate, value: null },
    });
  };

  const handleSurgeryDateChange = (value: any) => {
    setForm({
      ...form,
      surgeryDate: {
        ...surgeryDate,
        value,
        isValid: isSurgeryDateValid(admitDate.value, value),
      },
      dischargeDate: { ...dischargeDate, value: null },
    });
  };

  const handleDischargeDateChange = (value: any) => {
    setForm({
      ...form,
      dischargeDate: {
        ...dischargeDate,
        value,
      },
    });
  };

  const handleEpisodeSettingUpdate = (id: string, value: string) => {
    const updatedInputs = [...episodeSettingInputs];
    updatedInputs.forEach((el) => {
      el.checked = el.id === id;
    });
    setEpisodeSettingInputs(updatedInputs);
    handleEpisodeSettingsChange({ name: value, id });
  };

  const handleEpisodeTypeUpdate = (id: string, value: string) => {
    const isPlanned = value === episodePlanType.PLANNED.text;
    setIsEpisodeCareInputDisabled(isPlanned);

    const updatedEpisodeTypeInputs = [...episodeTypeInputs];
    updatedEpisodeTypeInputs.forEach((el) => {
      el.checked = el.id === id;
    });
    setEpisodeTypeInputs(updatedEpisodeTypeInputs);

    if (isPlanned) {
      const { SURGICAL } = episodeCareType;
      handleEpisodeCareUpdate(SURGICAL.id, SURGICAL.text);
    }

    handleEpisodeTypeChange({ name: value, id });
  };
  const [
    acuteCareFacilityValueIfDisabled,
    setAcuteCareFacilityValueIfDisabled,
  ] = useState("");

  const handleEpisodeCareUpdate = (id: string, value: string) => {
    const updatedEpisodeCareInputs = [...episodeCareInputs];
    updatedEpisodeCareInputs.forEach((el) => {
      el.checked = el.id === id;
    });
    setEpisodeCareInputs(updatedEpisodeCareInputs);
    handleEpisodeCareChange({ name: value, id });
  };

  const practicemessage = useMemo(() => {
    if (surgeonPractice.isDisabled) return "";
    if (!surgeonName.value) {
      return surgeonPractice.isValid ? "" : "Please select one from dropdown.";
    }
    if (!surgeonName.PracticeId && !surgeonPractice.value.name) {
      return "No practice assigned to Surgeon. Please select a valid practice.";
    }
    if (!surgeonPractice.value.name) {
      return "Invalid practice assigned to Surgeon. Please select a valid practice.";
    }
    return "";
  }, [
    surgeonPractice.isDisabled,
    surgeonPractice.isValid,
    surgeonPractice.value.name,
    surgeonName.value,
    surgeonName.PracticeId,
  ]);

  const isChecked = (list: RadioButtonListType[]) =>
    list.some((item) => item.checked);

  useEffect(() => {
    if (acuteCareFacility.isDisabled) {
      appDispatch(
        getAcuteFacilityCareByIdAsync({
          facilityId: existingEpisodeDetails?.facilityId.toString() ?? "",
        })
      ).then((res) => {
        const val =
          res.payload && res.payload.ProviderName
            ? res.payload.ProviderName
            : "";
        setAcuteCareFacilityValueIfDisabled(val);
      });
    }
  }, [appDispatch]);

  return (
    <>
      <div className="input-row-container">
        <div className="input-container">
          <div className="input-wrapper">
            <div className="dropdown-label" id={surgeonName.id}>
              Surgeon/Admitting Physician
              <span className="red-color-asterisk">*</span>
            </div>
            <CustomDropDown
              placeholder="Select"
              dropDownMenuItems={metadata.surgeons}
              handleValueChanges={handleSurgeonNameChange}
              defaultValue={{ name: surgeonName.value }}
              idFieldName="key"
              dropDownBoxClass="surgeon-admitting-physician-dropdown"
              selectionClass="surgeon-admitting-physician-selection"
              errorMessage="Please select one from dropdown."
              showError={!surgeonName.isDisabled && !surgeonName.isValid}
              isDisabled={surgeonName.isDisabled}
            />
          </div>
        </div>
        <div className="input-container">
          <div className="input-wrapper">
            <TextField
              label="NPI"
              type="text"
              value={surgeonName.NPI ? surgeonName.NPI : "-"}
              inputClassName="npi-name-input"
              placeholder="-"
              disabled={true}
              infoMessaage="Not specified for the selected physician."
              isDisplayInfoMessage={!npi.isDisabled && !npi.isValid}
              maxLength={NPI_LENGTH}
              id={npi.id}
            />
          </div>
        </div>
        <div className="input-container">
          <div className="input-wrapper">
            <div className="dropdown-label" id={surgeonPractice.id}>
              Surgeon’s Practice
              <span className="red-color-asterisk">*</span>
            </div>
            <CustomDropDown
              placeholder="Select"
              dropDownMenuItems={metadata.practices}
              handleValueChanges={handleSurgeonPracticeChange}
              defaultValue={{ name: surgeonPractice.value.name }}
              idFieldName="key"
              dropDownBoxClass="surgeon-practice-dropdown"
              selectionClass="surgeon-practice-selection"
              errorMessage={practicemessage}
              showError={practicemessage !== ""}
              isDisabled={surgeonPractice.isDisabled}
            />
          </div>
        </div>
      </div>
      <div className="input-row-container">
        <div className="input-container">
          <div className="input-wrapper">
            <div className="dropdown-label" id={program.id}>
              Program
              <span className="red-color-asterisk">*</span>
            </div>
            <CustomDropDown
              placeholder="Select"
              dropDownMenuItems={metadata.programs}
              handleValueChanges={handleProgramChange}
              defaultValue={{ name: program.value.name }}
              idFieldName="key"
              dropDownBoxClass="program-dropdown"
              selectionClass="program-selection"
              errorMessage="Please select one from dropdown."
              showError={!program.isDisabled && !program.isValid}
              isDisabled={program.isDisabled}
            />
          </div>
        </div>
        <div className="input-container">
          <div className="input-wrapper">
            <div className="dropdown-label" id={episode.id}>
              Episode
              <span className="red-color-asterisk">*</span>
            </div>
            <CustomDropDown
              placeholder="Select"
              dropDownMenuItems={metadata.episodes}
              handleValueChanges={handleEpisodeChange}
              defaultValue={{ name: episode.value.name }}
              idFieldName="key"
              dropDownBoxClass="episode-dropdown"
              selectionClass="episode-selection"
              errorMessage="Please select one from dropdown."
              showError={!episode.isDisabled && !episode.isValid}
              isDisabled={
                episode.isDisabled || !program.value.id || !program.value.name
              }
            />
          </div>
        </div>
        <div className="input-container">
          <div className="input-wrapper">
            <TextField
              label="CPT"
              type="text"
              value={cpt.value}
              setValue={(label, value) => handleCptChange(value)}
              inputClassName="cpt-name-input"
              placeholder="XXXXX"
              errorMessage="Length should be equal to 5 characters."
              showError={!cpt.isDisabled && !cpt.isValid}
              maxLength={CPT_LENGTH}
              disabled={cpt.isDisabled}
              id={cpt.id}
            />
          </div>
        </div>
      </div>

      <div className="text-area-container">
        <TextArea
          leftLabel="Procedure Description"
          rightLabel="Maximum 500 characters"
          inputText={procedureDescription.value}
          handleChange={handleProcedureDescriptionChange}
          showError={
            !procedureDescription.isDisabled && !procedureDescription.isValid
          }
          errorMessage={"Length should be less than 500 characters."}
          rows={6}
          required
          isDisabled={procedureDescription.isDisabled}
          id={procedureDescription.id}
        />
      </div>
      <div className="input-row-container">
        <div className="input-container">
          <div className="episode-setting-radio-input" id={episodeSettings.id}>
            <RadioInputGroup
              labelText="Episode Settings"
              errorMessage={TextLabels.EPISODE_SETTING_GERROR}
              showError={
                !isChecked(episodeSettingInputs) && !episodeSettings.isValid
              }
              radioList={episodeSettingInputs}
              handleInputChange={handleEpisodeSettingUpdate}
              required
              disabled={episodeSettings.isDisabled}
            />
          </div>
        </div>
        <div className="input-container">
          <div className="episode-type-radio-input" id={episodeType.id}>
            <RadioInputGroup
              labelText="Episode Type"
              errorMessage={TextLabels.EPISODE_TYPE_ERROR}
              showError={!isChecked(episodeTypeInputs) && !episodeType.isValid}
              radioList={episodeTypeInputs}
              handleInputChange={handleEpisodeTypeUpdate}
              required
              disabled={episodeType.isDisabled}
            />
          </div>
        </div>
        <div className="input-container">
          <div className="episode-type-radio-input">
            <RadioInputGroup
              labelText="Episode Care"
              errorMessage={TextLabels.EPISODE_CARE_ERROR}
              showError={!isChecked(episodeCareInputs) && !episodeCare.isValid}
              shouldDisplayErroronDisabled
              radioList={episodeCareInputs}
              handleInputChange={handleEpisodeCareUpdate}
              className="episode-care"
              required
              disabled={
                !isChecked(episodeTypeInputs) ||
                isEpisodeCareInputDisabled ||
                episodeCare.isDisabled
              }
            />
          </div>
        </div>
      </div>

      <div className="input-row-container">
        <div className="input-container">
          <div className="input-wrapper">
            <div className="dropdown-label" id={surgerySiteSide.id}>
              Surgery Site Side
              <span className="red-color-asterisk">*</span>
            </div>
            <CustomDropDown
              placeholder="Select"
              dropDownMenuItems={metadata.surgerySiteSides}
              handleValueChanges={handleSurgerySiteSideChange}
              defaultValue={{ name: surgerySiteSide.value.name }}
              idFieldName="key"
              dropDownBoxClass="surgery-site-side-dropdown"
              selectionClass="surgery-site-side-selection"
              errorMessage="Please select one from dropdown."
              showError={
                !surgerySiteSide.isDisabled && !surgerySiteSide.isValid
              }
              isDisabled={surgerySiteSide.isDisabled}
            />
          </div>
        </div>
        <div className="input-container">
          <div className="input-wrapper">
            <div className="dropdown-label" id={acuteCareFacility.id}>
              Acute Care Facility
              <span className="red-color-asterisk">*</span>
            </div>
            <CustomDropDown
              placeholder="Select"
              dropDownMenuItems={metadata.facilities}
              handleValueChanges={handleAcuteCareFacilityChange}
              defaultValue={{
                name: acuteCareFacility.isDisabled
                  ? acuteCareFacilityValueIfDisabled
                  : acuteCareFacility.value.name,
              }}
              idFieldName="key"
              dropDownBoxClass="acute-care-facility-dropdown"
              selectionClass="acute-care-facility-selection"
              errorMessage="Please select one from dropdown."
              showError={
                !acuteCareFacility.isDisabled && !acuteCareFacility.isValid
              }
              isDisabled={acuteCareFacility.isDisabled}
            />
          </div>
        </div>

        <div className="input-container">
          <div className="input-wrapper">
            <TextField
              label="CCN"
              type="text"
              value={ccn.value}
              setValue={(label, val) => handleCnnChange(val)}
              inputClassName="npi-name-input"
              placeholder="XXXXXX"
              errorMessage="Length should be equal to 6 characters."
              showError={!ccn.isDisabled && !ccn.isValid}
              disabled={ccn.isDisabled}
              maxLength={CCN_LENGTH}
            />
          </div>
        </div>
      </div>

      <div className="input-row-container">
        <div className="input-container surgeon-episode-date-container">
          <div className="input-wrapper">
            <div className="label" id={admitDate.id}>
              Admit Date
              <span className="red-color-asterisk">*</span>
            </div>
            <SingleDatePicker
              required
              defaultDate={admitDate.value}
              minDate={null}
              maxDate={null}
              isDisabled={admitDate.isDisabled}
              isError={!admitDate.isDisabled && !admitDate.isValid}
              handleDateChange={handleAdmitDateChange}
              errorMessage="Please select the date."
              popperPlacement="top"
            />
          </div>
        </div>
        <div className="input-container">
          <div className="input-wrapper">
            <div className="label" id={surgeryDate.id}>
              Surgery Date
              <span className="red-color-asterisk">*</span>
            </div>
            <SingleDatePicker
              required
              defaultDate={surgeryDate.value}
              minDate={
                admitDate.value
                  ? new Date(admitDate.value).setDate(
                      new Date(admitDate.value).getDate() - 1
                    )
                  : null
              }
              maxDate={null}
              isDisabled={!admitDate.value || surgeryDate.isDisabled}
              isError={!surgeryDate.isDisabled && !surgeryDate.isValid}
              handleDateChange={handleSurgeryDateChange}
              errorMessage="Please select the date."
              popperPlacement="top"
            />
          </div>
        </div>
        <div className="input-container">
          <div className="input-wrapper">
            <div className="label" id={dischargeDate.id}>
              Discharge Date
            </div>
            <SingleDatePicker
              required={false}
              errorMessage=""
              defaultDate={dischargeDate.value}
              minDate={
                surgeryDate.value
                  ? new Date(surgeryDate.value).setDate(
                      new Date(surgeryDate.value).getDate() - 1
                    )
                  : null
              }
              maxDate={null}
              isDisabled={!surgeryDate.value || dischargeDate.isDisabled}
              isError={false}
              handleDateChange={handleDischargeDateChange}
              popperPlacement="top"
            />
          </div>
        </div>
      </div>
      <div className="input-row-container">
        <div className="input-container">
          <div className="input-wrapper">
            <TextField
              label="Patient Episode Status"
              type="text"
              value={"New"}
              inputClassName="patient-episode-status-input-disabled"
              setValue={() => {}}
              disabled={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SurgeonAndEpisodeDetails;
