import React from "react";
import ReactTooltip from "react-tooltip";
import { ToolTipProps } from "./tool-tip.types";
import "./tool-tip.styles.scss";

export const CustomToolTip = (props: ToolTipProps) => {
  const { text, place } = props;
  return (
    <ReactTooltip
      id={text}
      place={place}
      type="dark"
      effect="solid"
      className={`custom-tooltip ${place}`}
      delayShow={1000}
    >
      <span>{text}</span>
    </ReactTooltip>
  );
};
